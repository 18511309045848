import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InfoIcon from '../../components/icons/infoIcon';
import List from '../../components/tasks/list';
import FilterButton from '../../components/ui/filterButton';
import InputField from '../../components/ui/inputField';
import {format} from 'date-fns';
import {tasksSagaActions} from '../../redux/task/tasksSagaActions';
import {contentSagaActions} from '../../redux/content/contentSagaActions';
import SelectSearch from '../../components/ui/selectSearch';
import getRole from '../../hooks/getRole';

function Dashboard(props) {
  const [showFilter, setShowFilter] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isAssignedToMe, setIsAssignedToMe] = useState(false)
  const [assignedToMe, setAssignedToMe] = useState('')
  const [isCreatedByMe, setIsCreatedByMe] = useState(false)
  const [createdByMe, setCreatedByMe] = useState('')
  const [title, setTitle] = useState('Toutes mes tâches')
  const [date, setDate] = useState('');
  const [search, setSearch] = useState('');
  const [contentId, setContentId] = useState('');
  const {user_isDeleted, user_email, user_roles} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {contentList} = useSelector((state) => state.content);
  const {task} = useSelector((state) => state.task);

  useEffect(() => {
    dispatch(contentSagaActions.getAllContent());
  }, [dispatch]);

  useEffect(() => {
    dispatch(tasksSagaActions.filterTasks({date, search, contentId, createdByMe, assignedToMe}));
  }, [date, search, contentId, createdByMe, assignedToMe, dispatch, task]);

  function handleCheck() {
    isChecked ? setDate('') : setDate(format(new Date(), 'yyyy-MM-dd'));
    setIsChecked(!isChecked);
  }

  function handleContentChange(val) {
    setContentId('');
    setTitle('Tous mes Tâches');
    if (val) {
      setTitle(val.title);
      setContentId(val.id);
    }
  }

  function handleCheckCreator() {
    isCreatedByMe? setCreatedByMe('') : setCreatedByMe(user_email);
    setIsCreatedByMe(!isCreatedByMe);
  }

  function handleCheckAssignee() {
    isAssignedToMe? setAssignedToMe('') : setAssignedToMe(user_email);
    setIsAssignedToMe(!isAssignedToMe);
  }

  return (
    <>
      {user_isDeleted ?
        <div className={'flex justify-center bg-red-100 rounded-lg p-5 text-sm text-red-600 w-full gap-4'}>
          <InfoIcon/>
          <p>
            Votre compte a été désactivé, veuillez contacter votre administrateur.
          </p>
        </div>
        :
        <div>
          <div className="flex justify-between items-center mb-4">
            <h1>Dashboard : {title}</h1>
            <div className="relative">
              <FilterButton showFilter={showFilter} onClick={() => setShowFilter(!showFilter)} />
              <div
                className={'absolute right-0 mt-1 w-52 bg-white border border-gray-600 rounded py-4 px-2 ' + (showFilter ? 'block' : 'hidden')}>
                <div className="w-full text-center border-b border-b-primary">
                  Filtres
                </div>
                <div className="pt-6 space-y-2">
                  <InputField type={'text'} placeholder={'Tâche'} label={'Tâche'}
                              onChange={(e) => setSearch(e.target.value)} />
                  <div className="flex flex-col gap-3">
                    <label>Délai</label>
                    <div className={'flex items-center'}>
                      <input className={'w-8 h-4'} type={'checkbox'}
                             checked={isChecked}
                             onChange={handleCheck} />
                      <label>En retard</label>
                    </div>
                  </div>
                  <SelectSearch
                    label={'Contenu'}
                    items={contentList}
                    field={'title'}
                    onChange={handleContentChange}
                    placeholder={'Contenu'}
                  />
                  {
                    getRole(user_roles).key !== 'ROLE_USER' ?
                      <div className="flex flex-col gap-3">
                        <label>Tâches</label>
                        <div className={'flex items-center'}>
                          <input className={'w-8 h-4'} type={'checkbox'}
                                 checked={isCreatedByMe}
                                 onChange={handleCheckCreator} />
                          <label>Créées par moi.</label>
                        </div>
                        <div className={'flex items-center'}>
                          <input className={'w-8 h-4'} type={'checkbox'}
                                 checked={isAssignedToMe}
                                 onChange={handleCheckAssignee} />
                          <label>Assignées à moi.</label>
                        </div>
                      </div>
                      : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className={'overflow-auto'}>
            <List />
          </div>
        </div>
      }
    </>
  );
}

export default Dashboard;