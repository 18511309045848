import api from '../../hooks/Api';
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  addUserFail,
  addUserSuccess,
  deactivateUser,
  editUser,
  getAllUsers,
  httpFailure,
  getContentCreators,
  getUsersByRole, getUsersSuccess, getEventsFromCalendar
} from './usersSlice';
import {toast} from 'react-toastify';
import {getMe} from '../auth/authSlice';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

function* workerGetAllUsers(action) {
  let email = action.payload.search ? `&email=${action.payload.search}` : '';

  let isDeleted = '';
  if (action.payload.isDeleted !== action.payload.isNotDeleted) {
    isDeleted = `&isDeleted=${action.payload.isDeleted}`;
  }

  let type = '';
  if (action.payload.isFree !== action.payload.isEmployee) {
    type = `&type=${action.payload.isFree ? 'freelance' : 'employee'}`;
  }

  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/users?_page=${action.payload.page}${email}${type}${isDeleted}`
    }));

    yield put(getAllUsers(res.data));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* editAccount(id, user) {
  if (user.profilePhoto) {
    delete user.profilePhoto;
  }

  return yield call(() => api({
    url: `${baseUrl}/api/users/${id}`,
    method: 'put',
    data: user
  }));
}

function* workerDeactivateAccount(action) {
  try {
    const res = yield editAccount(action.payload.id, action.payload.user);
    if (res.data.isDeleted) {
      toast.success('Compte désactivé avec succès!', {theme: 'dark'});
      yield put(getMe(res));
    } else {
      toast.error('Pour désactiver votre profil, vous devez terminer toutes vos tâches ou contacter vos responsables.', {theme: 'dark'});
    }
  } catch {
    toast.error('Une erreur est survenue veuillez réessayer ultérieurement', {theme: 'dark'});
  }
}

function* workerDeactivateUser(action) {
  try {
    let res = yield editAccount(action.payload.user.id, action.payload.user);
    res = {...res, index: action.payload.index};
    yield put(deactivateUser(res));
  } catch (e) {
    yield put(httpFailure);
  }
}

function* workerEditUser(action) {
  try {
    let res = yield editAccount(action.payload.data.id, action.payload.data);
    res = {...res, index: action.payload.index};
    yield put(editUser(res));
  } catch (e) {
    yield put(httpFailure);
  }
}

function* workerAddUser(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/users`,
      method: 'POST',
      data: action.payload
    }));

    yield put(addUserSuccess(res));
  } catch (e) {
    yield put(addUserFail(e.response.status));
  }
}

function* workerGetContentCreators() {
  const res = yield call(() => api({
    url: `${baseUrl}/api/users?isDeleted=false&exists[contentCreated]=true`
  }));
  yield put(getContentCreators(res.data));
}

function* getUsers(role) {
  return yield call(() => api({
    url: `${baseUrl}/api/get_user_by_role`,
    method: 'post',
    data: role
  }));
}

function* workerGetUsersByRole(action) {
  try {
    const res = yield getUsers(action.payload);
    yield put(getUsersByRole(res.data));
  } catch {}
}

function* workerGetUsers() {
  try {
    const res = yield getUsers({role : 'ROLE_USER'});
    yield put(getUsersSuccess(res.data));
  } catch {}
}

function* workerSyncToCalendar(action) {
  yield call(() => api({
    url: `${baseUrl}/api/sync_to_google`,
    method: 'post',
    data: action.payload
  }).then(() => {
    window.location.replace('/calendar');
  }));
}

function* workerGetCalendarEvents() {
  const res = yield call(() => api({
    url: `${baseUrl}/api/get_calendar_events`
  }));

  yield put(getEventsFromCalendar(res));
}

export function* watcherUsers() {
  yield takeEvery('FETCH_ALL_USERS', workerGetAllUsers);
  yield takeEvery('DEACTIVATE_ACCOUNT', workerDeactivateAccount);
  yield takeEvery('DEACTIVATE_USER', workerDeactivateUser);
  yield takeEvery('ADD_USER', workerAddUser);
  yield takeEvery('EDIT_USER', workerEditUser);
  yield takeEvery('FETCH_CONTENT_CREATORS', workerGetContentCreators);
  yield takeEvery('FETCH_USER_BY_ROLE', workerGetUsersByRole);
  yield takeEvery('FETCH_USERS', workerGetUsers);
  yield takeEvery('SYNC_TO_CALENDAR', workerSyncToCalendar);
  yield takeEvery('GET_CALENDAR_EVENTS', workerGetCalendarEvents);
}