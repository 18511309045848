import React, {useEffect, useState} from 'react';
import TaskCard from './taskCard';
import {DragDropContext} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from 'react-redux';
import {statusSagaActions} from '../../redux/status/statusSagaActions';
import {tasksSagaActions} from '../../redux/task/tasksSagaActions';

function List() {

  const dispatch = useDispatch();
  const {statusList} = useSelector(state => state.status);
  const {filteredTasks} = useSelector(state => state.task);
  const [list, setList] = useState(filteredTasks);

  useEffect(() => {
    dispatch(statusSagaActions.getAllStatus());
  }, [dispatch]);

  useEffect(() => {
    setList(filteredTasks);
  }, [filteredTasks]);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const listCopy = [...list];
    let i = list.findIndex(task => task.id === result.draggableId);

    listCopy[i] = {
      ...listCopy[i],
      status:{
       id : result.destination.droppableId,
       '@id': result.destination.droppableId
      }
    };

    const payload = {
      id: listCopy[i].id,
      status: result.destination.droppableId
    };

    dispatch(tasksSagaActions.changeTaskStatus(payload));
    setList(listCopy);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
        <div className={'flex gap-3'}>
          {statusList?.map((status) => (
            <div key={status.id} className={'task-status rounded p-2 border-dashed border'} style={{'borderColor' :  status.color}}>
              <h2 className={'mb-4'}>{status.name}</h2>
              <TaskCard key={status.id} status={status} elements={list.filter((task) => task.status['@id'] === status['@id'])} />
            </div>
          ))}
        </div>
    </DragDropContext>
  );
}

export default List;