import api from '../../hooks/Api';
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  httpFailure,
  getAllCategories,
  getActiveCategories,
  addCategory,
  editCategory,
  deactivateCategory
} from './categorySlice';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

function* workerGetAllCategories(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/categories?_page=${action.payload}`
    }));

    yield put(getAllCategories(res.data));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* workerGetActiveCategories() {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/categories?isActive=true`
    }));

    yield put(getActiveCategories(res.data));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* workerAddCategory(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/categories`,
      method: 'post',
      data: action.payload
    }));

    yield put(addCategory(res));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* updateCategory(action) {
  const res = yield call(() => api({
    url: `${baseUrl}/api/categories/${action.payload.category.id}`,
    method: 'put',
    data: action.payload.category
  }));

  return {...res, index: action.payload.index};
}

function* workerEditCategory(action) {
  try {
    const res = yield updateCategory(action);
    yield put(editCategory(res));
  } catch {}
}

function* workerDeactivateCategory(action) {
  try {
    const res = yield updateCategory(action);
    yield put(deactivateCategory(res));
  } catch {}
}

export function* watcherCategories() {
  yield takeEvery('FETCH_ALL_CATEGORIES', workerGetAllCategories);
  yield takeEvery('FETCH_ACTIVE_CATEGORIES', workerGetActiveCategories);
  yield takeEvery('ADD_CATEGORY', workerAddCategory);
  yield takeEvery('EDIT_CATEGORY', workerEditCategory);
  yield takeEvery('DEACTIVATE_CATEGORY', workerDeactivateCategory);
}