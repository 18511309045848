import React, {useEffect, useState} from 'react';
import Pill from '../ui/pill';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {useSelector} from 'react-redux';
import {format} from 'date-fns';
import ExclamationTriangle from '../icons/exclamationTriangle';
import Modal from 'react-modal';
import {modalStyle} from '../../utils/modalStyle';
import TaskDetail from './taskDetail';

function TaskCard({status, elements}) {
  Modal.setAppElement('#root');
  const {user_email} = useSelector((state) => state.auth);
  const [myTask, setMyTask] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const {task} = useSelector((state) => state.task);

  useEffect(() => {
    if(task) {
      setMyTask(task);
    }
  }, [task]);

  function hasDatePassed(targetDate, isDone) {
    const currentDate = new Date();
    if (!isDone) {
      return (currentDate > new Date(targetDate));
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleOpenTask(val) {
    setMyTask(val);
    openModal();
  }

  return (
    <>
      <Droppable droppableId={status['@id']}>
        {(provided) => (
          <div className={'w-full h-[75vh] min-w-fit'} {...provided.droppableProps} ref={provided.innerRef}>
            {elements?.map((task, index) => (
                  <Draggable draggableId={task.id} index={index} key={task.id}>
                    {(provided) => {
                      return (
                        <div
                          className={'flex flex-col gap-4 py-4 px-2 border border-gray-500 bg-white rounded my-1'}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <p className={'overflow-clip text-base max-h-14 font-medium cursor-pointer'} onClick={() => handleOpenTask(task)}>
                            {task.title}
                          </p>
                          {task.assignedTo.email === user_email ?
                            <Pill text={'Moi'} color={'#FFB30D'} />
                            :
                            <Pill text={`${task.assignedTo.firstName} ${task.assignedTo.lastName}`} color={'#314266'} />
                          }
                          {
                            hasDatePassed(task.deadlineAt, task.isDone) ?
                              <span className={'flex items-center gap-2 text-red-600'}>
                                <ExclamationTriangle size={'w-4 h-4'}/>
                                <p className={'text-sm font-medium'}>DeadLine : {format(new Date(task.deadlineAt), 'dd-M-yyyy')}</p>
                               </span>
                              :
                              <span className={'flex items-center gap-2'}>
                                <p className={'text-sm font-light'}>DeadLine : {format(new Date(task.deadlineAt), 'dd-M-yyyy')}</p>
                               </span>
                          }
                        </div>);
                    }
                    }
                  </Draggable>
                )
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        <div className={'w-[70vw] min-h-[60vh] max-h-[80vh] overflow-y-auto'}>
          <TaskDetail item={myTask} />
        </div>
      </Modal>
    </>
  );
}

export default TaskCard;