export const tasksSagaActions = {
  editTask,
  addTask,
  filterTaskByUser,
  filterTasks,
  deleteTask,
  startTask,
  fillTask,
  finishTask,
  validateTask,
  changeTaskStatus,
  publishTask
};

function editTask(payload) {
  return {
    type: 'EDIT_TASK',
    payload
  }
}

function deleteTask(payload) {
  return {
    type: 'DELETE_TASK',
    payload
  };
}

function addTask(payload) {
  return {
    type: 'ADD_TASK',
    payload
  }
}

function filterTaskByUser(payload) {
  return {
    type: 'FETCH_TASKS_BY_USERS',
    payload
  }
}

function filterTasks(payload) {
  return {
    type: 'FILTER_TASKS',
    payload
  }
}

function startTask(payload) {
  return {
    type: 'START_TASK',
    payload
  }
}

function fillTask(payload) {
  return {
    type: 'FILL_TASK',
    payload
  };
}

function finishTask(payload) {
  return {
    type: 'FINISH_TASK',
    payload
  };
}

function validateTask(payload) {
  return {
    type: 'VALIDATE_TASK',
    payload
  };
}

function changeTaskStatus(payload) {
  return {
    type: 'CHANGE_STATUS',
    payload
  }
}

function publishTask(payload) {
  return {
    type: 'PUBLISH_TASK',
    payload
  };
}