import {configureStore} from '@reduxjs/toolkit';
import authsReducer from '../auth/authSlice';
import categoriesReducer from '../category/categorySlice';
import statusReducer from '../status/statusSlice';
import destinationReducer from '../destination/destinationSlice';
import userReducer from '../user/usersSlice';
import contentReducer from '../content/contentSlice';
import taskReducer from '../task/tasksSlice';
import commentReducer from '../comment/commentSlice';
import themeReducer from '../theme/themesSlice';
import notificationReducer from '../notification/notificationSlice';
import wordPressReducer from '../wrodPress/wordPressSlice'
import createSagaMiddleware from 'redux-saga';
import {watcherLogin} from '../auth/authSaga';
import {watcherCategories} from '../category/categorySaga';
import {watcherStatus} from '../status/statusSaga';
import {watcherDestinations} from '../destination/destinationSaga';
import {watcherUsers} from '../user/usersSaga';
import {watcherContents} from '../content/contentSaga';
import {watcherTasks} from '../task/tasksSaga';
import {watcherComments} from '../comment/commentSaga';
import {watcherNotifications} from '../notification/notificationSaga';
import {watcherThemes} from '../theme/themesSaga';
import {watcherWordPress} from '../wrodPress/wordPressSaga';

let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authsReducer,
    category: categoriesReducer,
    status: statusReducer,
    destination: destinationReducer,
    users: userReducer,
    content: contentReducer,
    task: taskReducer,
    comment: commentReducer,
    notification: notificationReducer,
    theme: themeReducer,
    wordPress: wordPressReducer
  },
  middleware: [sagaMiddleware]
});

sagaMiddleware.run(watcherLogin);
sagaMiddleware.run(watcherCategories);
sagaMiddleware.run(watcherStatus);
sagaMiddleware.run(watcherDestinations);
sagaMiddleware.run(watcherUsers);
sagaMiddleware.run(watcherContents);
sagaMiddleware.run(watcherTasks);
sagaMiddleware.run(watcherComments);
sagaMiddleware.run(watcherNotifications);
sagaMiddleware.run(watcherThemes);
sagaMiddleware.run(watcherWordPress);

export default store;
