import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const initialState = {
  totalItems: 0,
  commentsList: [],
  comment: {}
};

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    addCommentsSuccess(state, action) {
      state.comment = action.payload;
    },
    editCommentSuccess(state, action) {
      state.comment = action.payload;
      toast.success('Modification enregistrée avec succès!', {theme: 'dark'});
    },
    deleteCommentSuccess(state, action) {
      state.comment = action.payload;
      toast.success('Commentaire supprimé avec succès!', {theme: 'dark'});
    }
  }
});

export const {
  addCommentsSuccess,
  editCommentSuccess,
  deleteCommentSuccess
} = commentSlice.actions;

export default commentSlice.reducer;