import React, {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import isTokenExpired from '../hooks/isTokenExpired';
import {authSagaActions} from '../redux/auth/authSagaActions';

const ProtectedRoute = ({children}) =>{
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token && !isTokenExpired(token)) {
      dispatch(authSagaActions.getMe());
    } else {
      localStorage.removeItem('token');
      navigate('/login', {state: {from: location.pathname}});
    }
  }, [token, dispatch, navigate]);

  return children;
}

export default ProtectedRoute;