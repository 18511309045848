import React from 'react';
import TagIcon from '../icons/tagIcon';
import {useNavigate} from 'react-router-dom';

function Tag({color, text, link}) {
  const navigate = useNavigate();

  function redirectToLink() {
    if (link) {
      navigate(link);
    }
  }

  return (
    <span className={'flex items-center gap-1 rounded-xl border px-2 ' + (link? 'cursor-pointer' : 'cursor-default')} style={{borderColor: color, color}} onClick={redirectToLink}>
      <TagIcon size={'w-5 h-5'}/>
      <p className={'font-semibold'}>{text}</p>
    </span>
  );
}

export default Tag;