import React from 'react';

function UserCard({title, lastName, firstName, email, phone, type, company, country, role, closeModal}) {
  return (
    <div className="flex flex-col gap-2 w-full my-4">
      {
        title ?
          <div className={'w-[80vw] md:w-[40vw] mb-4 flex justify-between'}>
            <p className={'text-xl font-bold'}>{title}</p>
            <span className={'cursor-pointer'} onClick={closeModal}>X</span>
          </div>
        : null
      }
      <ul className="tex-gray">
        <li className="flex flex-row gap-3 py-2 border-b capitalize">
          <span className={'font-bold'}>Nom :</span>
          <span>{lastName}</span>
        </li>
        <li className="flex flex-row gap-3 py-2 border-b capitalize">
          <span className={'font-bold'}>Prénom :</span>
          <span>{firstName}</span>
        </li>
        <li className="flex flex-row gap-3 py-2 border-b">
          <span className={'font-bold'}>Email :</span>
          <span>{email}</span>
        </li>
        <li className="flex flex-row gap-3 py-2 border-b">
          <span className={'font-bold'}>Numéro de téléphone :</span>
          <span>{phone}</span>
        </li>
        <li className="flex flex-row gap-3 py-2 border-b">
          <span className={'font-bold'}>Profil :</span>
          <span>{type === 'EMPLOYEE' ? 'Employé(e)' : 'Freelance' }</span>
        </li>
        {company ?
          <li className="flex flex-row gap-3 py-2 border-b capitalize">
            <span className={'font-bold'}>Société :</span>
            <span>{company}</span>
          </li> : null
        }
        <li className="flex flex-row gap-3 py-2 border-b capitalize">
          <span className={'font-bold'}>Pays :</span>
          <span>{country}</span>
        </li>
        <li className="flex flex-row gap-3 py-2 border-b capitalize">
          <span className={'font-bold'}>Rôle :</span>
          <span>{role}</span>
        </li>
      </ul>
    </div>
  );
}

export default UserCard;