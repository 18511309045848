import React, {useState} from 'react';
import PenIcon from '../icons/penIcon';
import TrashIcon from '../icons/trashIcon';
import {useSelector} from 'react-redux';
import getRole from '../../hooks/getRole';

function TaskActions({handleEditTask, handleDeleteTask, item, index}) {
  const [showOptions, setShowOptions] = useState(false);
  const {user_email, user_roles} = useSelector((state) => state.auth);

  if (
    item.createdBy?.email !== user_email
    || getRole(user_roles).key !== 'ROLE_ADMIN'
  ) {
    return;
  }

  return (
    <div className={'absolute -right-0 top-2 mr-2 cursor-pointer'}>
      <svg onClick={() => setShowOptions(!showOptions)} xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
      </svg>
      {showOptions ?
        <div className="z-10 absolute right-1 top-5 flex flex-col gap-2 w-32 bg-white rounded border border-primary py-2">
          <span className={'flex items-center gap-2 px-4 hover:text-orange-600 hover:bg-gray-100'} onClick={() => handleEditTask(item, index)}>
            <PenIcon size={'w-3 h-3'} />
            Modifier
          </span>
          <span className={'flex items-center gap-2 px-4 hover:text-red-600 hover:bg-gray-100'} onClick={() => handleDeleteTask(item)}>
            <TrashIcon />
            Supprimer
          </span>
        </div>
      : null}
    </div>
  );
}

export default TaskActions;