import React from 'react';
import ReactPaginate from 'react-paginate';

function Pagination({onPageChange, totalItems}) {
  return (
    <>
      {totalItems > 10 ?
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          pageCount={Math.ceil(totalItems / 10)}
          containerClassName={'flex flex-row gap-2'}
          previousClassName={'flex border border-primary text-base rounded-md hover:bg-primary hover:text-white items-center'}
          previousLinkClassName={'py-2 px-4 cursor-pointer'}
          nextClassName={'flex items-center border border-primary text-base rounded-md hover:bg-primary hover:text-white'}
          nextLinkClassName={'py-2 px-4 cursor-pointer'}
          pageClassName={'flex items-center border border-primary text-base rounded-md hover:bg-primary hover:text-white'}
          pageLinkClassName={'py-2 px-4 cursor-pointer'}
          breakClassName={'p-2 text-base'}
          activeClassName={'border border-primary text-white bg-primary text-base rounded-md'}
          onPageChange={onPageChange}
        />
        : null}
    </>
  );
}

export default Pagination;
