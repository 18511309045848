import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {resetContent} from '../../redux/content/contentSlice';
import ContentForm from '../../components/forms/contentForm';

function AddContent(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {content, success} = useSelector((state) => state.content);

  useEffect(() => {
    if (success) {
      dispatch(resetContent());
      navigate(`/content/${content.id}`);
    }
  }, [success, dispatch]);

  return (
    <ContentForm formTitle={'Créer un contenu'} />
  );
}

export default AddContent;