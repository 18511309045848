import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const initialState = {
  isAuthenticated: false,
  user_id: '',
  user: {},
  user_email: '',
  user_firstName: '',
  user_lastName: '',
  user_roles: [],
  user_company: '',
  user_type: '',
  user_image: '',
  user_country: '',
  user_isDeleted: false,
  error: null,
  success: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    logout: (state, action) => {
      state.isAuthenticated = false;
      localStorage.removeItem('token');
    },
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      localStorage.setItem('token', action.payload.data.token);
      state.user = action.payload;
      state.error = null;
    },
    loginFailure(state, action) {
      state.isAuthenticated = false;
      state.user = null;
      if (action.payload === 401) {
        toast.error('Veuillez vérifier vos informations!', {theme: 'dark'});
      } else {
        toast.error('Une erreur est survenue veuillez réessayer ultérieurement', {theme: 'dark'});
      }
    },
    createUserSuccess(state, action) {
      state.success = true;
      toast.success('Votre compte a été crée avec success!', {theme: 'dark'});
    },
    createUserFailure(state, action) {
      if (action.payload === 409) {
        toast.error('Adresse email déjà enregistrée!', {theme: 'dark'});
      } else {
        toast.error('Une erreur est survenue veuillez réessayer ultérieurement', {theme: 'dark'});
      }
    },
    sendResetEmailSuccess() {
      toast.success('Veuillez consulter votre boîte de réception pour obtenir les instructions nécessaires afin de réinitialiser votre mot de passe.', {theme: 'dark'});
    },
    resetPasswordSuccess(state) {
      toast.success('Votre mot de passe a été modifié avec success', {theme: 'dark'});
      state.success = true;
    },
    resetPasswordFailure(state, action) {
      let errorMsg = '';
      switch (action.payload) {
        case 401 :
          errorMsg = 'Votre token est expiré';
          break;
        case 404 :
          errorMsg = 'Aucun utilisateur trouvé avec ce token';
          break;
        default :
          errorMsg = 'Une erreur est survenue veuillez réessayer ultérieurement';
          break;
      }

      toast.error(errorMsg, {theme: 'dark'});
    },
    getMe: (state, action) => {
      state.user = action.payload.data;
      state.user_id = action.payload.data.id;
      state.user_email = action.payload.data.email;
      state.user_firstName = action.payload.data.firstName;
      state.user_lastName = action.payload.data.lastName;
      state.user_roles = action.payload.data.roles;
      state.user_company = action.payload.data.company;
      state.user_type = action.payload.data.type;
      state.user_country = action.payload.data.country;
      state.user_phone = action.payload.data.phone;
      state.user_isDeleted = action.payload.data.isDeleted;
      state.user_createdAt = action.payload.data.createdAt;
      state.user_image =  action.payload.data.profilePhoto ?
        `${process.env.REACT_APP_BACK_END_URL}/media/${action.payload.data.profilePhoto?.name}`
        :
        "https://www.cosavostra.com/wp-content/uploads/2020/04/logo-cosavostra-hashtag.svg";
    },
    httpFailure() {
      toast.error('Une erreur est survenue veuillez réessayer ultérieurement', {theme: 'dark'});
    },
  }
});

export const {
  loginSuccess,
  loginFailure,
  createUserSuccess,
  createUserFailure,
  sendResetEmailSuccess,
  resetPasswordSuccess,
  resetPasswordFailure,
  setSuccess,
  logout,
  getMe,
  httpFailure
} = authSlice.actions;

export default authSlice.reducer;
