import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const initialState = {
  wordPress: null,
  wordPressList: [],
  totalItems: 0
};

const wordPressSlice = createSlice({
  name: 'wordPress',
  initialState,
  reducers: {
    getAllWordPresses(state, action) {
      state.totalItems = action.payload['hydra:totalItems'];
      state.wordPressList = action.payload['hydra:member'];
    },
    addWordPressSuccess(state, action) {
      state.totalItems = state.totalItems + 1;
      state.wordPressList = [...state.wordPressList, action.payload];
      state.wordPressList = state.wordPressList.sort((a, b) => a.name.localeCompare(b.name));
      toast.success('WordPress ajouté avec succès.', {theme: 'dark'});
    },
    addWordPressFail(state, action) {
      let error = 'Une erreur est survenue veuillez réessayer ultérieurement';
      if (action.payload.includes('Duplicate entry')) {
        error = 'Le nom doit être unique.';
      }

      toast.error(error, {theme: 'dark'});
    },
    editWordPressSuccess(state, action) {
      state.wordPressList[action.payload.index] = action.payload.data;
      toast.success('WordPress modifié avec succès.', {theme: 'dark'});
    },
    deleteWordPressSuccess(state, action) {
      state.totalItems = state.totalItems - 1;
      state.wordPressList = state.wordPressList.filter((val, i) => i !== action.payload);
      toast.success('WordPress supprimé avec succès.', {theme: 'dark'});
    }
  }
});

export const {
  getAllWordPresses,
  addWordPressSuccess,
  addWordPressFail,
  editWordPressSuccess,
  deleteWordPressSuccess
} = wordPressSlice.actions;

export default wordPressSlice.reducer;