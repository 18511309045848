import React, {useEffect} from 'react';
import Select from 'react-select';
import AlertIcon from '../icons/alertIcon';

function SelectSearch({label, items, field, onChange, defaultValue, error, placeholder, isMulti, user, value}) {

  let options = [];

  useEffect(() => {
    items?.map(item => {
      options.push({value: item[field], label: user ? `${item.firstName} ${item.lastName} - ${item[field]}` : item[field], ...item});
    });
  }, [items, options]);

  return (
    <div className={'bg-white flex flex-col items-start gap-2 w-full'}>
      <label>{label}</label>
      <Select
        value={value}
        classNamePrefix="select"
        isMulti={isMulti}
        className={'w-full text-sm basic-single'}
        isClearable={true}
        name={'users'}
        isSearchable={true}
        onChange={onChange}
        options={options}
        placeholder={placeholder? placeholder : 'Trouver un utilisateur'}
        noOptionsMessage={() => 'Aucun objet trouvé.'}
        defaultValue={defaultValue || placeholder? placeholder : 'Trouvé un utilisateur'}
      />
      <div className={'flex flex-row text-[#F15D5E] text-sm gap-2 ' + (error ? 'show' : 'hidden')}>
        <AlertIcon />
        <span>{error}</span>
      </div>
    </div>
  );
}

export default SelectSearch;