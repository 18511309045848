import React, {useEffect, useState} from 'react';
import AddButton from '../../components/ui/addButton';
import {useDispatch, useSelector} from 'react-redux';
import {contentSagaActions} from '../../redux/content/contentSagaActions';
import {formatDistance} from 'date-fns';
import {fr} from 'date-fns/locale';
import Pagination from '../../components/ui/pagination';
import {usersSagaActions} from '../../redux/user/usersSagaActions';
import FilterButton from '../../components/ui/filterButton';
import ItemNotFound from '../../components/ui/itemNotFound';
import SelectSearch from '../../components/ui/selectSearch';
import InputField from '../../components/ui/inputField';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import getRole from '../../hooks/getRole';

function ListOfContent(props) {
  const {user_roles} = useSelector((state) => state.auth);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const theme = queryParams.get('theme');
  const [showFilter, setShowFilter] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let path = `${process.env.REACT_APP_BACK_END_URL}/media`;
  const {
    contentList,
    totalItems
  } = useSelector(
    (state) => state.content
  );

  const {usersList} = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(contentSagaActions.getAllContent({page: 1, theme}));
    dispatch(usersSagaActions.getContentCreators());
  }, [dispatch, theme]);

  function onPageChange(currentPage) {
    dispatch(contentSagaActions.getAllContent({page: currentPage.selected + 1}));
  }

  function toggleShowFilter() {
    setShowFilter(!showFilter);
  }

  function searchByTitle(e) {
    dispatch(contentSagaActions.getContentByTitle(e.target.value));
  }

  function search(val) {
    dispatch(contentSagaActions.getContentByCreator(val.value));
  }

  function goToAddInterface() {
    navigate('/add-content');
  }

  return (
    <>
      <div className="flex flex-col gap-4 w-full items-center">
        <div className="flex justify-between w-full">
          <h1>Liste des contenus {theme && <>- {theme}</>}</h1>

          <div className="flex gap-2">
            <div className="relative">
              <FilterButton onClick={toggleShowFilter} showFilter={showFilter} />
              <div
                className={'absolute top-full -right-0 w-52 bg-white border border-gray-500 py-4 px-2 rounded ' + (showFilter ? 'block' : 'hidden')}>
                <div className="w-full text-center border-b border-b-primary">
                  Filtres
                </div>
                <div className="pt-6 space-y-2">
                  <InputField className={'text-sm'} type={'text'} label={'Titre'} placeholder={'Titre'} onChange={searchByTitle}/>
                  <SelectSearch label={'Créateur'} items={usersList} field={'email'} onChange={search} user/>
                </div>
              </div>
            </div>
            {(getRole(user_roles).key === 'ROLE_MANAGER' || getRole(user_roles).key === 'ROLE_ADMIN') &&
              <AddButton onClick={goToAddInterface}/>
            }
          </div>
        </div>
        {totalItems > 0 ?
          <>
            {contentList?.map((content, index) => (
              <div key={index} className="flex flex-col gap-2 w-full border border-primary rounded-lg p-2 max-w-screen">
                <Link to={`/content/${content.id}`} className="flex text-primary font-semibold gap-2 capitalize max-h-6 overflow-auto">
                  {content.title}
                  <span className={'font-light lowercase'}>
                     {'- ' + formatDistance(new Date(content.createdAt), new Date(), {addSuffix: true, locale: fr})}
                  </span>
                </Link>
                <div className="flex flex-col md:flex-row gap-2">
                  <div className={'md:border-r md:border-b-0 border-b w-full md:w-4/5 p-1 text-ellipsis'}>
                    <p className={'max-h-32 overflow-auto w-full'}>
                      {content.description}
                    </p>
                  </div>
                  <div className={'w-full md:w-1/5'}>
                    <div className={'flex w-full flex-col gap-2'}>
                      <div className={'flex gap-1 items-center'}>
                        <img className={'w-10 h-10 rounded-full bg-red-600 border-primary border-2'}
                             src={content.createdBy?.profilePhoto ?
                               `${path}/${content.createdBy?.profilePhoto?.name}`
                               : 'https://www.cosavostra.com/wp-content/uploads/2020/04/logo-cosavostra-hashtag.svg'
                             } alt={'createdBy'}
                        />
                        <div className={'flex flex-col'}>
                          <span className={'text-sm font-light m-0 p-0'}>Créé par:</span>
                          <span
                            className={'font-medium m-0 p-0'}>{content.createdBy?.firstName} {content.createdBy?.lastName}</span>
                        </div>
                      </div>
                      {content.managedBy ?
                        <div className={'flex gap-1 items-center'}>
                          <img className={'w-10 h-10 rounded-full border-primary border-2'}
                               src={content.managedBy.profilePhoto ?
                                 `${path}/${content.managedBy.profilePhoto?.name}`
                                 : 'https://www.cosavostra.com/wp-content/uploads/2020/04/logo-cosavostra-hashtag.svg'
                               } alt={'createdBy'}
                          />
                          <div className={'flex flex-col'}>
                            <span className={'text-sm font-light m-0 p-0'}>Géré par:</span>
                            <span
                              className={'font-medium m-0 p-0'}>{content.managedBy.firstName} {content.managedBy.lastName}</span>
                          </div>
                        </div>
                        :
                        <div className={'flex gap-1 items-center'}>
                          <div
                            className={'w-10 h-10 rounded-full border-primary border-2 text-white bg-red-700 text-2xl flex items-center justify-center font-bold'}>
                            ?
                          </div>
                          <div className={'flex flex-col'}>
                            <span className={'text-sm font-light m-0 p-0'}>Géré par:</span>
                            <span className={'font-medium m-0 p-0'}>Aucun</span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <Pagination totalItems={totalItems} onPageChange={onPageChange} />
          </>
          :
          <ItemNotFound />
        }

      </div>
    </>
  );
}

export default ListOfContent;