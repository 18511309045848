import React from 'react';
import TrashIcon from '../icons/trashIcon';

function DeleteButton({ onClick }) {
  return (
    <button className={'p-1 border border-[#FF0000] text-[#FF0000] w-fit rounded-md hover:text-white hover:bg-[#FF0000]'} onClick={onClick}>
      <TrashIcon size={'w-6 h-6'} />
    </button>
  );
}

export default DeleteButton;