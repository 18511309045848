import React from 'react';
import GoogleCalendarIcon from './googleCalendarIcon';
import axios from 'axios';
import {useSelector} from 'react-redux';

function GoogleAuthButton(props) {
  const baseUrl = process.env.REACT_APP_BACK_END_URL;
  const {isSynced} = useSelector((state) => state.users);

  function handleSyncToGoogleCalendar() {
    axios.get(`${baseUrl}/api/google_auth`)
      .then((res) => {
        window.open(res.data.authUrl);
      });
  }

  if (isSynced === true || isSynced === null) {
    return null;
  }

  return (
    <button className={'cursor-pointer flex gap-2 p-2 bg-secondary text-white rounded-lg w-full border items-center justify-center text-sm hover:text-primary hover:border-primary hover:bg-white'} onClick={handleSyncToGoogleCalendar}>
      <GoogleCalendarIcon />
      Synchronisation
    </button>
  );
}

export default GoogleAuthButton;