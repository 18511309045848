import api from '../../hooks/Api';
import {call, put, takeEvery} from 'redux-saga/effects';
import {httpFailure} from '../category/categorySlice';
import {
  deleteTask,
  editTask,
  addTaskSuccess,
  getFilteredTasks,
  fillTaskSuccess,
  startTaskSuccess,
  validateTask,
  publishTaskSuccess
} from './tasksSlice';
import {addTask} from '../content/contentSaga';
import {getNextStatus} from '../status/statusSaga';
import {uploadFile} from '../auth/authSaga';
import {toast} from 'react-toastify';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

function* workerEditTask(action) {
  let destinationsList = [];

  for (let i in action.payload.destination) {
    destinationsList.push({destination: action.payload.destination[i]['@id']});
  }

  const task = {
    ...action.payload,
    assignedTo: `/api/users/${action.payload.assignedTo.id}`,
    destinations: destinationsList,
    category: action.payload.category['@id']
  };

  delete task.status;
  delete task.file;
  try {
    const res = yield call(() => api({
      url: `${baseUrl}${action.payload['@id']}`,
      method: 'put',
      data: task
    }));

    yield put(editTask({...res, index: task.index}));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* workerDeleteTask(action) {
  try {
    yield call(() => api({
      url: `${baseUrl}${action.payload['@id']}`,
      method: 'delete'
    }));

    yield put(deleteTask());
  } catch (e) {
    yield put(httpFailure());
  }
}

function* workerAddTask(action) {
  let destinationsList = [];
  for (let i in action.payload.destination) {
    destinationsList.push({destination: action.payload.destination[i]['@id']});
  }

  const data = {
    ...action.payload,
    assignedTo: `/api/users/${action.payload.assignedTo.id}`,
    destinations: destinationsList,
    category: action.payload.category['@id']
  };

  try {
    const res = yield addTask(data);
    yield put(addTaskSuccess(res));
  } catch {}
}

function* workerFilterTasksByUsers(action) {
  let createdBy = '';
  if (action.payload.createdBy) {
    createdBy = `createdBy.email=${action.payload.createdBy}&`;
  }

  let assignedTo = '';
  if (action.payload.createdBy) {
    assignedTo = `assignedTo.email=${action.payload.assignedTo}`;
  }

  const res = yield call(() => api({
    url: `${baseUrl}/api/tasks?${createdBy}${assignedTo}`,
    method: 'get'
  }));

  yield put(getFilteredTasks(res.data));
}

function* workerFilterTasks(action) {
  let deadlineAt = '';
  if (action.payload.date) {
    deadlineAt = `deadlineAt[before]=${action.payload.date}&`;
  }

  let title = '';
  if (action.payload.search) {
    title = `title=${action.payload.search}&`;
  }

  let contentId = '';
  if (action.payload.contentId) {
    contentId = `content=${action.payload.contentId}&`;
  }

  let created = '';
  if (action.payload.createdByMe) {
    created = `createdBy.email=${action.payload.createdByMe}&`;
  }

  let assigned = '';
  if (action.payload.assignedToMe) {
    assigned = `assignedTo.email=${action.payload.assignedToMe}&`;
  }

  let status = '';
  if (action.payload.statusId) {
    status = `status=${action.payload.statusId}`;
  }

  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/tasks?${deadlineAt}${title}${contentId}${created}${assigned}${status}`
    }));

    yield put(getFilteredTasks(res.data));
  } catch {}
}

function* workerStartTask(action) {
  try {
    const task = action.payload;

    const nextStatus = yield getNextStatus({level: task.status.level});
    let payload = {
      status: `/api/statuses/${nextStatus.data.id}`,
      startedAt: new Date()
    };

    const res = yield call(() => api({
      url: `${baseUrl}/api/tasks/${task.id}`,
      method: 'put',
      data: payload
    }));

    yield put(startTaskSuccess(res));
  } catch {}
}

function* workerFillTask(action) {
  const task = action.payload;
  for (let i in task.myFiles) {
    task.myFiles[i].task = task.id;
    yield uploadFile(task.myFiles[i]);
  }

  const res = yield call(() => api({
    url: `${baseUrl}/api/tasks/${task.id}`,
    method: 'put',
    data: task
  }));

  yield put(fillTaskSuccess(res.data));
}

function* workerFinishTask(action) {
  const status = yield getNextStatus({level: action.payload.status.level});
  const payload = {
    ...action.payload,
    status: `/api/statuses/${status.data.id}`
  };

  yield workerFillTask({payload});
}

function* workerValidateTask(action) {
  const status = yield getNextStatus({level: action.payload.status.level});
  const task = {
    ...action.payload,
    status: `/api/statuses/${status.data.id}`
  };

  const res = yield call(() => api({
    url: `${baseUrl}/api/tasks/${task.id}`,
    method: 'put',
    data: task
  }));

  yield put(validateTask(res.data));
}

function* workerChangeStatus(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/tasks/${action.payload.id}`,
      method: 'put',
      data: action.payload
    }));

    yield put(fillTaskSuccess(res.data));
  } catch {}
}

function* workerPublishTask(action) {
  const res = yield call(() => api({
    url: `${baseUrl}/api/publish_to_wp`,
    method: 'post',
    data: action.payload
  }));

  yield put(publishTaskSuccess(res));
}

export function* watcherTasks() {
  yield takeEvery('EDIT_TASK', workerEditTask);
  yield takeEvery('DELETE_TASK', workerDeleteTask);
  yield takeEvery('ADD_TASK', workerAddTask);
  yield takeEvery('FETCH_TASKS_BY_USERS', workerFilterTasksByUsers);
  yield takeEvery('FILTER_TASKS', workerFilterTasks);
  yield takeEvery('START_TASK', workerStartTask);
  yield takeEvery('FILL_TASK', workerFillTask);
  yield takeEvery('FINISH_TASK', workerFinishTask);
  yield takeEvery('VALIDATE_TASK', workerValidateTask);
  yield takeEvery('CHANGE_STATUS', workerChangeStatus);
  yield takeEvery('PUBLISH_TASK', workerPublishTask);
}