import React, {useState} from 'react';
import InputField from '../ui/inputField';
import Button from '../ui/button';
import {useDispatch} from 'react-redux';
import {categorySagaActions} from '../../redux/category/categorySagaActions';

function CategoryForm({category, index, closeModal}) {
  const [name, setName] = useState(category ? category.name : '');
  const [nameError, setNameError] = useState('');
  const dispatch = useDispatch();
  function submitForm() {
    if (name.length === 0) {
      setNameError('Ce champ est obligatoire!');
      return;
    }
    
    if (!category) {
      dispatch(categorySagaActions.addCategory({name}));
    } else {
      category = {...category, name: name};
      dispatch(categorySagaActions.editCategory({category, index}))
    }

    closeModal();
  }

  return (
    <>
      <div className="flex justify-between">
        <p className={'text-primary font-medium mb-5'}> {category ? 'Modifier ' : 'Nouvelle '} catégorie</p>
        <span className={'cursor-pointer'} onClick={closeModal}>x</span>
      </div>
      <div className={'w-72 flex flex-col items-end gap-3'}>
        <InputField type={'text'} label={'Catégorie'} placeholder={'Catégorie'} value={name}
                    onChange={(e) => setName(e.currentTarget.value)} error={nameError} />
        <div className="w-2/5">
          <Button className={'bg-primary text-white'} onClick={submitForm} value={category ? 'Modifier' : 'Ajouter'} />
        </div>
      </div>
    </>
  );
}

export default CategoryForm;
