export const usersSagaActions = {
  getAllUsers,
  deactivateAccount,
  deactivateUser,
  addUser,
  editUser,
  getContentCreators,
  getUsersByRole,
  getUsers,
  syncToCalendar,
  getCalendarEvents
};

function getAllUsers(payload) {
  return {
    type: 'FETCH_ALL_USERS',
    payload
  };
}

function deactivateAccount(payload) {
  return {
    type: 'DEACTIVATE_ACCOUNT',
    payload
  };
}

function deactivateUser(payload) {
  return {
    type: 'DEACTIVATE_USER',
    payload
  };
}

function addUser(payload) {
  return {
    type: 'ADD_USER',
    payload
  };
}

function editUser(payload) {
  return {
    type: 'EDIT_USER',
    payload
  };
}

function getContentCreators() {
  return {
    type: 'FETCH_CONTENT_CREATORS'
  };
}

function getUsersByRole(payload) {
  return {
    type: 'FETCH_USER_BY_ROLE',
    payload
  };
}

function getUsers() {
  return {
    type: 'FETCH_USERS',
  };
}

function syncToCalendar(payload) {
  return {
    type: 'SYNC_TO_CALENDAR',
    payload
  }
}

function getCalendarEvents() {
  return {
    type: 'GET_CALENDAR_EVENTS'
  }
}