import React from 'react';
import ArrowUpIcon from '../icons/arrowUpIcon';
import ArrowDownIcon from '../icons/arrowDownIcon';

function FilterButton({onClick, showFilter}) {
  return (
    <button
      onClick={onClick}
      className={'flex items-center bg-white text-primary rounded border border-primary text-sm md:px-4 py-2 px-2 hover:drop-shadow-xl hover:bg-primary hover:text-white gap-2'}>
      <span>Filtres</span>
      {showFilter ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </button>
  );
}

export default FilterButton;