export default function getRole(roles) {
  if (roles?.includes('ROLE_ADMIN')) {
    return {key: 'ROLE_ADMIN', text: 'Admin'};
  }

  if (roles?.includes('ROLE_HEAD_OF')) {
    return {key: 'ROLE_HEAD_OF', text: 'Head Of'};
  }

  if (roles?.includes('ROLE_MANAGER')) {
    return {key: 'ROLE_MANAGER', text: 'Manager'};
  }

  return {key: 'ROLE_USER', text: 'Utilisateur'};
}