import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const initialState = {
  totalItems: 0,
  categoriesList: [],
  activeCategoriesList: [],
  error: null,
  success: false
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    httpFailure() {
      toast.error('Une erreur est survenue veuillez réessayer ultérieurement', {theme: 'dark'});
    },
    getAllCategories(state, action) {
      state.totalItems = action.payload['hydra:totalItems'];
      state.categoriesList = action.payload['hydra:member'];
    },
    getActiveCategories(state, action) {
      state.totalItems = action.payload['hydra:totalItems'];
      state.activeCategoriesList = action.payload['hydra:member'];
    },
    addCategory(state, action) {
      toast.success('Catégorie ajoutée avec succès!', {theme: 'dark'});
      state.categoriesList.push(action.payload.data);
    },
    editCategory(state, action) {
      toast.success('Catégorie modifiée avec succès!', {theme: 'dark'});
      state.categoriesList[action.payload.index] = action.payload.data;
    },
    deactivateCategory(state, action) {
      const etat = action.payload.data.isActive ? 'activée' : 'désactivée';
      toast.success(`Catégorie ${etat} avec succès.`, {theme: 'dark'});
      state.categoriesList[action.payload.index] = action.payload.data;
    }
  }
});

export const {
  httpFailure,
  getAllCategories,
  getActiveCategories,
  addCategory,
  editCategory,
  deactivateCategory
} = categorySlice.actions;

export default categorySlice.reducer;