import React, {useEffect, useState} from 'react';
import Button from '../ui/button';
import {useDispatch, useSelector} from 'react-redux';
import getRole from '../../hooks/getRole';
import {modalStyle} from '../../utils/modalStyle';
import Modal from 'react-modal';
import {statusSagaActions} from '../../redux/status/statusSagaActions';
import CommentForm from '../forms/commentForm';
import PublishTasks from './publishTasks';

function TaskManagement({done, handleSave, handleFinish, handleValidate, item}) {
  const {user_roles} = useSelector((state) => state.auth);
  const {comment} = useSelector((state) => state.comment);
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(statusSagaActions.isFinalStatus({level: item.status.level}));
  }, [dispatch, item]);

  useEffect(() => {
    if (comment) {
      setIsOpen(false);
    }
  }, [comment]);

  function closeModal() {
    setIsOpen(false);
  }

  function handleReject() {
    setIsOpen(true);
  }

  return (
    !item.isDone ?
      <>
      <div className={'flex gap-2 items-center w-1/2'}>
        {done ?
          <>
            {
              getRole(user_roles).key === 'ROLE_USER' ?
                <>
                  <Button value={'En attente de validation ...'} className={'hover:border-primary border-primary text-primary cursor-default'} />
                </>
                :
                <>
                  <Button value={'Rejeter'} className={'hover:border-primary border-primary text-primary'} onClick={handleReject} />
                  <Button value={'Valider'} className={'border-primary text-white border-primary bg-primary'} onClick={handleValidate} />
                </>
            }
          </>
          :
          <>
            <Button value={'Sauvegarder'} className={'hover:border-primary border-primary text-primary'} onClick={handleSave} />
            <Button value={'Terminer'} className={'border-primary text-white border-primary bg-primary'} onClick={handleFinish} />
          </>
        }
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        <CommentForm
          item={item}
          closeModal={closeModal}
          title={'Voulez vous vraiment rejeter cette tâche?'}
          subTitle={'Pour confirmer votre choix, veuillez ajouter au moins un commentaire.'}
        />
      </Modal>
    </>
      :
      <PublishTasks task={item} />
  );
}

export default TaskManagement;