import React, {useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import fr from '@fullcalendar/core/locales/fr';
import {useDispatch, useSelector} from 'react-redux';
import {tasksSagaActions} from '../../redux/task/tasksSagaActions';
import ArrowRightIcon from '../../components/icons/arrowRightIcon';
import InputField from '../../components/ui/inputField';
import {contentSagaActions} from '../../redux/content/contentSagaActions';
import {statusSagaActions} from '../../redux/status/statusSagaActions';
import Modal from 'react-modal';
import {modalStyle} from '../../utils/modalStyle';
import TaskDetail from '../../components/tasks/taskDetail';
import {format} from 'date-fns';
import Pill from '../../components/ui/pill';
import EyeIcon from '../../components/icons/eyeIcon';
import GoogleAuthButton from '../../components/ui/googleAuthButton';
import {useLocation} from 'react-router-dom';
import {usersSagaActions} from '../../redux/user/usersSagaActions';

function Calendar(props) {
  Modal.setAppElement('#root');
  const {filteredTasks} = useSelector(state => state.task);
  const dispatch = useDispatch();
  const [param, setParam] = useState('');
  const [myEvents, setMyEvents] = useState([]);
  const [todos, setToDos] = useState([]);
  const [showFilter, setShowFilter] = useState('');
  const [content, setContent] = useState(null);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState('');
  const {statusList} = useSelector(state => state.status);
  const {contentList} = useSelector((state) => state.content);
  const {task} = useSelector((state) => state.task);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [myTask, setMyTask] = useState(null);
  const {comment} = useSelector((state) => state.comment);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const {calendarEvents} = useSelector((state) => state.users);

  useEffect(() => {
    if (code) {
      dispatch(usersSagaActions.syncToCalendar({code}));
    }
  }, []);

  useEffect(() => {
    dispatch(tasksSagaActions.filterTasks({contentId: content?.id, statusId: status?.id, search}));
  }, [content, status, search, task, comment, dispatch]);

  useEffect(() => {
    dispatch(statusSagaActions.getAllStatus());
    dispatch(contentSagaActions.getAllContent());
    dispatch(tasksSagaActions.filterTasks({}));
    dispatch(usersSagaActions.getCalendarEvents());
  }, [dispatch]);

  useEffect(() => {
    if (myTask?.id) {
      setMyTask(filteredTasks.filter((t) => t.id === myTask.id)[0]);
    }

    if (filteredTasks) {
      let list = [];
      let todosList = [];
      filteredTasks.map((task) => {
        if (task.startedAt && task.deadlineAt >= task.startedAt ) {
          list.push({
            title: task.title,
            start: format(new Date(task.startedAt), 'yyyy-MM-dd'),
            end: format(new Date(task.deadlineAt), 'yyyy-MM-dd'),
            color: task.status.color,
            ...task
          });
        } else if (!task.startedAt && new Date(task.deadlineAt) >= new Date() && todos.length === 0) {
          todosList.push(task);
        }
      });

      setMyEvents(list);

      if (todosList.length > 0) {
        setToDos(todosList);
      }
    }
  }, [filteredTasks]);

  function toggleFilter(field) {
    if (showFilter === field) {
      setShowFilter('');
    } else {
      setShowFilter(field);
    }
  }

  function handleStartTask(task) {
    let list = todos.filter(t => t !== task);

    let payload = {
      id: task.id,
      status: task.status
    };

    dispatch(tasksSagaActions.startTask(payload));

    setToDos(list);
  }

  function handleEventClick(val) {
    val.jsEvent.preventDefault();

    if (val.event.url) {
      window.open(val.event.url, '_blank');
      return;
    }

    setMyTask({
      ...val.event.extendedProps,
      id: val.event.id,
      title: val.event.title
    });

    openModal();
  }

  function handlePreviewTask(val) {
    setMyTask(val);

    openModal();
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function toggleParam(val) {
    if (param === val) {
     setParam('');
    } else {
      setParam(val);
    }
  }

  function resetFilter() {
    setSearch('');
    setContent(null);
    setStatus(null);
    setShowFilter('');
  }

  return (
    <>
      <div className={'flex flex-col md:flex-row gap-2 md:gap-4'}>
        <div className={'flex flex-col w-full md:w-1/6 gap-4'}>
          <h2 className={'pt-2 md:mb-2'}>Mon calendrier</h2>
          {todos.length > 0 &&
            <div className={'flex flex-col bg-secondary bg-opacity-20 rounded font-medium border border-[#e5e7eb]'}>
              <div className={'flex justify-between items-center px-2 border-b border-primary'}>
                <div className={'w-full text-center md:mb-2 py-2'} onClick={() => setParam('tasks')}>
                  Tâches non démarrées
                </div>
                <svg onClick={() => toggleParam('tasks')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-7 h-7 md:hidden">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </div>
              <div className={'max-h-[12rem] overflow-y-auto md:block ' + (param === 'tasks' ? 'block' : 'hidden')}>
                {todos?.map((task, index) => (
                  <li className={' hover:bg-secondary hover:bg-opacity-60 hover:text-white flex justify-between items-center px-4 py-2'} key={index}>
                    <p>{task.title}</p>
                    <div className={'flex flex-row gap-2'}>
                      <span className={'hover:text-blue-600 cursor-pointer'} title={'Prévisualiser'} onClick={() => handlePreviewTask(task)}>
                        <EyeIcon size={'w-5 h-5'} />
                      </span>
                      <span className={'hover:text-green-600 cursor-pointer'} title={'Commencer'} onClick={() => handleStartTask(task)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
                        </svg>
                      </span>
                    </div>
                  </li>
                ))
                }
            </div>
          </div>
          }
          <div className={'flex flex-col bg-secondary bg-opacity-20 rounded font-medium border border-[#e5e7eb]'}>
            <div className={'flex justify-between items-center px-2 border-b border-primary'}>
              <div className={'w-full text-center md:mb-2 py-2'} onClick={() => setParam('tasks')}>
                Filtres
              </div>
              <svg onClick={() => toggleParam('filtres')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-7 h-7 md:hidden">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </div>
            <ul className={'space-y-2 md:block ' + (param === 'filtres' ? 'block' : 'hidden')}>
              <li className={'flex flex-col cursor-pointer'}>
                <span className={'flex justify-between items-center w-full hover:bg-secondary hover:bg-opacity-60 hover:text-white px-4 py-2'} onClick={() => toggleFilter('content')}>
                  <p>Contenu</p>
                  <ArrowRightIcon size={'h-3 w-3'}/>
                </span>
                <ul className={'px-6 space-y-1.5 max-h-[8rem] overflow-y-auto ' + (showFilter === 'content' ? 'block' : 'hidden')}>
                  {contentList?.map((content, index) => (
                    <li className={'hover:bg-secondary hover:bg-opacity-30 rounded p-1'} key={index} onClick={() => setContent(content)}>{content.title}</li>
                  ))}
                </ul>
              </li>
              <li className={'flex flex-col cursor-pointer'}>
                <span className={'flex justify-between items-center w-full hover:bg-secondary hover:bg-opacity-60 hover:text-white px-4 py-2'} onClick={() => toggleFilter('status')}>
                  <p>Statut</p>
                  <ArrowRightIcon size={'h-3 w-3'}/>
                </span>
                <ul className={'px-6 space-y-1.5 max-h-[8rem] overflow-y-auto ' + (showFilter === 'status' ? 'block' : 'hidden')}>
                  {statusList?.map((status, index) => (
                    <li className={'hover:bg-secondary hover:bg-opacity-30 rounded p-1'} key={index} onClick={() => setStatus(status)}>
                      <Pill color={status.color} text={status.name} />
                    </li>
                  ))
                  }
                </ul>
              </li>
              <li className={'flex flex-col cursor-pointer'}>
                <span className={'flex justify-between items-center w-full hover:bg-secondary hover:bg-opacity-60 hover:text-white px-4 py-2'} onClick={() => toggleFilter('search')}>
                  <p>Recherche</p>
                  <ArrowRightIcon size={'h-3 w-3'} />
                </span>
                <div className={'px-3 pb-2 ' + (showFilter === 'search' ? 'block' : 'hidden')}>
                  <InputField className={'text-sm'} placeholder={'Tâche'} onChange={(e) => setSearch(e.target.value)} />
                </div>
              </li>
          </ul>
            <div className={'w-full text-right font-light text-sm hover:underline pr-4 cursor-pointer hover:font-normal mb-2'} onClick={resetFilter}>
              Réinitialiser tous les filtres.
            </div>
          </div>
          <GoogleAuthButton />
        </div>
        <div className={'w-full md:w-5/6'}>
          <FullCalendar
            firstDay={0}
            dayMaxEventRows={3}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            locale={fr}
            businessHours={true}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            eventSources={[myEvents, calendarEvents]}
            editable={false}
            eventClassNames={'cursor-pointer'}
            eventClick={handleEventClick}
          />
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        <div className={'w-[70vw] min-h-[60vh] max-h-[80vh] overflow-y-auto'}>
          <TaskDetail item={myTask} />
        </div>
      </Modal>
    </>
  );
}

export default Calendar;