import React, {useEffect} from 'react';
import NotificationCard from './notificationCard';
import {useDispatch, useSelector} from 'react-redux';
import {notificationSagaActions} from '../../redux/notification/notificationSagaActions';
import {io} from 'socket.io-client';
import ArrowRightIcon from '../icons/arrowRightIcon';
import {Link} from 'react-router-dom';

function Notifications({isVisible, toggleIsVisible, setNotifications}) {
  const socketUrl = process.env.REACT_APP_SOCKET_URL;

  const {notifications, notification, unSeenNotifications, updatedAt} = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  useEffect(() => {
    const socket = io(socketUrl);

    socket.on('new_notification', ()=> {
      dispatch(notificationSagaActions.getNotifications());
      dispatch(notificationSagaActions.getUnSeenNotifications());
    });
  }, []);

  useEffect(() => {
    dispatch(notificationSagaActions.getNotifications());
    dispatch(notificationSagaActions.getUnSeenNotifications());
  }, [dispatch, notification, updatedAt]);

  useEffect(() => {
      setNotifications(unSeenNotifications);
  },[unSeenNotifications, notification, updatedAt]);

  function markAllAsRead() {
    dispatch(notificationSagaActions.markNotificationsAsRead());
  }

  if (!isVisible) {
    return;
  }

  return (
    <div className={'max-h-screen md:w-96 w-screen bg-white overflow-y-auto border md:border-r-primary border-b-primary'}>
      <div className={'my-2 md:my-2 w-full flex flex-row items-center justify-between px-3'}>
        <h2>Notifications ({unSeenNotifications})</h2>
        <span className={'cursor-pointer'} onClick={toggleIsVisible}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:h-7 hover:w-7">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </span>
      </div>
      <div className={'flex flex-col'}>
        {notifications?.map((notification, index) => (
          <NotificationCard notification={notification} key={index} />
        ))}
      </div>
      <div className={'w-full flex justify-between items-center p-1'}>
        <Link to={'#'} onClick={markAllAsRead} className={'text-secondary'}>
          Marquer tout comme lu
        </Link>
        <Link to={'/notifications'} onClick={toggleIsVisible} className={'flex flex-row items-center gap-2 text-cosa-yellow'}>
          Voir tous
          <ArrowRightIcon size={'h-4 w-4'} />
        </Link>
      </div>
    </div>
  );
}

export default Notifications;