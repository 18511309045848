export const themesSagaActions = {
  getAllActiveThemes,
  addTheme,
  editTheme,
  toggleIsThemeActive,
  getActiveThemes
};

function getAllActiveThemes(payload) {
  return {
    type: 'FETCH_ALL_THEMES',
    payload
  };
}

function addTheme(payload) {
  return {
    type: 'ADD_THEME',
    payload
  };
}

function editTheme(payload) {
  return {
    type: 'EDIT_THEME',
    payload
  };
}

function toggleIsThemeActive(payload) {
  return {
    type: 'TOGGLE_IS_ACTIVE',
    payload
  };
}

function getActiveThemes() {
  return {
    type: 'FETCH_ACTIVE_THEMES'
  };
}