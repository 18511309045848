import React, {useCallback, useEffect, useState} from 'react';
import Pill from '../ui/pill';
import {format} from 'date-fns';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import {fr} from 'date-fns/locale';
import Button from '../ui/button';
import {useDispatch, useSelector} from 'react-redux';
import {tasksSagaActions} from '../../redux/task/tasksSagaActions';
import {modalStyle} from '../../utils/modalStyle';
import TaskForm from '../forms/taskForm';
import Modal from 'react-modal';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {statusSagaActions} from '../../redux/status/statusSagaActions';
import InputField from '../ui/inputField';
import {useDropzone} from 'react-dropzone';
import ExclamationTriangle from '../icons/exclamationTriangle';
import TaskManagement from './taskManagement';
import CommentsSections from '../comments/commentsSections';
import EyeIcon from '../icons/eyeIcon';
import DisplayFile from '../ui/displayFile';
import OpenAi from '../openAi/openAi';
import TaskActions from './taskActions';

const LINK_TITLE = 'Ajouter un lien.';
const WRITE_TITLE = 'Ajouter un article.';

function TaskDetail({item, index}) {
  const [editTaskModalIsOpen, setEditTaskModalIsOpen] = useState(false);
  const [myTask, setTask] = useState({});
  const [isLink, setIsLink] = useState(true);
  const [myFiles, setMyfiles] = useState([]);
  const {initialStatus, nextStatus} = useSelector((state) => state.status);
  const [article, setArticle] = useState(item ? item.article : '');
  const [link, setLink] = useState(item ? item.link : '');
  const [error, setError] = useState('');
  const [editable, setEditable] = useState(false);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const onDrop = useCallback(acceptedFiles => {
    setMyfiles(acceptedFiles);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone(
    {
      onDrop,
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/jpg': [],
        'application/pdf': []
      },
      multiple: true,
      disabled: editable
    });

  useEffect(() => {
    if (initialStatus) {
      dispatch(statusSagaActions.getNextStatus({level: initialStatus.level}));
    }
  }, [initialStatus, dispatch]);

  useEffect(() => {
      if (nextStatus) {
        setEditable(nextStatus.id !== item.status.id);
      }
    }
    , [nextStatus, initialStatus, item]);

  useEffect(() => {
    dispatch(statusSagaActions.getInitialStatus());
  }, [dispatch]);

  useEffect(() => {
    if (link || article || myFiles.length > 0) {
      setError('');
    }
  }, [link, article, myFiles]);

  function handleStartTask() {
    dispatch(tasksSagaActions.startTask(item));
  }

  function handleEditTask(task, index) {
    setEditTaskModalIsOpen(true);
    let destination = [];

    for (const destinationKey in task.destinations) {
      destination.push({
        value: task.destinations[destinationKey].destination.name,
        label: task.destinations[destinationKey].destination.name, ...task.destinations[destinationKey].destination
      });
    }

    const item = {
      ...task, index, category: {
        value: task.category.name,
        label: task.category.name,
        ...task.category
      },
      deadlineAt: format(new Date(task.deadlineAt), 'yyyy-MM-dd'),
      destination,
      assignedTo: {
        value: task.assignedTo.email,
        label: `${task.assignedTo.firstName} ${task.assignedTo.lastName} - ${task.assignedTo.email}`,
        ...task.assignedTo
      }
    };

    setTask(item);
  }

  function refactorArticle(str) {
    setArticle(str);
  }

  function handleDeleteTask(task) {
    if (window.confirm('voulez vous vraiment supprimer cette tâches de façon permanente?')) {
      dispatch(tasksSagaActions.deleteTask(task));
    }
  }

  function closeEditTaskModal() {
    setEditTaskModalIsOpen(false);
    setTask(null);
  }

  function handleToggleLink() {
    setIsLink(!isLink);
  }

  function isFormValid() {
    if (!link && !article && myFiles.length === 0) {
      setError('veuillez remplir l\'un de ces champs: lien, article ou fichier');
      return false;
    }

    return true;
  }

  function handleSave() {
    if (isFormValid()) {
      const task = {
        id: item.id,
        link,
        article,
        myFiles
      };
      dispatch(tasksSagaActions.fillTask(task));
      setMyfiles([]);
    }
  }

  function handleFinish() {
    if (isFormValid()) {
      const task = {
        id: item.id,
        link,
        status: item.status,
        article,
        myFiles
      };

      dispatch(tasksSagaActions.finishTask(task));
    }
  }

  function handleValidate() {
    const task = {
      id: item.id,
      status: item.status,
      isDone: true
    };

    dispatch(tasksSagaActions.validateTask(task));
  }

  function handlePreviewFile(file) {
    setFile(file);
  }

  function handleClosePreview() {
    setFile(null);
  }

  function renderCKEditor(myheight, mywidth) {
    return (
      <CKEditor
        disabled={editable}
        data={article}
        config={{
          toolbar: {
            items: ['selectAll', '|',
              'heading', '|',
              'bold', 'italic', '|',
              'bulletedList', 'numberedList', '|',
              'undo', 'redo',
              'link', 'blockQuote'
            ]
          }
        }}
        editor={ClassicEditor}
        onReady={(editor) => {
          editor.editing.view.change((writer) => {
            writer.setStyle(
              "max-height",
              myheight,
              editor.editing.view.document.getRoot()
            )
            writer.setStyle(
              "max-width",
              mywidth,
              editor.editing.view.document.getRoot()
            )
          })
          if (editor.ui.view.editable.element.offsetParent) {
            editor.ui.view.editable.element.offsetParent.style.minWidth = '100%';
            editor.ui.view.editable.element.style.minWidth = '100%';
          }
        }}
        onChange={(event, editor) => {
          setArticle(editor.getData());
        }
        }
      />
    )
  }

  function makeFullScreen() {
    setIsFullScreen(true);
  }

  function exitFullScreen() {
    setIsFullScreen(false);
  }

  return (
    <>
      <OpenAi article={article} refactorArticle={refactorArticle} isEditable={editable} />
      <div className={'fixed w-full h-full top-0 right-0 md:py-12 md:px-24 py-12 px-4 bg-black bg-opacity-60 z-50 overflow-x-auto ' + (isFullScreen ? 'block' : 'hidden')}>
        <div className={'fixed md:top-6 md:right-8 top-2 right-4 rounded-full hover:bg-gray-300 bg-gray-200 p-2 z-50 cursor-pointer'} onClick={exitFullScreen}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 md:w-6 md:h-6">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"/>
          </svg>
        </div>
        {renderCKEditor('80vh', '100%')}
      </div>
      <div className={'flex flex-col md:flex-row relative'}>
        <TaskActions
          item={item}
          index={index}
          handleDeleteTask={handleDeleteTask}
          handleEditTask={handleEditTask}
        />
        <div className={'w-full flex flex-col gap-2 items'}>
          <div className={'flex gap-6 items-center'}>
            <h2 className={'capitalize'}>{item.title}</h2>
            <div>
              <Pill color={item.status.color} text={item.status.name} />
            </div>
          </div>
          <h3>Description</h3>
          <p>{item.description}</p>
          <div className={'flex gap-3 items-center'}>
            <h3>Destination(s):</h3>
            {
              item.destinations
                ?.map((dest, i) => (
                <p key={i}>{dest.destination.name}</p>
              ))
                ?.reduce((prev, curr) => [prev, ' | ', curr], '')}
          </div>
          <div className={'flex gap-3 items-center'}>
            <h3>Deadline: </h3>
            <p className={'text-sm'}>
              {format(new Date(item.deadlineAt), 'dd/MM/yy')}
              &nbsp;
              <span className={'text-sm font-light'}>
            ({formatDistanceToNow(new Date(item.deadlineAt), {addSuffix: true, locale: fr})})
          </span>
            </p>
          </div>
          <div className={'w-1/5'}>
            {
              initialStatus?.id === item.status.id ?
                <Button className={'border-primary text-white bg-primary px-12'}
                        value={'Commencer'} onClick={handleStartTask} />
                : null
            }
          </div>
          <CommentsSections comments={item.comments} />
        </div>
          {
            initialStatus?.id !== item.status.id ?
              <>
                <div className={'hidden md:block w-0.5 bg-primary'} />
                <div className={'flex flex-col gap-2 px-1 py-4 md:px-4 md:py-8 items-end w-full'}>
                  <div className={'w-full'}>
                    {error ?
                      <div
                        className={'flex justify-center bg-red-100 rounded-lg p-2 text-red-700 w-full gap-4 mb-3'}>
                        <ExclamationTriangle />
                        <p>
                          {error}
                        </p>
                      </div>
                      : null
                    }
                    {
                      !isLink ?
                        <>
                          <InputField
                            type={'link'}
                            label={'Ajouter un lien'}
                            placeholder={'Ajouter un lien'}
                            value={link}
                            readOnly={editable}
                            onChange={(e) => setLink(e.target.value)}
                          />
                          <span
                            className={'font-light text-sm hover:underline cursor-pointer text-gray-500 hover:text-gray-700'}
                            onClick={handleToggleLink}>
                            {WRITE_TITLE}
                          </span>
                        </>
                        :
                        <div className={'relative pt-4 md:p-0'}>
                          {renderCKEditor('5rem', '41.75rem')}
                          <div className={'absolute md:right-2 right-2 md:top-2 -top-3'}>
                            <div className={'cursor-pointer ' + (isFullScreen ? 'hidden':'block')} onClick={makeFullScreen}>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:w-7 hover:h-7">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                              </svg>
                            </div>
                          </div>
                          <span className={'font-light text-sm hover:underline cursor-pointer text-gray-500 hover:text-gray-700'} onClick={handleToggleLink}>
                            {LINK_TITLE}
                          </span>
                        </div>
                    }
                  </div>
                  <div className={'w-full flex items-center justify-center gap-2'}>
                    <div className={'w-32 h-0.5 bg-primary'} />
                    <p className={'text-center'}>ou bien</p>
                    <div className={'w-32 h-0.5 bg-primary'} />
                  </div>
                  <div
                    className={'w-full bg-white text-secondary border-secondary border rounded text-center font-medium hover:cursor-pointer py-4 px-4 hover:text-white hover:bg-primary'} {...getRootProps()}>
                    <input {...getInputProps()}/>
                    {
                      isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p className={'flex justify-center items-center gap-2'}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                               stroke="currentColor" className="w-8 h-8 md:h-6 md:w-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                          </svg>
                          Glisser & déposer ou cliquer pour ajouter un document.
                        </p>
                    }
                    {
                      myFiles?.map(file => (
                        <li className={'w-full text-sm text-left px-3'} key={file.path}>
                          {file.name}
                        </li>))
                    }
                  </div>
                  <ul className={'w-full list-disc px-2 space-y-1'}>
                    {item.files?.map((file, idx) => (
                      <div key={idx} className={'flex flex-row gap-2'}>
                        <div className={'flex flex-row gap-1'}>
                        <span className={'rounded border-primary border p-1 cursor-pointer'} onClick={() => handlePreviewFile(file)}>
                          <EyeIcon />
                        </span>
                        </div>
                        <span>{file.originalName}</span>
                      </div>
                      ))}
                  </ul>
                  <TaskManagement done={editable} handleFinish={handleFinish} handleSave={handleSave} item={item} handleValidate={handleValidate} />
                </div>
              </>
              : null
          }
      </div>
      <DisplayFile file={file} handleClosePreview={handleClosePreview} />
      <Modal
        isOpen={editTaskModalIsOpen}
        onRequestClose={closeEditTaskModal}
        style={modalStyle}
      >
        <div className={'w-[70vw] overflow-y-auto max-h-[80vh]'}>
          <TaskForm task={myTask} isFormVisible={true} onDelete={closeEditTaskModal} />
        </div>
      </Modal>
    </>
  );
}

export default TaskDetail;