export const statusSagaActions = {
  getAllStatus,
  getActiveStatus,
  addStatus,
  editStatus,
  deactivateStatus,
  getInitialStatus,
  getNextStatus,
  isFinalStatus
};

function getAllStatus(payload) {
  return {
    type: 'FETCH_ALL_STATUS',
    payload
  };
}

function getActiveStatus() {
  return {
    type: 'FETCH_ACTIVE_STATUS'
  };
}

function addStatus(payload) {
  return {
    type: 'ADD_STATUS',
    payload
  };
}

function editStatus(payload) {
  return {
    type: 'EDIT_STATUS',
    payload
  };
}

function deactivateStatus(payload) {
  return {
    type: 'DEACTIVATE_STATUS',
    payload
  };
}

function getInitialStatus() {
  return {
    type: 'GET_INITIAL_STATUS'
  }
}

function getNextStatus(payload) {
  return {
    type: 'GET_NEXT_STATUS',
    payload
  }
}

function isFinalStatus(payload) {
  return {
    type: 'IS_FINAL_STATUS',
    payload
  }
}