import React, {useEffect, useState} from 'react';
import ArrowRightIcon from '../../components/icons/arrowRightIcon';
import {Link} from 'react-router-dom';
import TagIcon from '../../components/icons/tagIcon';
import PenIcon from '../../components/icons/penIcon';
import TrashIcon from '../../components/icons/trashIcon';
import {modalStyle} from '../../utils/modalStyle';
import ThemeForm from '../../components/forms/themeForm';
import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import {themesSagaActions} from '../../redux/theme/themesSagaActions';
import CheckIcon from '../../components/icons/checkIcon';

function ThemeCard({myTheme}) {
  const [showActions, setShowActions] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const {theme} = useSelector(state => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    if (theme) {
      setShowActions(false);
      setEditIsOpen(false);
    }
  }, [theme]);

  function openEdit() {
    setShowActions(false);
    setEditIsOpen(true);
  }

  function closeEdit() {
    setEditIsOpen(false);
  }

  function handleDeactivateTheme() {
    const state = myTheme.isActive ? 'désactiver' : 'activer';
    if (window.confirm(`Voulez vous vraiment ${state} cette thématique?`)) {
      let payload = {id : myTheme.id, isActive : !myTheme.isActive};
      dispatch(themesSagaActions.toggleIsThemeActive(payload));
    }
  }

  return (
    <>
      <div className={'relative w-full border border-primary rounded-lg py-2 px-4 flex flex-col shadow gap-4 hover:shadow-amber-300 hover:border-cosa-yellow h-48'}>
        <div className={'flex items-center justify-between'}>
          <div className={'flex items-center gap-2'}>
            <div style={{color: myTheme.color}}>
              <TagIcon />
            </div>
            <h2 className={'truncate ' + (myTheme.isActive? 'decoration-0' : 'line-through')}>{myTheme.title}</h2>
          </div>
          <div className={'relative'}>
            <span className={'cursor-pointer'} onClick={() => setShowActions(!showActions)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-6=5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
              </svg>
            </span>
            <div className={'absolute w-36 border border-secondary rounded bg-white z-10 ' + (showActions? 'block' : 'hidden')}>
              <button className={'w-full flex flex-row items-center justify-center gap-4 hover:bg-gray-500 py-1 hover:text-white text-sm'} onClick={openEdit}>
                <PenIcon size={'w-3 h-3'} />
                Modifier
              </button>
              {myTheme.isActive ?
                <button className={'w-full flex flex-row items-center justify-center gap-4 hover:bg-red-500 py-1 hover:text-white text-sm'} onClick={handleDeactivateTheme}>
                  <TrashIcon />
                  Désactiver
                </button>
                :
                <button className={'w-full flex flex-row items-center justify-center gap-4 hover:bg-blue-500 py-1 hover:text-white text-sm'} onClick={handleDeactivateTheme}>
                  <CheckIcon size={'w-3 h-3'}/>
                  Activer
                </button>
              }
            </div>
          </div>
        </div>
        <div className={'w-full h-0.5 bg-primary'} />
        <div className={'flex flex-col gap-2 text-secondary'}>
          <span className={'font-medium text-base'}>
            {myTheme.contents.length} contenu(s)
          </span>
          <span className={'text-blue-600 font-light'}>
            {myTheme.hashtag}
          </span>
        </div>
        {myTheme.contents.length > 0 &&
          <Link to={`/content?theme=${myTheme.title}`} className={'mt-4 w-full text-right flex flex-row items-center gap-2 justify-end text-cosa-yellow'}>
            voir tous
            <ArrowRightIcon size={'h-4 w-4'} />
          </Link>
        }
      </div>

      <Modal
        isOpen={editIsOpen}
        onRequestClose={closeEdit}
        style={modalStyle}
      >
        <ThemeForm closeModal={closeEdit} theme={myTheme} />
      </Modal>
    </>
  );
}

export default ThemeCard;