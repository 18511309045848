import jwtDecode from 'jwt-decode';

function isTokenExpired(token) {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp < Date.now() / 1000;
  } catch (e) {
    return true;
  }
}

export default isTokenExpired;