export const authSagaActions = {
  login,
  sign_up,
  sendResetEmail,
  resetPassword,
  getMe,
  editProfilePicture,
  editProfile
};

function login(payload) {
  return {
    type: 'LOGIN',
    payload
  };
}

function sign_up(payload) {
  return {
    type: 'UPLOAD_AND_SIGNUP',
    payload
  };
}

function sendResetEmail(payload) {
  return {
    type: 'SEND_RESET_EMAIL',
    payload
  };
}

function resetPassword(payload) {
  return {
    type: 'RESET_PASSWORD',
    payload
  };
}

function getMe() {
  return {
    type: 'GET_ME'
  };
}

function editProfilePicture(payload) {
  return {
    type: 'EDIT_PROFIL_PICTURE',
    payload
  };
}

function editProfile(payload) {
  return {
    type: 'EDIT_PROFILE',
    payload
  };
}
