import React, {useState} from 'react';
import InputField from '../ui/inputField';
import Button from '../ui/button';
import {useDispatch} from 'react-redux';
import {wordPressSagaActions} from '../../redux/wrodPress/wordPressSagaActions';

function WordPressForm({item, index}) {
  const [name, setName] = useState(item ? item.name : '');
  const [nameError, setNameError] = useState('');
  const [link, setLink] = useState(item ? item.link : '');
  const [linkError, setLinkError] = useState('');
  const dispatch = useDispatch();

  function isFormValid() {
    if (name === '' || link === '') {
      if (name === '') {
        setNameError('Ce champ est obligatoire!');
      }

      if (link === '') {
        setLinkError('Ce champ est obligatoire!');
      }

      return false;
    }

    return true;
  }

  function submitForm() {
    if (!isFormValid()) {
      return;
    }

    let payload = {name, link};
    if (!item) {
      return dispatch(wordPressSagaActions.addWordPress(payload));
    }

    const wordPress = {id: item.id, ...payload};
    dispatch(wordPressSagaActions.editWordPress({wordPress, index}));
  }

  return (
    <>
      <div className={'flex justify-between mb-5'}>
        <p className={'text-primary font-medium'}>
          {item ? 'Modifier ' : 'Nouveau '} WordPress
        </p>
      </div>
      <div className={'w-72 flex flex-col items-end gap-3'}>
        <InputField
          type={'text'}
          label={'WordPress'}
          placeholder={'WordPress'}
          value={name}
          error={nameError}
          onChange={(e) => {
            setName(e.target.value);
            setNameError('');
          }}
        />
        <InputField
          type={'link'}
          label={'Lien'}
          placeholder={'Lien'}
          value={link}
          error={linkError}
          onChange={(e) => {
            setLink(e.target.value);
            setLinkError('')
          }}
        />
        <div className="w-2/5">
          <Button className={'bg-primary text-white'} value={item ? 'Modifier' : 'Ajouter'} onClick={submitForm} />
        </div>
      </div>
    </>
  );
}

export default WordPressForm;