import React from 'react';
import AlertIcon from '../icons/alertIcon';

function TextAreaField({ label, value, onChange, error, ...rest}) {
  return (
    <div className={'flex flex-col items-start gap-2 w-full'}>
      <label>{label}</label>
      <textarea className={error ? 'border-[#F15D5E]' : ''} value={value} onChange={onChange} {...rest} />
      <div className={'flex flex-row text-[#F15D5E] text-sm gap-2 ' + (error ? 'show' : 'hidden')}>
        <AlertIcon />
        <span>{error}</span>
      </div>
    </div>
  );
}

export default TextAreaField;