import api from '../../hooks/Api';
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  addWordPressFail,
  addWordPressSuccess,
  deleteWordPressSuccess,
  editWordPressSuccess,
  getAllWordPresses
} from './wordPressSlice';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

function* workerGetAllWordPress(action) {
  let page = '';
  if (action.payload?.page) {
    page = `_page=${action.payload.page}&`;
  }

  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/word_presses?${page}`
    }));

    yield put(getAllWordPresses(res.data));
  } catch {}
}

function* workerAddWordPress(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/word_presses`,
      method: 'post',
      data: action.payload
    }));

    yield put(addWordPressSuccess(res.data));
  } catch (e) {
    yield put(addWordPressFail(e.response.data['hydra:description']));
  }
}

function* editWordPress(action) {
  try {
    const wordPress = action.payload.wordPress;
    const res = yield call(() => api({
      url: `${baseUrl}/api/word_presses/${wordPress.id}`,
      method: 'put',
      data: wordPress
    }));

    yield put(editWordPressSuccess({data: res.data, index: action.payload.index}));
  } catch (e) {
    yield put(addWordPressFail(e.response.data['hydra:description']));
  }
}

function* deleteWordPress(action) {
  try {
    yield call(() => api({
      url: `${baseUrl}/api/word_presses/${action.payload.id}`,
      method: 'delete'
    }));

    yield put(deleteWordPressSuccess(action.payload.index));
  } catch (e) {
    yield put(addWordPressFail(e.response.data['hydra:description']));
  }
}

export function* watcherWordPress() {
  yield takeEvery('FETCH_ALL_WORD_PRESS', workerGetAllWordPress);
  yield takeEvery('ADD_WORD_PRESS', workerAddWordPress);
  yield takeEvery('EDIT_WORD_PRESS', editWordPress);
  yield takeEvery('DELETE_WORD_PRESS', deleteWordPress);
}