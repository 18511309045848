import './style/App.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {lazy, Suspense} from 'react';
import DashboardLayout from './pages/home/dashboardLayout';
import ProtectedRoutes from './routes/ProtectedRoutes';

const Login = lazy(() => import('./pages/auth/login'));
const SignUp = lazy(() => import('./pages/auth/signUp'));
const ResetPassword = lazy(() => import('./pages/auth/resetPassword'));
const ChangePassword = lazy(() => import('./pages/auth/changePassword'));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<h1> loading </h1>}>
        <Routes>
          <Route path={'/login'} element={<Login />} />
          <Route path={'/sign-up'} element={<SignUp />} />
          <Route path={'/reset-password'} element={<ResetPassword />} />
          <Route path={'/change-password/:token'} element={<ChangePassword />} />
          <Route path={'*'} element={
            <ProtectedRoutes>
              <DashboardLayout />
            </ProtectedRoutes>
          } />
        </Routes>
      </Suspense>
    </BrowserRouter>);
}

export default App;
