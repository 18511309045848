import api from '../../hooks/Api';
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  addDestination,
  deactivateDestination,
  editDestination,
  getAllDestinations,
  httpFailure
} from './destinationSlice';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

function* workerGetAllDestinations(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/destinations?_page=${action.payload}`
    }));

    yield put(getAllDestinations(res.data));
  } catch {}
}

function* workerAddDestination(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/destinations`,
      method: 'post',
      data: action.payload
    }));

    yield put(addDestination(res));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* updateDestination(action) {
  const res = yield call(() => api({
    url: `${baseUrl}/api/destinations/${action.payload.destination.id}`,
    method: 'put',
    data: action.payload.destination
  }));

  return {...res, index: action.payload.index};
}

function* workerEditDestination(action) {
  try {
    const res = yield updateDestination(action);
    yield put(editDestination(res));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* workerDeactivateDestination(action) {
  try {
    let res = yield updateDestination(action);
    yield put(deactivateDestination(res));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* workerGetActiveDestinations() {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/destinations?isActive=true`
    }));

    yield put(getAllDestinations(res.data));
  } catch (e) {
    yield put(httpFailure());
  }
}

export function* watcherDestinations() {
  yield takeEvery('FETCH_ALL_DESTINATIONS', workerGetAllDestinations);
  yield takeEvery('ADD_DESTINATION', workerAddDestination);
  yield takeEvery('EDIT_DESTINATION', workerEditDestination);
  yield takeEvery('DEACTIVATE_DESTINATION', workerDeactivateDestination);
  yield takeEvery('FETCH_ACTIVE_DESTINATIONS', workerGetActiveDestinations);
}