import api from '../../hooks/Api';
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  addThemeFail,
  addThemeSuccess,
  editThemeSuccess,
  getAllThemes,
  toggleIsThemeActive
} from './themesSlice';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

function* workerGetAllThemes(action) {
  let page = '';
  if (action.payload.page) {
    page = `_page=${action.payload.page}&`;
  }

  let title = '';
  if (action.payload.search) {
    title = `title=${action.payload.search}&`;
  }

  let creator = '';
  if (action.payload.isCreatedByMe && action.payload.creator) {
    creator = `createdby.email=${action.payload.creator}`;
  }

  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/themes?${page}${title}${creator}`
    }));

    yield put(getAllThemes(res.data));
  } catch {}
}

function* workerAddTheme(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/themes`,
      method: 'post',
      data: action.payload
    }));

    yield put(addThemeSuccess(res.data));
  } catch (e) {
    yield put(addThemeFail(e.response.data['hydra:description']));
  }
}

function* editTheme(theme) {
  return yield call(() => api({
    url: `${baseUrl}/api/themes/${theme.id}`,
    method: 'put',
    data: theme
  }));
}

function* workerEditTheme(action) {
  try {
    const res = yield editTheme(action.payload);

    yield put(editThemeSuccess(res.data));
  } catch (e) {
    yield put(addThemeFail(e.response.data['hydra:description']));
  }
}

function* workerToggleIsThemeActive(action) {
  try {
    const res = yield editTheme(action.payload);

    yield put(toggleIsThemeActive(res.data));
  } catch (e) {
    yield put(addThemeFail(e.response.data['hydra:description']));
  }
}

function* workerGetActiveThemes() {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/themes?isActive=true`
    }));

    yield put(getAllThemes(res.data));
  } catch (e) {
    yield put(addThemeFail(e.response.data['hydra:description']));
  }
}

export function* watcherThemes() {
  yield takeEvery('FETCH_ALL_THEMES', workerGetAllThemes);
  yield takeEvery('ADD_THEME', workerAddTheme);
  yield takeEvery('EDIT_THEME', workerEditTheme);
  yield takeEvery('TOGGLE_IS_ACTIVE', workerToggleIsThemeActive);
  yield takeEvery('FETCH_ACTIVE_THEMES', workerGetActiveThemes);
}