export const commentSagaActions = {
  addComments,
  editComment,
  deleteComment
};

function addComments(payload) {
  return {
    type: 'ADD_COMMENTS',
    payload
  };
}

function editComment(payload) {
  return {
    type: 'EDIT_COMMENT',
    payload
  };
}

function deleteComment(payload) {
  return {
    type: 'DELETE_COMMENT',
    payload
  };
}