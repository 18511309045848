import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {statusSagaActions} from '../../redux/status/statusSagaActions';
import InputField from '../ui/inputField';
import Button from '../ui/button';

function StatusForm({status, index, closeModal, statusList}) {
  const [name, setName] = useState(status ? status.name : '');
  const [nameError, setNameError] = useState('');
  const [level ,setLevel] = useState(status ? status.level : -1)
  const [color, setColor] = useState(status ? status.color : '#001542');
  const dispatch = useDispatch();

  function submitForm() {
    if (name.length === 0) {
      setNameError('Ce champ est obligatoire!');
      return;
    }
    if (!status) {
      dispatch(statusSagaActions.addStatus({name, color, level: getLevel(level)}));
    } else {
      status = {...status, name, color, level: getLevel(level)};
      dispatch(statusSagaActions.editStatus({status, index}));
    }

    closeModal();
  }

  function getLevel(n) {
    if (parseInt(n) === -1) {
      if (statusList.length === 0) {
        return 1;
      }
      return parseInt(statusList[statusList.length - 1].level + 1);
    }

    return parseInt(n);
  }

  return (
    <>
      <div className="flex justify-between">
        <p className={'text-primary font-medium mb-5'}> {status ? 'Modifier ' : 'Nouveau '} statut</p>
        <span className={'cursor-pointer'} onClick={closeModal}>x</span>
      </div>
      <div className={'w-72 flex flex-col items-end gap-3'}>
        <InputField type={'text'} label={'Statut'} placeholder={'Statut'} value={name}
                    onChange={(e) => setName(e.currentTarget.value)} error={nameError} />
        <select onChange={(e) => {
          setLevel(e.target.value);
        }} defaultValue={status? status.level : -1}>
          {statusList?.map((st, index) =>
            (
              <option key={index} value={st.level}>{index + 1 }</option>
            )
          )}
          { !status ?
            <option value={-1}>{statusList.length + 1}</option>
            : null
          }
        </select>
        <div className={'flex flex-col items-start gap-2 w-full'}>
          <label>Couleur</label>
          <input className={'p-0'} type={'color'} value={color} onChange={(e) => setColor(e.currentTarget.value)} />
        </div>
        <div className="w-2/5">
          <Button className={'bg-primary text-white'} onClick={submitForm} value={status ? 'Modifier' : 'Ajouter'} />
        </div>
      </div>
    </>
  );
}

export default StatusForm;