import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {categorySagaActions} from '../../redux/category/categorySagaActions';
import Table from '../../components/ui/table';
import Modal from 'react-modal';
import CategoryForm from '../../components/forms/categoryForm';
import Pagination from '../../components/ui/pagination';
import AddButton from '../../components/ui/addButton';
import {modalStyle} from '../../utils/modalStyle';

function Categories() {
  const [modalIsOpen, setIsOpen] = useState(false);
  let [category, setCategory] = useState(null);
  let [index, setIndex] = useState(null);
  const dispatch = useDispatch();
  Modal.setAppElement('#root');

  const {
    categoriesList,
    totalItems
  } = useSelector(
    (state) => state.category
  );

  useEffect(() => {
    dispatch(categorySagaActions.getAllCategories(1));
  }, [dispatch]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setCategory(null);
  }

  function onPageChange(currentPage) {
    dispatch(categorySagaActions.getAllCategories(currentPage.selected + 1));
  }

  function onEdit(val, i) {
    setIndex(i);
    setCategory(val);
    setIsOpen(true);
  }

  function onCheck(val, index) {
    const state = val.isActive ? 'désactiver' : 'activer';
    if (window.confirm(`Voulez vous vraiment ${state} cette categorie?`)) {
      category = {...val, isActive: !val.isActive};
      dispatch(categorySagaActions.deactivateCategory({category, index}));
    }
  }

  return (
    <>
      <div className={'flex flex-col gap-6 items-center rounded'}>
        <div className={'w-full flex justify-between'}>
          <h1>Liste des catégories :</h1>
          <AddButton onClick={openModal} />
        </div>
        <Table data={categoriesList} headers={['Catégorie', 'Créée le', 'Modifiée le']}
               items={['name', 'createdAt', 'updatedAt']} onEdit={onEdit} onCheck={onCheck} />
        <Pagination totalItems={totalItems} onPageChange={onPageChange} />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        <CategoryForm category={category} closeModal={closeModal} index={index} />
      </Modal>
    </>
  );
}

export default Categories;
