import React from 'react';
import PenIcon from '../icons/penIcon';

function EditButton({onClick, sm}) {
  return (
    <button className={'p-1 border border-[#FFB30D] text-[#FFB30D] w-fit rounded-md hover:text-white hover:bg-[#FFB30D] flex items-center justify-center ' + (sm ? 'w-[35%] md:w-full' : '')} onClick={onClick}>
      <PenIcon />
    </button>
  );
}

export default EditButton;