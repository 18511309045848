import React, {useEffect, useState} from 'react';
import Button from '../ui/button';
import {useDispatch, useSelector} from 'react-redux';
import {tasksSagaActions} from '../../redux/task/tasksSagaActions';
import {modalStyle} from '../../utils/modalStyle';
import Modal from 'react-modal';
import ExclamationTriangle from '../icons/exclamationTriangle';
import {Link} from 'react-router-dom';
import {wordPressSagaActions} from '../../redux/wrodPress/wordPressSagaActions';
import Select from 'react-select';
import AlertIcon from '../icons/alertIcon';
import CheckIcon from '../icons/checkIcon';
import TaskDetail from './taskDetail';

function PublishTasks({task}) {
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [wantedFiles, setWantedFiles] = useState([]);
  const [listOfWP, setListOfWP] = useState([]);
  const [wordPress, setWordPress] = useState(null);
  const {wordPressList} = useSelector(state => state.wordPress);
  const [error, setError] = useState('');
  const {publicationUrl} = useSelector((state) => state.task);
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    dispatch(wordPressSagaActions.getAllWordPresses());
  }, [dispatch]);

  useEffect(() => {
    setError('');
  }, [wordPress]);

  useEffect(() => {
    if (wordPressList) {
      setListOfWP(wordPressList.map(wp => ({value: wp.link, label: wp.name})));
    }
  }, [wordPressList]);

  useEffect(() => {
    if (publicationUrl) {
      closeModal();
      setIsPublished(true);
    }
  }, [publicationUrl]);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function closePublicationModal() {
    setIsPublished(false);
  }

  function handlePublishTask() {
    if (!wordPress) {
      setError('Vous devez choisir un WordPress!');

      return;
    }

    const payload = {
      task_id: task.id,
      wanted_files: wantedFiles,
      wordPress_link: wordPress.value
    };

    dispatch(tasksSagaActions.publishTask(payload));
  }

  function handleCheckBoxChange(file) {
    if (wantedFiles.includes(file.name)) {
      wantedFiles.splice(wantedFiles.indexOf(file.name), 1);
    } else {
      setWantedFiles(currentList => {
        return [
          ...currentList,
          file.name
        ];
      });
    }
  }

  return (
    <>
      <div className={'w-1/2'}>
        <Button value={'Publier sur WordPress'} className={'bg-primary text-white'} onClick={openModal} />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        <div className={'w-[70vw] overflow-y-auto max-h-[80vh]'}>
          <div className={'w-full flex justify-between items-center mb-4'}>
            <h2>Publier sur WordPress</h2>
            <span className={'cursor-pointer'} onClick={closeModal}>X</span>
          </div>
          {task.publishUrl &&
            <div className={'w-full flex justify-center items-center gap-4 bg-red-500 bg-opacity-40 p-2 rounded text-red-600'}>
              <ExclamationTriangle />
              <span>
                Vous avez déjà publié <Link className={'underline hover:font-medium cursor-pointer'} to={task.publishUrl} target="_blank">cette tâche</Link>, voulez vous la publier encore une fois?
              </span>
            </div>
          }
          <div className={'flex flex-col gap-2'}>
            <div className={'flex w-full items-center gap-4'}>
              <h3>Titre :</h3>
              <p>{task.title}</p>
            </div>
            <div className={'flex flex-col w-full items-start gap-2'}>
              <h3 className={'w-full'}>Article :</h3>
              <p className={'w-full px-2'} dangerouslySetInnerHTML={{__html: task.article}} />
            </div>
            {task.files?.length > 0 &&
              <div className={'flex flex-col gap-2'}>
                <div className={'flex gap-2 items-center'}>
                  <h3>Fichiers à publier :</h3>
                  <span className={'font-light text-gray-500 text-sm'}>(Vous êtes autorisé(e) à publier uniquement des images.)</span>
                </div>
                {task.files?.map((file, index) => (
                  <div className={'flex gap-2 w-full px-2'} key={index}>
                    <input
                      className={'w-6 h-6'}
                      type={'checkbox'}
                      disabled={!file.mimeType?.toUpperCase().startsWith('IMAGE')}
                      onChange={() => handleCheckBoxChange(file)}
                    />
                    <p>{file.originalName}</p>
                  </div>
                ))}
              </div>
            }
            <div className={'flex flex-col gap-2'}>
              <label>
                WordPress:
              </label>
              <Select
                placeholder={'Choisir un WordPress.'}
                isMulti={false}
                options={listOfWP}
                onChange={setWordPress}
                isClearable
              />
              <div className={'flex flex-row text-[#F15D5E] text-sm gap-2 ' + (error ? 'show' : 'hidden')}>
                <AlertIcon />
                <span>{error}</span>
              </div>
            </div>
            <div className={'w-full flex justify-end gap-2'}>
              <div className={'w-1/4'}>
                <Button className={'border-primary'} value={'Annuler'} onClick={closeModal} />
              </div>
              <div className={'w-1/4'}>
                <Button className={'bg-primary text-white'} value={'Publier'} onClick={handlePublishTask} />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isPublished}
        onRequestClose={closePublicationModal}
        style={modalStyle}
      >
        <div className={'flex items-center justify-center flex-col gap-4'}>
          <div className={'text-green-600 rounded-full border border-green-600 p-2'}>
            <CheckIcon size={'w-8 h-8'}/>
          </div>
            <span className={'w-full text-center bg-opacity-40 p-2 rounded text-green-600'}>
                Votre tâche a été publiée avec succès. Vous pouvez la consulter à travers ce <Link className={'underline hover:font-medium cursor-pointer'} to={publicationUrl} target="_blank">lien</Link>.
            </span>
        </div>
      </Modal>
    </>
  );
}

export default PublishTasks;
