import React, {useState} from 'react';
import TextAreaField from '../ui/textAreaField';
import Button from '../ui/button';
import {commentSagaActions} from '../../redux/comment/commentSagaActions';
import {useDispatch} from 'react-redux';

function CommentForm({item, closeModal, title, subTitle, comment}) {
  const [myComment, setComment] = useState(comment? comment.body : '');
  const [commentError, setCommentError] = useState('');
  const [list, setList] = useState([]);
  const dispatch = useDispatch();

  function isFormValid() {
    if (myComment === '') {
      setCommentError('Ce champ est obligatoire!');

      return false;
    }

    return true;
  }

  function addCommentToList() {
    const commentaire = {body: myComment, task: item['@id']};
    setList([...list, commentaire]);
    setComment('');
  }

  function handleAddMore() {
    if (isFormValid()) {
      addCommentToList();
      setComment('');
      return true;
    }

    return false;
  }

  function handleSubmit() {
    if (comment && isFormValid()) {
      const data = {
        ...comment,
        body: myComment
      };

      dispatch(commentSagaActions.editComment(data));
      setComment('');
      closeModal();
      return;
    }

    if (myComment !== '') {
      dispatch(commentSagaActions.addComments([{body: myComment, task: item['@id']}]));
    } else if (list.length === 0 && !isFormValid()) {
      return;
    }

    if (list.length > 0) {
      dispatch(commentSagaActions.addComments(list));
    }

  }

  function handleReset() {
    closeModal();
    setComment('');
    setList([]);
  }

  return (
    <div className={'w-[45vw] flex flex-col'}>
      <div className={'w-full flex flex-row justify-between mb-4'}>
        <h2>{title}</h2>
        <span className={'cursor-pointer'} onClick={closeModal}>X</span>
      </div>
      <p>{subTitle}</p>
      <div className={'flex flex-col gap-2'}>
        <TextAreaField
          label={'Commentaire(s) ' + (comment ? '' : list.length)}
          value={myComment}
          error={commentError}
          onChange={(e) => {
            setComment(e.target.value);
            setCommentError('');
          }}
        />
        <div className={'w-full flex flex-row justify-end gap-2'}>
          <div className={'w-24'}>
            <Button className={'border-primary'} value={'Annuler'} onClick={handleReset}/>
          </div>
          {!comment &&
          <div className={'w-34'}>
            <Button className={'bg-primary text-white'} value={'Ajouter d\'autre commentaires'} onClick={handleAddMore}/>
          </div>
          }
          <div className={'w-24'}>
            <Button className={'bg-primary text-white'} value={comment ? 'Modifier' : 'Confirmer'} onClick={handleSubmit}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommentForm;