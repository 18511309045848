import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Modal from 'react-modal';
import {destinationSagaActions} from '../../redux/destination/destinationSagaActions';
import AddButton from '../../components/ui/addButton';
import Table from '../../components/ui/table';
import Pagination from '../../components/ui/pagination';
import {modalStyle} from '../../utils/modalStyle';
import DestinationForm from '../../components/forms/destinationForm';

function Destinations(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  let [destination, setDestination] = useState(null);
  let [index, setIndex] = useState(null);
  const dispatch = useDispatch();
  Modal.setAppElement('#root');

  const {
    destinationsList,
    totalItems
  } = useSelector(
    (state) => state.destination
  );

  useEffect(() => {
    dispatch(destinationSagaActions.getAllDestinations(1));
  }, [dispatch]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setDestination(null);
  }

  function onPageChange(currentPage) {
    dispatch(destinationSagaActions.getAllDestinations(currentPage.selected + 1));
  }

  function onEdit(val, i) {
    setIndex(i);
    setDestination(val);
    setIsOpen(true);
  }

  function onCheck(val, index) {
    const state = val.isActive ? 'désactiver' : 'activer';
    if (window.confirm(`Voulez vous vraiment ${state} cette destination?`)) {
      destination = {...val, isActive: !val.isActive};
      dispatch(destinationSagaActions.deactivateDestination({destination, index}));
    }
  }

  return (
    <>
      <div className={'flex flex-col gap-6 items-center rounded'}>
        <div className={'w-full flex justify-between'}>
          <h1>Liste des destinations :</h1>
          <AddButton onClick={openModal} />
        </div>
        <Table data={destinationsList} headers={['Destination', 'Type', 'Créée le', 'Modifiée le']}
               items={['name', 'type', 'createdAt', 'updatedAt']} onEdit={onEdit} onCheck={onCheck} />
        <Pagination totalItems={totalItems} onPageChange={onPageChange} />

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyle}
        >
          <DestinationForm destination={destination} closeModal={closeModal} index={index} />
        </Modal>
      </div>
    </>
  );
}

export default Destinations;