import React from 'react';
import PlusIcon from '../icons/plusIcon';

function AddButton({ onClick }) {
  return (
    <button
      className={'p-1 border border-primary text-primary w-fit rounded-md hover:text-white hover:bg-primary'}
      onClick={onClick}>
      <PlusIcon />
    </button>
  );
}

export default AddButton;