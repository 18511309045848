import React from 'react';
import {useDispatch} from 'react-redux';
import {notificationSagaActions} from '../../redux/notification/notificationSagaActions';
import {formatDistance} from 'date-fns';
import {fr} from 'date-fns/locale';

function NotificationCard({notification}) {
  const path = `${process.env.REACT_APP_BACK_END_URL}/media`;
  const dispatch = useDispatch();

  function handleSeeNotification() {
    !notification.isSeen && dispatch(notificationSagaActions.seeNotification(notification));
    setTimeout(() => {
      window.location.replace(notification.link);
    }, 500);
  }

  return (
    <div
      className={'flex flex-row items-center px-2 py-2 cursor-pointer gap-2 hover:bg-[#c6c5e3] border border-b-gray-400 ' + (notification.isSeen? 'bg-white' : 'bg-[#E0DFFF] font-semibold')}
      onClick={handleSeeNotification}
    >
      <img
        className={'h-10 w-10 rounded-full border border-primary bg-white'}
        src={notification.sender?.profilePhoto ?
          `${path}/${notification.sender?.profilePhoto?.name}`
          : 'https://www.cosavostra.com/wp-content/uploads/2020/04/logo-cosavostra-hashtag.svg'
        }
        alt={''} />
      <div className={'flex flex-col max-h-[8rem] w-5/6'}>
        <p className={'font-bold'}>
          {notification.sender?
            <>
              {notification.sender.firstName} {notification.sender.lastName}
            </> :
            <>L'équipe CosaContent</>
          }
        </p>
        <p className={' overflow-truncate'}>{notification.body}</p>
      </div>
      <span className={'font-light text-sm text-center w-1/6'}>
        {formatDistance(new Date(notification.createdAt), new Date(), {locale: fr})}
      </span>
    </div>
  );
}

export default NotificationCard;