export const notificationSagaActions = {
  getNotifications,
  getUnSeenNotifications,
  seeNotification,
  markNotificationsAsRead
};

function getNotifications(payload) {
  return {
    type: 'GET_ALL_NOTIFICATIONS',
    payload
  };
}

function getUnSeenNotifications(payload) {
  return {
    type: 'GET_UNSEEN_NOTIFICATIONS',
    payload
  };
}

function seeNotification(payload) {
  return {
    type: 'SEE_NOTIFICATION',
    payload
  };
}

function markNotificationsAsRead() {
  return {
    type: 'MARK_NOTIFICATIONS_AS_READ'
  };
}