import React from 'react';
import OpenAiIcon from '../icons/openAiIcon';

function OpenAiButton({onClick, isVisible}) {
  return (
    <div className={'open-ai-button fixed right-6 bottom-6 cursor-pointer rounded-full bg-[#00A67E] w-12 h-12 flex items-center justify-center border border-primary hover:animate-none ' + (isVisible? 'hidden' :'block')} onClick={onClick}>
      <OpenAiIcon />
    </div>
  );
}

export default OpenAiButton;