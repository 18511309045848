import React from 'react';
import InfoIcon from '../icons/infoIcon';

function ItemNotFound(props) {
  return (
    <div className={'flex justify-center bg-blue-100 rounded-lg p-5  text-blue-700 w-full gap-4'}>
      <InfoIcon/>
      <p>
        Aucun objet n'a été trouvé!
      </p>
    </div>
  );
}

export default ItemNotFound;