import React, {useEffect, useState} from 'react';
import AddButton from '../../components/ui/addButton';
import ThemeCard from './themeCard';
import Pagination from '../../components/ui/pagination';
import {useDispatch, useSelector} from 'react-redux';
import {themesSagaActions} from '../../redux/theme/themesSagaActions';
import ItemNotFound from '../../components/ui/itemNotFound';
import {modalStyle} from '../../utils/modalStyle';
import Modal from 'react-modal';
import ThemeForm from '../../components/forms/themeForm';
import FilterButton from '../../components/ui/filterButton';
import InputField from '../../components/ui/inputField';
import getRole from '../../hooks/getRole';

function Themes() {
  Modal.setAppElement('#root');

  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [isCreatedByMe, setIsCreatedByMe] = useState(false);
  const {user_email, user_roles} = useSelector((state) => state.auth);
  const {
    theme,
    themesList,
    totalItems
  } = useSelector(
    (state) => state.theme
  );

  useEffect(() => {
    dispatch(themesSagaActions.getAllActiveThemes({page : 1, search, isCreatedByMe, creator: user_email}));
  }, [dispatch, theme, search, isCreatedByMe, user_email]);

  useEffect(() => {
    if (theme) {
      setIsOpen(false);
    }
  }, [theme]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function toggleShowFilter() {
    setShowFilter(!showFilter);
  }

  return (
    <>
      <div className={'flex flex-col gap-8 items-center'}>
        <div className={'w-full flex justify-between items-center'}>
          <h1>Thématiques</h1>
          <div className={'relative flex gap-2'}>
            <FilterButton onClick={toggleShowFilter} showFilter={showFilter} />
            <div className={'absolute top-full right-10 w-52 border border-gray-500 py-4 px-2 rounded bg-white z-20 ' + (showFilter ? 'block' : 'hidden')}>
              <div className="w-full text-center border-b border-b-primary">
                Filtres
              </div>
              <div className={'pt-6 space-y-2'}>
                <InputField className={'text-sm'} type={'text'} label={'Titre'} placeholder={'Titre'} value={search} onChange={(e) => setSearch(e.target.value)} />
                <div className={'flex flex-col gap-3 mt-2'}>
                  <label>Créateur</label>
                  <div className={'flex items-center'}>
                    <input className={'w-8 h-4'} type={'checkbox'} checked={isCreatedByMe} onChange={() => setIsCreatedByMe(!isCreatedByMe)} />
                    <label>Créées par moi</label>
                  </div>
                </div>
              </div>
            </div>
            { (getRole(user_roles).key === 'ROLE_ADMIN'
              || getRole(user_roles).key === 'ROLE_MANAGER')
              &&
              <AddButton onClick={openModal} />
            }
          </div>
        </div>
        {totalItems > 0 ?
          <div className={'w-full flex flex-col md:grid md:grid-cols-5 gap-6 place-content-center'}>
          {themesList?.map((item, idx) => (
            <ThemeCard myTheme={item} key={idx} />
          ))}
        </div>
        :
          <ItemNotFound />
        }

        <Pagination totalItems={totalItems} />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        <ThemeForm closeModal={closeModal} />
      </Modal>
    </>
  );
}

export default Themes;