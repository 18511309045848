import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const initialState = {
  theme: null,
  themesList: [],
  totalItems: 0
};

const themeSlice = createSlice({
  name: 'themes',
  initialState,
  reducers: {
    getAllThemes(state, action) {
      state.totalItems = action.payload['hydra:totalItems'];
      state.themesList = action.payload['hydra:member'];
    },
    addThemeSuccess(state, action) {
      state.theme = action.payload;
      toast.success('Thématique ajoutée avec succès.', {theme: 'dark'});
    },
    addThemeFail(state, action) {
      let error = 'Une erreur est survenue veuillez réessayer ultérieurement';
      if (action.payload.includes('Duplicate entry')) {
        error = 'Les champs \'Titre\' et \'Hashtag\' doivent être unique.';
      }

      toast.error(error, {theme: 'dark'});
    },
    editThemeSuccess(state, action) {
      state.theme = action.payload;
      toast.success('Thématique modifiée avec succès.', {theme: 'dark'});
    },
    toggleIsThemeActive(state, action) {
      state.theme = action.payload;
      const res = action.payload.isActive ? 'activée' : 'désactivée';

      toast.success(`Thématique ${res} avec succès.`, {theme: 'dark'});
    }
  }
});

export const {
  getAllThemes,
  addThemeSuccess,
  addThemeFail,
  editThemeSuccess,
  toggleIsThemeActive
} = themeSlice.actions;

export default themeSlice.reducer;