import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.scss';
import App from './App';
import {Provider} from 'react-redux';
import store from './redux/store';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import axios from 'axios';

const root = ReactDOM.createRoot(document.getElementById('root'));
axios.interceptors.request.use(req => {
    const token = localStorage.getItem('token');

    if (!req.headers['Authorization'] && token){
      req.headers['Authorization'] = 'Bearer ' + token;
    }
    return req;
  },
  error => Promise.reject(error));

root.render(
    <Provider store={store}>
      <ToastContainer/>
      <App/>
    </Provider>
);

