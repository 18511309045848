import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const initialState = {
  totalItems: 0,
  statusList: [],
  initialStatus: null,
  nextStatus: null,
  isFinalStatus: true,
  error: null
};

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    getAllStatus(state, action) {
      state.totalItems = action.payload['hydra:totalItems'];
      state.statusList = action.payload['hydra:member'];
    },
    getActiveStatus(state, action) {
      state.totalItems = action.payload['hydra:totalItems'];
      state.statusList = action.payload['hydra:member'];
    },
    addStatus(state, action) {
      toast.success('Statut ajouté avec succès!', {theme: 'dark'});
      state.statusList.push(action.payload.data);
    },
    httpFailure() {
      toast.error('Une erreur est survenue veuillez réessayer ultérieurement', {theme: 'dark'});
    },
    editStatus() {
      toast.success('Statut modifié avec succès!', {theme: 'dark'});
    },
    deactivateStatus(state, action) {
      const etat = action.payload.data.isActive ? 'activé' : 'désactivé';
      toast.success(`Statut ${etat} avec succès.`, {theme: 'dark'});
      state.statusList[action.payload.index] = action.payload.data;
    },
    setInitialStatus(state, action) {
      state.initialStatus = action.payload;
    },
    setNextStatus(state, action) {
      state.nextStatus = action.payload;
    },
    isFinalStatus(state, action) {
      state.isFinalStatus = action.payload;
    }
  }
});

export const {
  getAllStatus,
  getActiveStatus,
  addStatus,
  httpFailure,
  editStatus,
  deactivateStatus,
  setInitialStatus,
  setNextStatus,
  isFinalStatus
} = statusSlice.actions;

export default statusSlice.reducer;