import React, {useEffect, useState} from 'react';
import AddButton from '../../components/ui/addButton';
import {useDispatch, useSelector} from 'react-redux';
import Modal from 'react-modal';
import {statusSagaActions} from '../../redux/status/statusSagaActions';
import Table from '../../components/ui/table';
import Pagination from '../../components/ui/pagination';
import {modalStyle} from '../../utils/modalStyle';
import StatusForm from '../../components/forms/statusForm';

function Status(props) {

  const [modalIsOpen, setIsOpen] = useState(false);
  let [status, setStatus] = useState(null);
  let [index, setIndex] = useState(null);
  const dispatch = useDispatch();
  Modal.setAppElement('#root');

  const {
    statusList,
    totalItems
  } = useSelector(
    (state) => state.status
  );

  useEffect(() => {
    dispatch(statusSagaActions.getAllStatus(1));
  }, [dispatch]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setStatus(null);
  }

  function onPageChange(currentPage) {
    dispatch(statusSagaActions.getAllStatus(currentPage.selected + 1));
  }

  function onEdit(val, i) {
    setIndex(i);
    setStatus(val);
    setIsOpen(true);
  }

  function onCheck(val, index) {
    const state = val.isActive ? 'désactiver' : 'activer';
    if (window.confirm(`Voulez vous vraiment ${state} ce statut?`)) {
      status = {...val, isActive: !val.isActive};
      dispatch(statusSagaActions.deactivateStatus({status, index}));
    }
  }

  return (
    <>
      <div className={'flex flex-col gap-6 items-center rounded'}>
        <div className={'w-full flex justify-between'}>
          <h1>Liste des statuts :</h1>
          <AddButton onClick={openModal} />
        </div>
        <Table data={statusList} headers={['#','Statut', 'Couleur', 'Créé le', 'Modifié le']}
               items={['name', 'color', 'createdAt', 'updatedAt']} onEdit={onEdit} onCheck={onCheck} />
        <Pagination totalItems={totalItems} onPageChange={onPageChange} />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        <StatusForm status={status} closeModal={closeModal} index={index} statusList={statusList}/>
      </Modal>
    </>
  );
}

export default Status;