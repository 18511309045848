export const wordPressSagaActions = {
  getAllWordPresses,
  addWordPress,
  editWordPress,
  deleteWordPress
};

function getAllWordPresses(payload) {
  return {
    type: 'FETCH_ALL_WORD_PRESS',
    payload
  };
}

function addWordPress(payload) {
  return {
    type: 'ADD_WORD_PRESS',
    payload
  };
}

function editWordPress(payload) {
  return {
    type: 'EDIT_WORD_PRESS',
    payload
  };
}

function deleteWordPress(payload) {
  return {
    type: 'DELETE_WORD_PRESS',
    payload
  };
}
