import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  unSeenNotifications : 0,
  totalItems : 0,
  notifications: [],
  notification: {},
  updatedAt: null
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getAllNotifications(state, action) {
      state.notifications = action.payload['hydra:member'];
      state.totalItems = action.payload['hydra:totalItems'];
    },
    setUnSeenNotification(state, action) {
      state.unSeenNotifications = action.payload['hydra:totalItems'];
    },
    editNotificationSuccess(state, action) {
      console.log(action);
      state.notification = action.payload;
    },
    markAllNotificationsAsRead(state) {
      state.updatedAt = new Date();
    }
  }
});

export const {
  getAllNotifications,
  setUnSeenNotification,
  editNotificationSuccess,
  markAllNotificationsAsRead
} = notificationSlice.actions;

export default notificationSlice.reducer;