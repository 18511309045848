import React from 'react';
import EditButton from './editButton';
import {format} from 'date-fns';
import Pill from './pill';
import ItemNotFound from './itemNotFound';

function Table({data, headers, items, onEdit, onCheck}) {
  function fillTable(data, item) {
    if (item === 'createdAt' || item === 'updatedAt') {
      return format(new Date(data[item]), 'dd/MM/yy');
    }
    else if (item === 'type') {
      return data['destinationTypeData'].text;
    }
    else if (item === 'color') {
      return (
        <div className={'flex justify-center'}>
          <Pill color={data[item]} text={data['name']}/>
        </div>
      );
    }

    return data[item];
  }

  return (
    <>
      {data.length === 0 ?
        <ItemNotFound />
        :
        <table
          className={'min-w-full table-auto text-center rounded-b-lg border border-primary text-primary text-sm md:text-base'}>
          <thead className={'bg-primary text-white'}>
          <tr>
            {headers?.map((header) => (
              <th className={'py-2'} key={header}>{header}</th>
            ))}
            <th>Active</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {data?.map((data, index) => (
            <tr key={data.id}>
              {
                headers.includes('#') ?
                  <td key={index}>{index + 1}</td> : null
              }
              {items?.map((item) => (
                <td className={'py-1 text-center'} key={item}>
                  {fillTable(data, item)}
                </td>
              ))}

              <td className={'py-1'}>
                <div className={'flex justify-center items-center'}>
                  <input className={'w-6 h-6 accent-primary cursor-pointer'} type={'checkbox'}
                         checked={data.isActive} onChange={() => onCheck(data, index)}/>
                </div>
              </td>
              <td className={'py-1 space-x-2'}>
                <EditButton onClick={() => onEdit(data, index)}/>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      }
    </>
  );
}

export default Table;