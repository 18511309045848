import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const initialState = {
  totalItems: 0,
  contentList: [],
  success: false,
  content: null
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    getAllContent(state, action) {
      state.totalItems = action.payload['hydra:totalItems'];
      state.contentList = action.payload['hydra:member'];
    },
    findContentByField(state, action) {
      state.totalItems = action.payload['hydra:totalItems'];
      state.contentList = action.payload['hydra:member'];
    },
    addContentSuccess(state, action) {
      toast.success('Contenu ajouté avec succès!', {theme: 'dark'});
      state.content = action.payload;
      state.success = true;
    },
    getContentById(state, action) {
      state.content = action.payload;
    },
    resetContent(state) {
      state.success = false;
    },
    deleteContentSuccess(state) {
      state.success = true;
      toast.success('Contenu supprimé avec succès!', {theme: 'dark'});
    }
  }
});

export const {
  getAllContent,
  findContentByField,
  addContentSuccess,
  getContentById,
  resetContent,
  deleteContentSuccess
} = contentSlice.actions;

export default contentSlice.reducer;