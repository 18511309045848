import React from 'react';
import EyeIcon from '../icons/eyeIcon';

function PreviewButton({onClick, sm}) {
  return (
    <button
      className={'p-1 border border-primary text-primary w-fit rounded-md hover:text-white hover:bg-primary flex items-center justify-center ' + (sm ? 'w-[35%] md:w-full' : '')}
      onClick={onClick}>
      <EyeIcon size={'w-6 h-6'} />
    </button>
  );
}

export default PreviewButton;