import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const initialState = {
  task: {},
  filteredTasks: [],
  totalItems: 0,
  success: false,
  publicationUrl: null
};

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    editTask(state, action) {
      state.task = action.payload.data;
      toast.success('Tâche modifiée avec succès.', {theme: 'dark'});
    },
    addTaskSuccess(state, action) {
      state.task = action.payload.data;
      toast.success('Tâche ajoutée avec succès.', {theme: 'dark'});
    },
    getFilteredTasks(state, action) {
      state.filteredTasks = action.payload['hydra:member'];
    },
    deleteTask(state) {
      state.task = null;
      toast.success('Tâche supprimée avec succès.', {theme: 'dark'});
    },
    startTaskSuccess(state, action) {
      state.task = action.payload.data;
      toast.success('Tâche lancée avec succès.', {theme: 'dark'});
    },
    fillTaskSuccess(state, action) {
      state.task = action.payload;
      toast.success('Modification enregistrée avec succès.', {theme: 'dark'});
    },
    validateTask(state, action) {
      state.task = action.payload;
      toast.success('Tâche validée avec succès.', {theme: 'dark'});
    },
    publishTaskSuccess(state, action) {
      state.publicationUrl = action.payload.data.publication_url;
      toast.success('Tâche publiée avec succès.', {theme: 'dark'});
    }
  }
});

export const {
  deleteTask,
  editTask,
  addTaskSuccess,
  getFilteredTasks,
  startTaskSuccess,
  fillTaskSuccess,
  validateTask,
  publishTaskSuccess
} = taskSlice.actions;

export default taskSlice.reducer;