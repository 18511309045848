import React, {useState} from 'react';
import InputField from '../ui/inputField';
import Button from '../ui/button';
import {useDispatch} from 'react-redux';
import {themesSagaActions} from '../../redux/theme/themesSagaActions';

function ThemeForm({theme, closeModal}) {
  const [title, setTitle] = useState(theme ? theme.title : '');
  const [titleError, setTitleError] = useState('');
  const [hashtag, setHashtag] = useState(theme ? theme.hashtag : '');
  const [hashtagError, setHashtagError] = useState('');
  const [color, setColor] = useState(theme ? theme.color : '#001542');
  const error = 'Ce champ est obligatoire!';
  const regex = /^#\S+$/;
  const dispatch = useDispatch();

  function isFormValid() {
    if (title === '' || hashtag === '') {
      if (title === '') {
        setTitleError(error);
      }

      if (hashtag === '') {
        setHashtagError(error);
      }

      return false;
    }

    if (!regex.test(hashtag)) {
      setHashtagError('Hashtag doit être de la forme \'#mot\'"');
      return false;
    }

    return true;
  }

  function submitForm() {
    if (!isFormValid()) {
      return;
    }

    let payload = {title, color, hashtag};
    if (!theme) {
      return dispatch(themesSagaActions.addTheme(payload));
    }

    dispatch(themesSagaActions.editTheme({id: theme.id, ...payload}));
  }

  return (
    <>
      <div className="flex justify-between mb-5">
        <p className={'text-primary font-medium'}> {theme ? 'Modifier ' : 'Nouvelle '} thématique</p>
        <span className={'cursor-pointer'} onClick={closeModal}>x</span>
      </div>
      <div className={'w-72 flex flex-col items-end gap-3'}>
        <InputField
          type={'text'}
          label={'Titre'}
          placeholder={'Titre'}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            setTitleError('');
          }}
          error={titleError}
        />
        <InputField
          type={'text'}
          label={'Hashtag'}
          placeholder={'#Hashtag'}
          value={hashtag}
          onChange={(e) => {
            setHashtag(e.target.value);
            setHashtagError('');
          }}
          error={hashtagError}
        />
        <div className={'flex flex-col items-start gap-2 w-full'}>
          <label>Couleur</label>
          <input className={'p-0'} type={'color'} value={color} onChange={(e) => setColor(e.currentTarget.value)} />
        </div>
        <div className="w-2/5">
          <Button className={'bg-primary text-white'} value={theme ? 'Modifier' : 'Ajouter'} onClick={submitForm} />
        </div>
      </div>
    </>
  );
}

export default ThemeForm;