import React from 'react';
import tinycolor from 'tinycolor2';

function Pill({color, text}) {

  let textColor;
  const contrast = getContrastRatio(color, '#000000');

  if (contrast >= 7.5) {
    textColor = '#000000';
  } else {
    textColor = '#ffffff';
  }

  function getContrastRatio(color1, color2) {
    const c1 = tinycolor(color1);
    const c2 = tinycolor(color2);

    const l1 = c1.getLuminance();
    const l2 = c2.getLuminance();

    return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
  }

  return (
    <div className={'capitalize rounded-xl font-medium text-sm  w-fit px-1.5 py-0.5 m-0'}
         style={{backgroundColor: color, color: textColor}}>
      {text}
    </div>
  );
}

export default Pill;