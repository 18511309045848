import React, {useState} from 'react';
import {formatDistance} from 'date-fns';
import {fr} from 'date-fns/locale';
import ArrowDownIcon from '../icons/arrowDownIcon';
import {useDispatch, useSelector} from 'react-redux';
import getRole from '../../hooks/getRole';
import PenIcon from '../icons/penIcon';
import TrashIcon from '../icons/trashIcon';
import {commentSagaActions} from '../../redux/comment/commentSagaActions';
import {modalStyle} from '../../utils/modalStyle';
import CommentForm from '../forms/commentForm';
import Modal from 'react-modal';
import ArrowUpIcon from '../icons/arrowUpIcon';

function CommentsSections({comments}) {
  const path = `${process.env.REACT_APP_BACK_END_URL}/media`;
  const [isCollapsed, setIsCollapsed] = useState(-1);
  const [showParams, setShowParams] = useState(-1);
  const {user_roles, user_email} = useSelector((state) => state.auth);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState(null);
  const dispatch = useDispatch();

  function closeModal() {
    setIsOpen(false);
  }

  function toggleIsCollapsed(idx) {
    if (idx !== isCollapsed) {
      setIsCollapsed(idx);
    } else {
      setIsCollapsed(-1);
    }
  }

  function toggleShowParams(idx) {
    if (idx !== showParams) {
      setShowParams(idx);
    } else {
      setShowParams(-1);
    }
  }

  function treatComment(comment) {
    const data = {
      ...comment,
      isTreated : true
    };

    dispatch(commentSagaActions.editComment(data));
  }

  function handleEdit(comment) {
    setIsOpen(true);
    setComment(comment);
    setShowParams(-1);
  }

  function handleDelete(comment) {
    if (window.confirm('voulez vous vraiment supprimer ce commentaire de façon permanente?')) {
      dispatch(commentSagaActions.deleteComment(comment));
      setShowParams(-1);
    }
  }

  return (
    <>
      {comments.length > 0 ?
        <div className={'w-full pr-2'}>
          <div className={'w-full bg-[#F7DDDF] flex flex-col gap-2 items-start p-2 rounded'}>
            <div className={'flex flex-row justify-between w-full'}>
              <p className={'text-primary font-semibold text-base mb-2'}>Derniers commentaires</p>
            </div>
            {comments?.map((comment, index) => (
              <div key={index} className={'flex flex-col w-full bg-white rounded gap-1 py-2 px-4' + (comment.isTreated ? ' border border-green-500' : '')}>
                <div className={'flex flex-row w-full items-center justify-between'}>
                  <div className={'flex items-center gap-2'}>
                    <img
                      className={'w-8 h-8 rounded-full border border-primary'}
                      src={comment.createdBy?.profilePhoto ?
                        `${path}/${comment.createdBy?.profilePhoto?.name}`
                        : 'https://www.cosavostra.com/wp-content/uploads/2020/04/logo-cosavostra-hashtag.svg'
                      }
                      alt={''} />
                    <p className={'font-semibold text-lg text-primary'}>{comment.createdBy.firstName} {comment.createdBy.lastName}</p>
                  </div>
                  <div className={'flex items-center gap-2'}>
                    <p className={'font-light text-gray-500'}>
                      {formatDistance(new Date(comment.createdAt), new Date(), {addSuffix: true, locale: fr})}
                    </p>
                    { comment.isTreated &&
                      <span className={'cursor-pointer border border-gray-500 rounded-full p-1/2'} onClick={()=>toggleIsCollapsed(index)}>
                        { index === isCollapsed ? <ArrowUpIcon /> : <ArrowDownIcon />  }
                      </span>
                    }
                    {
                      (comment.createdBy.email === user_email || getRole(user_roles).key === 'ROLE_ADMIN') ?
                      <div className={'relative'}>
                        <span className={'cursor-pointer'} onClick={() => toggleShowParams(index)}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                          </svg>
                        </span>
                        {
                          showParams === index ?
                            <div className="absolute z-10 bg-white rounded border border-gray-500 w-32 flex flex-col gap-2 py-1">
                          <span className={'flex items-center gap-2 px-4 hover:text-orange-600 hover:bg-gray-100 cursor-pointer'} onClick={() => handleEdit(comment)}>
                            <PenIcon size={'w-3 h-3'} />
                            Modifier
                          </span>
                          <span className={'flex items-center gap-2 px-4 hover:text-red-600 hover:bg-gray-100 cursor-pointer'} onClick={() => handleDelete(comment)}>
                            <TrashIcon />
                            Supprimer
                          </span>
                        </div>
                          : null
                        }
                      </div>
                        : null
                    }
                  </div>
                </div>
                { !comment.isTreated || index === isCollapsed ?
                  <div className={'flex flex-row justify-between transform transition duration-300 ease-in-out'}>
                    <p className={'w-full'}>{comment.body}</p>
                    {!comment.isTreated &&
                    <span className={'hover:text-green-800 cursor-pointer'} onClick={() => treatComment(comment)} title={'Marquer comme traité'}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span>
                    }
                  </div>
                  : null
                }
              </div>
            ))}
          </div>
        </div>
        : null
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        <CommentForm closeModal={closeModal} comment={comment} title={'Modifier commentaire'}/>
      </Modal>
    </>
  );
}

export default CommentsSections;