import api from '../../hooks/Api';
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  createUserFailure,
  createUserSuccess,
  getMe,
  httpFailure,
  loginFailure,
  loginSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  sendResetEmailSuccess
} from './authSlice';
import FormData from 'form-data';
import {toast} from 'react-toastify';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

function* workerLoginUser(action) {
  try {
    const data = yield call(() => api({
      url: `${baseUrl}/authentication_token`,
      method: 'post',
      data: action.payload
    }));

    yield put(loginSuccess(data));
  } catch (error) {
    yield put(loginFailure(error.response.status));
  }
}

function* workerUploadPictureAndCreateUser(action) {
  try {
    if (action.payload.profilePhoto) {
      const picture = yield call(uploadFile, action.payload.profilePhoto);
      action.payload.profilePhoto = `/api/media_objects/${picture.id}`;
    }

    yield call(createUser, action.payload);
  } catch (error) {
    yield put(createUserFailure(error.response.status));
  }
}

export function* uploadFile(file) {
  const formData = new FormData();
  formData.append('file', file);

  if(file.task) {
    formData.append('task', file.task);
  }

  const response = yield call(() => api({
    url: `${baseUrl}/api/media_objects`,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }));

  if (false === response.ok) {
    throw new Error('Failed to upload picture');
  }

  return response.data;
}

function* createUser(data) {
  try {
    yield call(() => api({
      url: `${baseUrl}/api/users`,
      method: 'POST',
      data
    }));

    yield put(createUserSuccess(data));
  } catch (error) {
    yield put(createUserFailure(error.response.status));
  }
}

function* workerSendResetEmail(data) {
  yield put(sendResetEmailSuccess());

  try {
    yield call(() => api({
      url: `${baseUrl}/api/send_reset_email`,
      method: 'post',
      data: data.payload
    }));
  } catch (e) {
  }
}

function* workerResetPassword(data) {
  try {
    yield call(() => api({
      url: `${baseUrl}/api/reset_password`,
      method: 'post',
      data: data.payload

    }));

    yield put(resetPasswordSuccess(data));
  } catch (error) {
    yield put(resetPasswordFailure(error.response.status));
  }
}

function* workerGetMe() {
  try {
    const response = yield call(() => api({
      url: `${baseUrl}/api/me`,
      method: 'get'
    }));

    yield put(getMe(response));
  } catch {}
}

function* workerEditProfilePicture(action) {
  try {
    let profilePhoto = '';
    const picture = yield call(uploadFile, action.payload.profilePhoto);
    profilePhoto = `/api/media_objects/${picture.id}`;

    const res = yield call(() => api({
      url: `${baseUrl}/api/users/${action.payload.id}`,
      method: 'put',
      data: {
        profilePhoto
      }
    }));

    toast.success('Photo de profil modifiée avec succès!', {theme: 'dark'})
    yield put(getMe(res));
  } catch {
    yield put(httpFailure);
  }
}

function* workerEditProfile(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/users/${action.payload.id}`,
      method: 'put',
      data: action.payload
    }));

    toast.success('Profil modifié avec succès!', {theme: 'dark'})
    yield put(getMe(res));
  } catch (e) {
    yield put(httpFailure);
  }

}

export function* watcherLogin() {
  yield takeEvery('LOGIN', workerLoginUser);
  yield takeEvery('UPLOAD_AND_SIGNUP', workerUploadPictureAndCreateUser);
  yield takeEvery('SEND_RESET_EMAIL', workerSendResetEmail);
  yield takeEvery('RESET_PASSWORD', workerResetPassword);
  yield takeEvery('GET_ME', workerGetMe);
  yield takeEvery('EDIT_PROFIL_PICTURE', workerEditProfilePicture);
  yield takeEvery('EDIT_PROFILE', workerEditProfile);
}
