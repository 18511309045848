import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {notificationSagaActions} from '../../redux/notification/notificationSagaActions';
import NotificationCard from '../../components/Notifications/notificationCard';
import Pagination from '../../components/ui/pagination';
import ItemNotFound from '../../components/ui/itemNotFound';

function NotificationsPage() {
  const dispatch = useDispatch();

  const {
    notifications,
    unSeenNotifications,
    totalItems
  } = useSelector((state) => state.notification);

  function onPageChange(currentPage) {
    dispatch(notificationSagaActions.getNotifications(currentPage.selected + 1));
  }

  useEffect(() => {
    dispatch(notificationSagaActions.getNotifications());
    dispatch(notificationSagaActions.getUnSeenNotifications());
  }, [dispatch]);

  if (totalItems === 0) {
    return (
      <div>
        <h1 className={'mb-4'}>Mes notifications</h1>
        <ItemNotFound />
      </div>
    )
  }

  return (
    <div className={'w-full'}>
      <h1 className={'mb-4'}>Mes notifications ({unSeenNotifications})</h1>
      <div className={'flex flex-col w-full'}>
        {notifications?.map((notification, index) => (
          <NotificationCard notification={notification} key={index} />
        ))}
      </div>
      <div className={'w-full my-2 flex justify-center items-center'}>
        <Pagination totalItems={totalItems} onPageChange={onPageChange} />
      </div>
    </div>
  );
}

export default NotificationsPage;