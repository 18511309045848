import React, {useState} from 'react';
import InputField from '../ui/inputField';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import AlertIcon from '../icons/alertIcon';
import countryNames from 'react-phone-number-input/locale/fr';
import 'react-phone-number-input/style.css';
import Button from '../ui/button';
import {useDispatch, useSelector} from 'react-redux';
import {authSagaActions} from '../../redux/auth/authSagaActions';
import {usersSagaActions} from '../../redux/user/usersSagaActions';
import getRole from '../../hooks/getRole';

function UserForm({user, index, closeModal}) {
  const [country, setCountry] = useState(user ? user.country : 'Tunisie');
  const [company, setCompany] = useState(user ? user.company : '');
  const [type, setType] = useState(user ? user.type : 'EMPLOYEE');
  const [role, setRole] = useState(user ? getRole(user.roles).key : 'ROLE_USER');
  const [firstName, setFirstName] = useState(user ? user.firstName : '');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState(user ? user.lastName : '');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState(user ? user.email : '');
  const [emailError, setEmailError] = useState('');
  const [phone, setPhone] = useState(user ? user.phone : '');
  const [phoneError, setPhoneError] = useState('');
  const emailRegex = /^\S+@\S+\.\S+$/;
  const {user_roles, user_id} = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  function setCountryValue(val) {
    setCountry(countryNames[val]);
  }

  function setPhoneNumber(val) {
    setPhone(val);
    setPhoneError(null);
  }

  function checkFormErrors() {
    let hasErrors = false;

    if (email === '') {
      setEmailError('Ce champ est obligatoire!');
      hasErrors = true;
    } else if (!emailRegex.test(email)) {
      setEmailError('Ce champ doit être un email!');
      hasErrors = true;
    }

    if (getRole(user_roles).text !== 'Admin') {
      if (!isValidPhoneNumber(phone)) {
        setPhoneError('Ce champ doit être un numéro de téléphone!');
        hasErrors = true;
      }
    }

    if (firstName === '') {
      setFirstNameError('Ce champ est obligatoire!');
      hasErrors = true;
    }
    if (lastName === '') {
      setLastNameError('Ce champ est obligatoire!');
      hasErrors = true;
    }

    return hasErrors;
  }

  function submitForm() {
    if (checkFormErrors())
      return;

    let data = {
      ...user,
      firstName,
      lastName,
      email,
      phone,
      country,
      type,
      company
    };

    if (user_roles?.includes('ROLE_ADMIN')) {
      data = {
        ...data,
        roles: [role]
      };
    }

    if (user) {
      if (index === undefined) {
        dispatch(authSagaActions.editProfile(data));
      } else {
        dispatch(usersSagaActions.editUser({data, index}));
      }
      closeModal();
    } else {
      data = {
        ...data,
        password: Math.random().toString(36).substring(0, 10)
      };

      dispatch(usersSagaActions.addUser(data));
    }
  }

  function setTitle() {
    if (!user)
      return 'Ajouter un utilisateur :';

    if (user.id === user_id)
      return 'Modifier votre profil';

    return 'Modifier utilisateur :';
  }

  return (
    <div className={'w-[80vw] md:w-[40vw]   flex flex-col  gap-2 items-end'}>
      <h1 className={'mb-5 w-full'}>
        {setTitle()}
      </h1>
      <div className="flex flex-row w-full gap-1">
        <InputField type={'text'} label={'Nom'} placeholder={'Nom'} error={lastNameError} value={lastName}
                    onChange={(e) => {
                      setLastName(e.currentTarget.value);
                      setLastNameError('');
                    }} />
        <InputField type={'text'} label={'Prénom'} placeholder={'Prénom'} error={firstNameError} value={firstName}
                    onChange={(e) => {
                      setFirstName(e.currentTarget.value);
                      setFirstNameError('');
                    }} />
      </div>
      <InputField readOnly={user} type={'email'} label={'Adresse e-mail'} placeholder={'Adresse e-mail'}
                  error={emailError} value={email} onChange={(e) => {
        setEmail(e.currentTarget.value);
        setEmailError('');
      }} />
      <div className={'flex flex-col items-start gap-2 w-full'}>
        <label>Numéro de téléphone</label>
        <PhoneInput
          className={'w-full'}
          international
          onCountryChange={setCountryValue}
          defaultCountry="TN"
          labels={countryNames}
          placeholder="Enter phone number"
          value={phone}
          onChange={setPhoneNumber} />
        <div className={'flex flex-row text-[#F15D5E] gap-2 text-sm ' + (phoneError ? 'show' : 'hidden')}>
          <AlertIcon />
          <span>{phoneError}</span>
        </div>
      </div>
      <div className="flex flex-row w-full gap-1">
        <div className={'flex flex-col items-start gap-2 w-full'}>
          <label>Profil</label>
          <select onChange={e => {
            setType(e.target.value);
          }} defaultValue={type}>
            <option value={'EMPLOYEE'}>Employé</option>
            <option value={'FREELANCE'}>Freelance</option>
          </select>
        </div>
        <InputField type={'text'} label={'Entreprise'} placeholder={'Entreprise'} value={company} onChange={(e) => {
          setCompany(e.currentTarget.value);
        }} />
      </div>
      {user_roles?.includes('ROLE_ADMIN') && user?.id !== user_id ?
        <div className={'flex flex-col items-start gap-2 w-full'}>
          <label>Rôle</label>
          <select onChange={e => {
            setRole(e.target.value);
          }} defaultValue={role}>
            <option value={'ROLE_USER'}>Utilisateur</option>
            <option value={'ROLE_HEAD_OF'}>Head Of</option>
            <option value={'ROLE_MANAGER'}>Manager</option>
            <option value={'ROLE_ADMIN'}>Administrateur</option>
          </select>
        </div>
        : null
      }
      <div className="w-2/5">
        <Button className={'bg-primary text-white'} onClick={submitForm} value={user ? 'Modifier' : 'Ajouter'} />
      </div>
    </div>
  );
}

export default UserForm;