export const destinationSagaActions = {
  getAllDestinations,
  addDestination,
  editDestination,
  deactivateDestination,
  getActiveDestinations
};

function getAllDestinations(payload) {
  return {
    type: 'FETCH_ALL_DESTINATIONS',
    payload
  };
}

function addDestination(payload) {
  return {
    type: 'ADD_DESTINATION',
    payload
  };
}

function editDestination(payload) {
  return {
    type: 'EDIT_DESTINATION',
    payload
  };
}

function deactivateDestination(payload) {
  return {
    type: 'DEACTIVATE_DESTINATION',
    payload
  };
}

function getActiveDestinations() {
  return {
    type: 'FETCH_ACTIVE_DESTINATIONS'
  };
}