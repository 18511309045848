import React, {useEffect, useState} from 'react';
import InputField from '../ui/inputField';
import SelectSearch from '../ui/selectSearch';
import TextAreaField from '../ui/textAreaField';
import AddButton from '../ui/addButton';
import TrashIcon from '../icons/trashIcon';
import Button from '../ui/button';
import {useDispatch, useSelector} from 'react-redux';
import {contentSagaActions} from '../../redux/content/contentSagaActions';
import {usersSagaActions} from '../../redux/user/usersSagaActions';
import {useNavigate} from 'react-router-dom';
import TaskForm from './taskForm';
import PenIcon from '../icons/penIcon';
import {themesSagaActions} from '../../redux/theme/themesSagaActions';
import {modalStyle} from '../../utils/modalStyle';
import ThemeForm from './themeForm';
import Modal from 'react-modal';

function ContentForm({formTitle , content, closeModal}) {
  Modal.setAppElement('#root');
  const [themeModalIsOpen, setThemeIsOpen] = useState(false);
  const {headOfList} = useSelector((state) => state.users);
  const [title, setTitle] = useState(content? content.title : '');
  const [titleError, setTitleError] = useState('');
  const [description, setDescription] = useState(content? content.description : '');
  const [descriptionError, setDescriptionError] = useState('');
  const [managerIdError, setManagerIdError] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState(null);

  const [defaultValue, setDefaultValue] = useState(content ? {value: content.managedBy?.email, label: `${content.managedBy?.firstName} ${content.managedBy?.lastName} - ${content.managedBy?.email}`, ...content.managedBy } : null);
  const [defaultThemeValue, setDefaultThemeValue] = useState(content?.theme ? {value: content.theme?.title, label: content.theme?.title, ...content.theme } : null);
  const [managerId, setManagerId] = useState(defaultValue ? defaultValue : '');
  const [selectedTheme, setMyTheme] = useState(defaultThemeValue ? defaultThemeValue : '');
  const {
    theme,
    themesList
  } = useSelector((state) => state.theme);

  useEffect(() => {
    dispatch(usersSagaActions.getUsersByRole({role: 'ROLE_HEAD_OF'}));
    dispatch(themesSagaActions.getActiveThemes());
  }, [dispatch]);

  useEffect(() => {
    if (theme) {
      closeThemeModal();
      setDefaultThemeValue({value: theme.title, label: theme.title, ...theme});
      setMyTheme(theme);
    }
  }, [theme]);

  useEffect(() => {
    if (!isFormVisible) {
      setTask(null);
    }
  }, [isFormVisible]);

  function openThemeModal() {
    setThemeIsOpen(true);
  }

  function closeThemeModal() {
    setThemeIsOpen(false);
  }

  function toggleShowForm() {
    setIsFormVisible(!isFormVisible);
  }

  function assignToManager(val) {
    setManagerId(val);
    setDefaultValue(val);
  }

  function assignTheme(val) {
    setMyTheme(val);
  }

  function isFormValid() {
    if ((title === '') || (description === '') || (managerId === '')) {
      if (title === '') {
        setTitleError('Ce champ est obligatoire!');
      }
      if (description === '') {
        setDescriptionError('Ce champ est obligatoire!');
      }
      if (managerId === '') {
        setManagerIdError('Ce champ est obligatoire!');
      }

      return false;
    }

    return true;
  }

  function submitForm() {
    if (isFormVisible) {
      window.alert('Merci de terminer vos tâches !');
      return;
    }
    if (isFormValid()) {
      if (content) {
        let updatedContent = {...content, title, description, managedBy: `/api/users/${managerId.id}`};
        delete updatedContent.tasks;

        if (selectedTheme) {
          updatedContent = {
            ...updatedContent,
            theme: `/api/themes/${selectedTheme.id}`
          };
        } else if (content.theme) {
          updatedContent = {
            ...updatedContent,
            theme: null
          };
        }

        dispatch(contentSagaActions.editContent(updatedContent));
        closeModal();
      } else {
        let payload = {
          title,
          description,
          managedBy: `/api/users/${managerId.id}`,
          tasksList: tasks
        };

        if (selectedTheme) {
          payload = {
            ...payload,
            theme: selectedTheme['@id']
          };
        }

        dispatch(contentSagaActions.addContentAndTasks(payload));
      }
    }
  }

  function handleCancel() {
    if (content) {
      closeModal();
    } else {
      navigate('/content');
    }
  }

  function handleTaskSubmit(data, edit) {
    if (!edit) {
      setTasks([...tasks, data]);
    } else {
      tasks[task.index] = data;
    }
  }

  function removeTask(index) {
    const newTasks = tasks.filter((item, i) => i !== index);
    setTasks(newTasks);
  }

  function handleEdit(item, index) {
    setTask({...item, index});
    setIsFormVisible(true);
  }

  return (
    <div className={'flex flex-col gap-3'}>
      <div className="w-full flex justify-between items-center">
        <h1>{formTitle}</h1>
        <div className={'flex gap-1 justify-end'}>
          <Button className={'text-primary bg-white border-primarymd: md:w-1/2'} value={'Annuler'} onClick={handleCancel}/>
          <Button className={'text-white bg-primary md:w-1/2'} value={content? 'Modifier' : 'Ajouter'} onClick={submitForm} />
        </div>
      </div>
      <InputField type={'text'} placeholder={'Titre'} label={'Titre'} value={title} onChange={(e) => {
        setTitle(e.target.value);
        setTitleError('');
      }}
        error={titleError}
      />
      <SelectSearch
        items={headOfList}
        label={'Affecté à'}
        field={'email'}
        onChange={assignToManager}
        error={managerIdError}
        value={defaultValue}
        user
      />
      <div className={'w-full'}>
        <SelectSearch
          items={themesList}
          label={'Thématique'}
          field={'title'}
          placeholder={'Sélectionner une thématique'}
          onChange={assignTheme}
          value={selectedTheme}
        />
        {!content &&
          <span className={'w-full flex justify-end font-light text-sm text-gray-600 cursor-pointer'} onClick={openThemeModal}>
            Créer une nouvelle thématique.
          </span>
        }
      </div>
      <TextAreaField placeholder={'Description...'} rows={3} label={'Description'} value={description} onChange={(e) => {
        setDescription(e.target.value);
        setDescriptionError('');
      }} error={descriptionError} />
      {!content?
      <>
        <div className={'flex flex-col gap-0'}>
        <div className={'flex flex-row justify-between items-center'}>
          <span className={'font-medium mb-2'}>
            Tâche
            {tasks.length > 1 ? 's ' : ' '}
            ({tasks.length})
          </span>
          <div className={ (isFormVisible? 'hidden' : 'block')}>
            <AddButton onClick={toggleShowForm} />
          </div>
        </div>
        {
          tasks.length>0 ?
            <>
              <div className={'flex flex-col px-4 gap-4'}>
                {tasks?.map((item, index) => (
                  <span key={index} className={'flex items-center gap-4 pb-2 border-b border-b-gray-300'}>
                    <div className={'flex gap-2'} >
                      <span className={'cursor-pointer hover:text-yellow-500 border hover:border-yellow-500 rounded p-1'} onClick={() => handleEdit(item, index)}>
                        <PenIcon size={'w-3 h-3'} />
                      </span>
                      <div className={'cursor-pointer hover:text-red-600 border hover:border-red-600 rounded p-1'} onClick={() => removeTask(index)}>
                        <TrashIcon size={'w-3 h-3'} />
                      </div>
                    </div>
                    -&nbsp;&nbsp;&nbsp;
                    {item.title}
                  </span>
                ))}
              </div>
            </>
          : null
        }
      </div>
        <TaskForm task={task} isFormVisible={isFormVisible} onDelete={toggleShowForm} onSubmit={handleTaskSubmit} />
        <Modal
          isOpen={themeModalIsOpen}
          onRequestClose={closeThemeModal}
          style={modalStyle}
        >
          <ThemeForm closeModal={closeThemeModal} />
        </Modal>
      </>
        : null
      }
    </div>
  );
}

export default ContentForm;