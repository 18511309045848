import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const initialState = {
  totalItems: 0,
  usersList: [],
  headOfList: [],
  calendarEvents: [],
  isSynced: null,
  success: false
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getAllUsers(state, action) {
      state.totalItems = action.payload['hydra:totalItems'];
      state.usersList = action.payload['hydra:member'];
    },
    deactivateUser(state, action) {
      const etat = action.payload.data.isDeleted ? 'désactivé' : 'activé';
      toast.success(`Utilisateur ${etat} avec succès!`, {theme: 'dark'});
      state.usersList[action.payload.index] = action.payload.data;
    },
    addUserSuccess(state, action){
      state.success = true;
      toast.success(`Utilisateur crée avec succès!`, {theme: 'dark'});
      state.usersList.push(action.payload.data);
    },
    addUserFail(state, action) {
      if (action.payload === 409) {
        toast.error('Adresse email déjà enregistrée!', {theme: 'dark'});
      } else {
        toast.error('Une erreur est survenue veuillez réessayer ultérieurement', {theme: 'dark'});
      }
    },
    editUser(state, action) {
      toast.success(`Utilisateur modifié avec succès!`, {theme: 'dark'});
      state.usersList[action.payload.index] = action.payload.data;
    },
    httpFailure() {
      toast.error('Une erreur est survenue veuillez réessayer ultérieurement', {theme: 'dark'});
    },
    getContentCreators(state, action){
      state.usersList = action.payload['hydra:member'];
    },
    getUsersByRole(state, action){
      state.headOfList = action.payload
    },
    getUsersSuccess(state, action) {
      state.usersList = action.payload
    },
    getEventsFromCalendar(state, action) {
      if (200 === action.payload.status) {
        state.calendarEvents = action.payload.data;
        state.isSynced = true;
      } else {
        state.isSynced = false;
      }
    }
  }
});

export const {
  getAllUsers,
  deactivateUser,
  addUserSuccess,
  addUserFail,
  editUser,
  httpFailure,
  getContentCreators,
  getUsersByRole,
  getUsersSuccess,
  getEventsFromCalendar
} = usersSlice.actions;

export default usersSlice.reducer;