import api from '../../hooks/Api';
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  addCommentsSuccess,
  editCommentSuccess,
  deleteCommentSuccess
} from './commentSlice';
import {httpFailure} from '../user/usersSlice';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

function* addComment(data) {
  return yield call(() => api({
    url: `${baseUrl}/api/comments`,
    method: 'post',
    data
  }));
}

function* workerAddComments(action) {
  const comments = action.payload;
  let comment = {};
  try {
    for (const i in comments) {
      comment = yield addComment(comments[i]);
    }

    yield put(addCommentsSuccess(comment.data));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* workerEditComment(action) {
  const comment = action.payload;
  delete comment.createdBy;

  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/comments/${comment.id}`,
      method: 'put',
      data: comment
    }));

    yield put(editCommentSuccess(res.data));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* workerDeleteComment(action) {
  const comment = action.payload;

  try {
    yield call(() => api({
      url: `${baseUrl}/api/comments/${comment.id}`,
      method: 'delete'
    }));

    yield put(deleteCommentSuccess(comment));
  } catch (e) {
    yield put(httpFailure());
  }
}

export function* watcherComments() {
  yield takeEvery('ADD_COMMENTS', workerAddComments);
  yield takeEvery('EDIT_COMMENT', workerEditComment);
  yield takeEvery('DELETE_COMMENT', workerDeleteComment);
}