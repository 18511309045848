import React, {useState} from 'react';
import InputField from '../ui/inputField';
import Button from '../ui/button';
import {useDispatch} from 'react-redux';
import {destinationSagaActions} from '../../redux/destination/destinationSagaActions';

function DestinationForm({destination, index, closeModal}) {
  const [name, setName] = useState(destination ? destination.name : '');
  const [nameError, setNameError] = useState('');
  const [type, setType] = useState(destination ? destination.type : 'BLOG');
  const dispatch = useDispatch();

  function submitForm() {
    if (name.length === 0) {
      setNameError('Ce champ est obligatoire!');
      return;
    }

    if (!destination) {
      dispatch(destinationSagaActions.addDestination({name, type}));
    } else {
      destination = {...destination, name, type};
      dispatch(destinationSagaActions.editDestination({destination, index}));
    }

    closeModal();
  }

  return (
    <>
      <div className="flex justify-between">
        <p className={'text-primary font-medium mb-5'}> {destination ? 'Modifier ' : 'Nouvelle '} déstination</p>
        <span className={'cursor-pointer'} onClick={closeModal}>x</span>
      </div>
      <div className={'w-72 flex flex-col items-end gap-3'}>
        <InputField type={'text'} label={'Déstination'} placeholder={'Déstination'} value={name}
                    onChange={(e) => setName(e.currentTarget.value)} error={nameError} />
        <div className={'flex flex-col items-start gap-2 w-full'}>
          <label>Type</label>
          <select onChange={(e) => setType(e.currentTarget.value)} defaultValue={destination?.type}>
            <option value="BLOG">Blog</option>
            <option value="SOCIAL_MEDIA">Réseaux social</option>
            <option value="SITE">Site</option>
            <option value="OTHER">Autre...</option>
          </select>
        </div>
        <div className="w-2/5">
          <Button className={'bg-primary text-white'} onClick={submitForm}
                  value={destination ? 'Modifier' : 'Ajouter'} />
        </div>
      </div>
    </>
  );
}

export default DestinationForm;
