import {createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

const initialState = {
  totalItems: 0,
  destinationsList: []
};

const destinationSlice = createSlice({
  name: 'destination',
  initialState,
  reducers: {
    httpFailure() {
      toast.error('Une erreur est survenue veuillez réessayer ultérieurement', {theme: 'dark'});
    },
    getAllDestinations(state, action) {
      state.totalItems = action.payload['hydra:totalItems'];
      state.destinationsList = action.payload['hydra:member'];
    },
    addDestination(state, action) {
      toast.success('Déstination ajoutée avec succès!', {theme: 'dark'});
      state.destinationsList.push(action.payload.data);
    },
    editDestination(state, action) {
      toast.success('Déstination modifiée avec succès!', {theme: 'dark'});
      state.destinationsList[action.payload.index] = action.payload.data;
    },
    deactivateDestination(state, action) {
      const etat = action.payload.data.isActive ? 'activée' : 'désactivée';
      toast.success(`Déstination ${etat} avec succès!`, {theme: 'dark'});
      state.destinationsList[action.payload.index] = action.payload.data;
    }
  }
});

export const {
  httpFailure,
  getAllDestinations,
  addDestination,
  editDestination,
  deactivateDestination
} = destinationSlice.actions;

export default destinationSlice.reducer;