export const contentSagaActions = {
  getAllContent,
  getContentByCreator,
  getContentByTitle,
  addContent,
  getContentById,
  editContent,
  deleteContent,
  addContentAndTasks
};

function getAllContent(payload) {
  return {
    type: 'FETCH_ALL_CONTENTS',
    payload
  };
}

function getContentByCreator(payload) {
  return {
    type: 'FIND_CONTENT_BY_CREATOR',
    payload
  };
}

function getContentByTitle(payload) {
  return {
    type: 'FIND_CONTENT_BY_TITLE',
    payload
  };
}

function addContent(payload) {
  return {
    type: 'ADD_CONTENT',
    payload
  };
}

function getContentById(payload) {
  return {
    type: 'GET_CONTENT_BY_ID',
    payload
  };
}

function editContent(payload) {
  return {
    type: 'EDIT_CONTENT',
    payload
  };
}

function deleteContent(payload) {
  return {
    type: 'DELETE_CONTENT',
    payload
  };
}

function addContentAndTasks(payload) {
  return {
    type: 'Add_CONTENT_AND_TASKS',
    payload
  };
}