import api from '../../hooks/Api';
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  addContentSuccess,
  deleteContentSuccess,
  findContentByField,
  getAllContent,
  getContentById
} from './contentSlice';
import {httpFailure} from '../user/usersSlice';
import {toast} from 'react-toastify';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

function* workerGetAllContent(action) {
  try {
    let url = '';
    let theme = '';

    if (action.payload) {
      if (action.payload.page) {
        url = `?_page=${action.payload.page}&`;
      }


      if (action.payload.theme) {
        theme = `theme.title=${action.payload.theme}`;
      }
    }

    const res = yield call(() => api({
      url: `${baseUrl}/api/contents${url}${theme}`
    }));

    yield put(getAllContent(res.data));
  } catch {}
}

function* workerFindContentByCreator(action) {
  let searchFiled = '';
  searchFiled = action.payload ? `?createdBy.email=${action.payload}` : '';
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/contents${searchFiled}`
    }));
    yield put(findContentByField(res.data));
  } catch {
    yield put(httpFailure());
  }
}

function* workerFindContentByTitle(action) {
  let field = '';
  field = action.payload ? `?title=${action.payload}` : '';
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/contents${field}`
    }));
    yield put(findContentByField(res.data));
  } catch {
    yield put(httpFailure());
  }
}

function* addContent(action) {
  return yield call(() => api({
    url: `${baseUrl}/api/contents`,
    method: 'post',
    data: action.payload
  }));
}

function* workerAddContent(action) {
  try {
    const res = addContent(action);

    yield put(addContentSuccess(res.data));
  } catch {
    yield put(httpFailure());
  }
}

function* workerGetContentById(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/contents/${action.payload}`
    }));

    yield put(getContentById(res.data));
  } catch {
    yield put(httpFailure());
  }
}

function* workerEditContent(action) {
  try {
    const res = yield editContent(action.payload);

    toast.success('Contenu modifié avec succès!', {theme: 'dark'});
    yield put(getContentById(res.data));
  } catch {
    yield put(httpFailure());
  }
}

function* editContent(content) {
  delete content.createdBy;

  return yield call(() => api({
    url: `${baseUrl}/api/contents/${content.id}`,
    method: 'put',
    data: content
  }));
}

function* workerDeleteContent(action) {
  try {
    yield call(() => api({
      url: `${baseUrl}/api/contents/${action.payload.id}`,
      method: 'delete'
    }));

    yield put(deleteContentSuccess());
  } catch {
    yield put(httpFailure());
  }
}

export function* addTask(task) {
  return yield call(() => api({
    url: `${baseUrl}/api/tasks`,
    method: 'post',
    data: task
  }));
}

function* workerAddContentAndTask(action) {
  try {
    const content = yield addContent(action);
    if (action.payload.tasksList?.length > 0) {
      for (let i in action.payload.tasksList) {
        let listOfDestinations = [];
        for (let j in action.payload.tasksList[i].destination) {
          listOfDestinations.push({destination: action.payload.tasksList[i].destination[j]['@id']});
        }
        const task = {
          ...action.payload.tasksList[i],
          assignedTo: `/api/users/${action.payload.tasksList[i].assignedTo.id}`,
          category: action.payload.tasksList[i].category['@id'],
          content: content.data['@id'],
          destinations: listOfDestinations
        };
        yield addTask(task);
      }
    }

    yield put(addContentSuccess(content.data));
  } catch (e) {
    yield put(httpFailure());
  }
}

export function* watcherContents() {
  yield takeEvery('FETCH_ALL_CONTENTS', workerGetAllContent);
  yield takeEvery('FIND_CONTENT_BY_CREATOR', workerFindContentByCreator);
  yield takeEvery('FIND_CONTENT_BY_TITLE', workerFindContentByTitle);
  yield takeEvery('ADD_CONTENT', workerAddContent);
  yield takeEvery('GET_CONTENT_BY_ID', workerGetContentById);
  yield takeEvery('EDIT_CONTENT', workerEditContent);
  yield takeEvery('DELETE_CONTENT', workerDeleteContent);
  yield takeEvery('Add_CONTENT_AND_TASKS', workerAddContentAndTask);
}