import React, {useEffect, useState} from 'react';
import EditButton from '../../components/ui/editButton';
import PreviewButton from '../../components/ui/previewButton';
import AddButton from '../../components/ui/addButton';
import InputField from '../../components/ui/inputField';
import {useDispatch, useSelector} from 'react-redux';
import {usersSagaActions} from '../../redux/user/usersSagaActions';
import Pagination from '../../components/ui/pagination';
import InfoIcon from '../../components/icons/infoIcon';
import Modal from 'react-modal';
import {modalStyle} from '../../utils/modalStyle';
import UserCard from '../../components/ui/userCard';
import UserForm from '../../components/forms/userForm';
import getRole from '../../hooks/getRole';
import FilterButton from '../../components/ui/filterButton';

function Users(props) {
  let path = `${process.env.REACT_APP_BACK_END_URL}/media`;
  const [showFilter, setShowFilter] = useState(false);

  const [user, setUser] = useState(null);
  const [index, setIndex] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  Modal.setAppElement('#root');
  const [search, setSearch] = useState('');
  const [isFree, setIsFree] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isNotDeleted, setIsNotDeleted] = useState(false);

  const dispatch = useDispatch();
  const {
    usersList,
    totalItems,
    success
  } = useSelector(
    (state) => state.users
  );

  useEffect(() => {
    dispatch(usersSagaActions.getAllUsers({page: 1, search, isDeleted, isNotDeleted, isFree, isEmployee}));
  }, [dispatch, isFree, isEmployee, search, isDeleted, isNotDeleted]);

  useEffect(() => {
    if (success) closeFormModal();
  }, [success]);

  function onPageChange(currentPage) {
    dispatch(usersSagaActions.getAllUsers({page: currentPage.selected + 1}));
  }

  function openPreviewModal(e, user) {
    e.preventDefault();
    setUser(user);
    setIsOpen(true);
  }

  function openFormModal(e, user, key) {
    e.preventDefault();
    setUser(user);
    setIndex(key);
    setFormModalIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeFormModal() {
    setFormModalIsOpen(false);
    setUser(null);
  }

  function toggleShowFilter() {
    setShowFilter(!showFilter);
  }

  function deactivateUser(user, index) {
    const state = user.isDeleted ? 'activer' : 'désactiver';
    if (window.confirm(`Voulez vous vraiment ${state} cet utilisateur?`)) {
      user = {...user, isDeleted: !user.isDeleted};
      dispatch(usersSagaActions.deactivateUser({user, index}));
    }
  }

  return (
    <>
      <div className={'flex flex-col items-center gap-3'} >
        <div className={'w-full flex justify-between'}>
          <h1>Liste des utilisateurs</h1>
          <div className={'flex gap-2'}>
            <div className="relative">
              <FilterButton onClick={toggleShowFilter} showFilter={showFilter} />
              <div
                className={'absolute top-11 right-0 bg-white w-52 border border-gray-500 py-4 px-2 rounded ' + (showFilter ? 'block' : 'hidden')}>
                <div className="w-full text-center border-b border-b-primary">
                  Filtres
                </div>
                <div className={'pt-6'}>
                  <InputField type={'text'} placeholder={'Email'} label={'Mot clé'}
                              onChange={(e) => setSearch(e.currentTarget.value)} />
                  <div className={'flex flex-col gap-3 mt-2'}>
                    <label>Type</label>
                    <div className={'flex items-center'}>
                      <input className={'w-8 h-4'} type={'checkbox'} checked={isFree}
                             onChange={() => setIsFree(!isFree)} />
                      <label>Freelance</label>
                    </div>
                    <div className={'flex items-center'}>
                      <input className={'w-8 h-4'} type={'checkbox'} checked={isEmployee}
                             onChange={() => setIsEmployee(!isEmployee)} />
                      <label>Employé(e)</label>
                    </div>
                  </div>
                  <div className={'flex flex-col gap-3 mt-2'}>
                    <label>État</label>
                    <div className={'flex items-center'}>
                      <input className={'w-8 h-4'} type={'checkbox'} checked={isNotDeleted}
                             onChange={() => setIsNotDeleted(!isNotDeleted)} />
                      <label>Actif</label>
                    </div>
                    <div className={'flex items-center'}>
                      <input className={'w-8 h-4'} type={'checkbox'} checked={isDeleted}
                             onChange={() => setIsDeleted(!isDeleted)} />
                      <label>Inactif</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddButton onClick={openFormModal} />
          </div>
        </div>
        <div className="w-full rounded border border-gray">
          {usersList.length > 0 ?
            <table className={'w-full border-collapse text-left text-xs md:text-base'}>
              <thead className={'bg-primary text-white font-medium'}>
              <tr>
                <th scope="col" className="md:px-4 md:py-2 p-1">Utilisateur</th>
                <th scope="col" className="md:px-4 md:py-2 p-1">Profil</th>
                <th scope="col" className="md:px-4 md:py-2 p-1">Actif</th>
                <th scope="col" className="md:px-4 md:py-2 p-1 text-center">Actions</th>
              </tr>
              </thead>
              <tbody className={'divide-y divide-gray-100 border-t border-gray-100'}>
                {usersList?.map((user, key) =>
                  <tr key={key} className="hover:bg-gray-50">
                    <td className="flex items-center justify-start gap-3 md:px-4 md:py-2 px-2 py-1">
                      <img className={'h-6 w-6 md:h-10 md:w-10 rounded-full bg-white border-2 border-primary'}
                           src={user.profilePhoto ?
                             `${path}/${user.profilePhoto?.name}`
                             : 'https://www.cosavostra.com/wp-content/uploads/2020/04/logo-cosavostra-hashtag.svg'
                           } alt={'user'} />
                      <div className={'text-xs md:text-sm truncate'}>
                        <p className={'font-medium capitalize'}>{user.firstName} {user.lastName}</p>
                        <p className={'text-gray-500 font-light'}>{user.email}</p>
                      </div>
                    </td>
                    <td className={'md:px-4 md:py-2 px-2 py-1 text-xs md:text-sm'}>
                      {user.type === 'FREELANCE' ? 'Freelance' : 'Employé(e)'}
                    </td>
                    <td className={'md:px-4 md:py-2 px-2 py-1 text-xs md:text-sm flex items-center justify-center'}>
                      <input className={'w-4 h-4 md:w-6 md:h-6 accent-primary cursor-pointer'} type={'checkbox'}
                             checked={!user.isDeleted} onChange={() => deactivateUser(user, key)} />
                    </td>
                    <td className={'md:px-4 md:py-2 px-2 py-1 text-xs md:text-sm max-w-fit'}>
                      <div className="flex gap-2 items-center justify-center">
                        <div className={'w-1/2'}>
                          <EditButton onClick={(e) => openFormModal(e, user, key)} sm={true} />
                        </div>
                        <div className={'w-1/2'}>
                          <PreviewButton onClick={(e) => openPreviewModal(e, user)} sm={true} />
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            :
            <div className={'flex justify-center bg-blue-100 rounded-lg p-5 text-sm text-blue-700 w-full gap-4'}>
              <InfoIcon />
              <p>
                Aucun utilisateur n'a été trouvé!
              </p>
            </div>
          }
        </div>
        <Pagination totalItems={totalItems} onPageChange={onPageChange} />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        {user ?
          <UserCard
            closeModal={closeModal}
            title={'Détails utilisateur :'}
            firstName={user.firstName}
            lastName={user.lastName}
            type={user.type}
            company={user.company}
            phone={user.phone}
            email={user.email}
            country={user.country}
            role={getRole(user.roles).text}
          />
          : null}
      </Modal>

      <Modal
        isOpen={formModalIsOpen}
        onRequestClose={closeFormModal}
        style={modalStyle}
      >
        <UserForm user={user} index={index} closeModal={closeFormModal} />
      </Modal>
    < />
  );
}

export default Users;