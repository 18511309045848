import {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {logout} from '../../redux/auth/authSlice';
import {useDispatch, useSelector} from 'react-redux';
import ArrowDownIcon from '../icons/arrowDownIcon';
import ArrowUpIcon from '../icons/arrowUpIcon';
import Notifications from '../Notifications/notifications';
import TagIcon from '../icons/tagIcon';

function SideBar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [notifications, setNotifications] = useState(0);
  const [isShowConfig, setIsShowConfig] = useState(false);
  const {
    user_firstName,
    user_lastName,
    user_image,
    user_type,
    user_company,
    user_roles
  } = useSelector(
    (state) => state.auth
  );

  function logOut(e) {
    e.preventDefault();

    dispatch(logout());
    navigate('/login');
  }

  function toggleIsVisible() {
    setIsVisible(!isVisible);
    setShow(false);
  }

  function populateSideBar(){
    return(
      <>
        <div>
          <div className="w-screen md:w-max p-1.5 md:block hidden">
            <Link to={'/'} aria-label="dashboard"
                  className="relative flex items-center space-x-4  py-3 text-primary">
              <img src={'https://www.cosavostra.com/wp-content/uploads/2020/04/logo-cosavostra-hashtag.svg'}
                   className="w-10 p-0.5 rounded border-2 border-primary" alt="cosa logo" />
              <span className="-mr-1 text-lg font-medium">CosaContent</span>
            </Link>
          </div>
          <ul className="md:mt-6 space-y-0.5 tracking-wide">
            <li className="min-w-max rounded-lg">
              <Link to={'/my-profile'} onClick={() => setShow(false)} aria-label="dashboard"
                 className="relative flex items-center space-x-4 bg-[#FFB30D]/[.6] px-2 py-3 text-primary">
                <img src={user_image} className="w-10 rounded-full border-primary border border-primary"
                     alt="profil" />
                <div className={'flex flex-col'}>
                  <span className="-mr-1 font-medium capitalize">{user_firstName} {user_lastName}</span>
                  <span className="-mr-1 text-sm font-light">{user_type === 'FREELANCE' ? 'Freelance' : 'Employé(e)'} - {user_company}</span>
                </div>
              </Link>
            </li>
            <li className="min-w-max">
              <Link onClick={() => setShow(false)} to={'/'} className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-5 h-5">
                  <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round"
                        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                </svg>

                <span className="group-hover:text-gray-700">Dashboard</span>
              </Link>
            </li>
            <li className="min-w-max">
              <Link to={'#'} onClick={toggleIsVisible} className="bg group flex items-center space-x-4 rounded-full px-4 py-3 text-primary">
                <div className={'relative'}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-5 h-5">
                    <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round"
                          d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                  </svg>
                  <span className={'absolute -top-2 -right-1 w-4 h-4 text-xs rounded-full bg-red-600 text-white text-center '+ (notifications > 0 ? 'block' : 'hidden' )}>
                    {notifications}
                  </span>
                </div>
                <span className="group-hover:text-gray-700">Notifications ({notifications})</span>
              </Link>
            </li>
            <li className="min-w-max">
              <Link onClick={() => setShow(false)} to='/content' className="group flex items-center space-x-4 rounded-md px-4 py-3 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                </svg>
                <span className="group-hover:text-gray-700">Contenu</span>
              </Link>
            </li>
            <li className="min-w-max">
              <Link onClick={() => setShow(false)} to='/calendar' className="group flex items-center space-x-4 rounded-md px-4 py-3 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                </svg>
                <span className="group-hover:text-gray-700">Calendrier</span>
              </Link>
            </li>
            <li className="min-w-max">
              <Link onClick={() => setShow(false)} to='/themes' className="group flex items-center space-x-4 rounded-md px-4 py-3 text-primary">
                <TagIcon size={'w-5 h-5'} className={'group-hover:text-cyan-300'} />
                <span className="group-hover:text-gray-700">Thématiques</span>
              </Link>
            </li>
            {user_roles.includes('ROLE_ADMIN')?
              <li className="min-w-max cursor-pointer">
              <Link to={void(0)} onClick={() => setIsShowConfig(!isShowConfig)}
                 className="group flex items-center space-x-4 rounded-md px-4 py-3 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-5 h-5">
                  <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round"
                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                  <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span className="group-hover:text-gray-700 flex flex-row items-center gap-3">
                      Configuration
                  {isShowConfig ?
                    <ArrowUpIcon />
                    :
                    <ArrowDownIcon />
                  }

                    </span>
              </Link>
              <ul className={'ml-10 space-y-1.5 ' + (isShowConfig ? 'show' : 'hidden')}>
                <li className={'min-w-max'}>
                  <Link onClick={() => setShow(false)} to={'/configurations/categories'} className="group flex items-center space-x-4 rounded-md px-4 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                    </svg> &nbsp;
                    Catégories
                  </Link>
                </li>
                <li className={'min-w-max'}>
                  <Link onClick={() => setShow(false)} to={'/configurations/status'} className="group flex items-center space-x-4 rounded-md px-4 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg> &nbsp;
                    Statuts
                  </Link>
                </li>
                <li className={'min-w-max'}>
                  <Link onClick={() => setShow(false)} to={'/configurations/destinations'} className="group flex items-center space-x-4 rounded-md px-4 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                    </svg> &nbsp;
                    Destinations
                  </Link>
                </li>
                <li className={'min-w-max'}>
                  <Link onClick={() => setShow(false)} to={'/configurations/users'} className="group flex items-center space-x-4 rounded-md px-4 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                    </svg> &nbsp;
                    Utilisateurs
                  </Link>
                </li>
                <li className={'min-w-max'}>
                  <Link onClick={() => setShow(false)} to={'/configurations/wordpress'} className="group flex items-center space-x-4 rounded-md px-4 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-wordpress" viewBox="0 0 16 16">
                      <path className={'group-hover:text-cyan-300'}
                        d="M12.633 7.653c0-.848-.305-1.435-.566-1.892l-.08-.13c-.317-.51-.594-.958-.594-1.48 0-.63.478-1.218 1.152-1.218.02 0 .039.002.058.003l.031.003A6.838 6.838 0 0 0 8 1.137 6.855 6.855 0 0 0 2.266 4.23c.16.005.313.009.442.009.717 0 1.828-.087 1.828-.087.37-.022.414.521.044.565 0 0-.371.044-.785.065l2.5 7.434 1.5-4.506-1.07-2.929c-.369-.022-.719-.065-.719-.065-.37-.022-.326-.588.043-.566 0 0 1.134.087 1.808.087.718 0 1.83-.087 1.83-.087.37-.022.413.522.043.566 0 0-.372.043-.785.065l2.48 7.377.684-2.287.054-.173c.27-.86.469-1.495.469-2.046zM1.137 8a6.864 6.864 0 0 0 3.868 6.176L1.73 5.206A6.837 6.837 0 0 0 1.137 8z" />
                      <path className={'group-hover:text-cyan-300'}
                        d="M6.061 14.583 8.121 8.6l2.109 5.78c.014.033.03.064.049.094a6.854 6.854 0 0 1-4.218.109zm7.96-9.876c.03.219.047.453.047.706 0 .696-.13 1.479-.522 2.458l-2.096 6.06a6.86 6.86 0 0 0 2.572-9.224z" />
                      <path className={'group-hover:text-cyan-300'} fillRule="evenodd"
                            d="M0 8c0-4.411 3.589-8 8-8 4.41 0 8 3.589 8 8s-3.59 8-8 8c-4.411 0-8-3.589-8-8zm.367 0c0 4.209 3.424 7.633 7.633 7.633 4.208 0 7.632-3.424 7.632-7.633C15.632 3.79 12.208.367 8 .367 3.79.367.367 3.79.367 8z" />
                    </svg>
                    &nbsp;
                    WordPress
                  </Link>
                </li>
              </ul>
            </li>
              : null 
            }
          </ul>
        </div>
        <div className="w-screen md:w-max -mb-3">
              <span className="group flex items-center space-x-4 rounded-md px-4 py-3 text-primary cursor-pointer"
                    onClick={logOut}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-5 h-5">
                  <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                </svg>
                <span className="group-hover:text-gray-700">Déconnexion</span>
              </span>
      </div>
      </>
    )
  }

  return (
    <div className='fixed top-0 left-0 flex md:flex-row flex-col z-50'>
      <div className="md:hidden w-screen">
        <div className={'min-w-screen min-h-12 flex justify-between bg-secondary py-1 px-4 items-center sticky top-0'}>
          <Link to={'/'} className={'flex flex-row gap-1 items-center hover:cursor-pointer no-underline'}>
            <img className={'h-10'} src={'../../assets/images/cv-white.png'} alt={''}/>
            <span className={'text-xl text-white'}>
              Cosa
              <b>Content</b>
            </span>
          </Link>
          <div className={'flex gap-2 items-center'}>
          <div className={'relative'} onClick={toggleIsVisible}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="gray" className="w-6 h-6">
              <path className={'group-hover:text-cyan-300'} strokeLinecap="round" strokeLinejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
            </svg>
            <span className={'absolute -top-2 -right-1 w-4 h-4 text-xs rounded-full bg-red-600 text-white text-center '+ (notifications > 0 ? 'block' : 'hidden' )}>
                  {notifications}
                </span>
          </div>
          <svg onClick={() => setShow(!show)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-7 h-7 hover:cursor-pointer">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          </div>
        </div>
        <div className={'min-w-screen h-screen bg-white flex flex-col fixed z-10 '+ (show? 'block' : 'hidden')}>
          {populateSideBar()}
        </div>
      </div>
      <div className="min-h-screen bg-white hidden md:block">
        <div className="sidebar min-h-screen w-[3.35rem] overflow-hidden border-r hover:w-56 hover:bg-white hover:shadow-lg">
          <div className="flex h-screen flex-col justify-between pt-2 pb-6">
            {populateSideBar()}
          </div>
        </div>
      </div>
      <Notifications isVisible={isVisible} toggleIsVisible={toggleIsVisible} setNotifications={setNotifications} />
    </div>
  );
}

export default SideBar;