import api from '../../hooks/Api';
import {call, put, takeEvery} from 'redux-saga/effects';
import {
  getAllNotifications,
  setUnSeenNotification,
  editNotificationSuccess,
  markAllNotificationsAsRead
} from './notificationSlice';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

function* workerGetNotifications(action) {
  let url = '';

  if (action.payload) {
    url = `?_page=${action.payload}`;
  }

  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/notifications${url}`
    }));

    yield put(getAllNotifications(res.data));
  } catch {}
}

function* workerGetUnSeenNotifications() {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/notifications?isSeen=false`
    }));

    yield put(setUnSeenNotification(res.data));
  } catch {}
}

function* workerSeeNotification(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/notifications/${action.payload.id}`,
      method: 'put',
      data: {isSeen: true}
    }));

    yield put(editNotificationSuccess(res.data));
  } catch {}
}

function* workerMarkNotificationsAsRead() {
  try {
    yield call(() => api({
      url: `${baseUrl}/api/mark_all_as_read`,
      method: 'put',
      data: {}
    }));

    yield put(markAllNotificationsAsRead());
  } catch {}
}

export function* watcherNotifications() {
  yield takeEvery('GET_ALL_NOTIFICATIONS', workerGetNotifications);
  yield takeEvery('GET_UNSEEN_NOTIFICATIONS', workerGetUnSeenNotifications);
  yield takeEvery('SEE_NOTIFICATION', workerSeeNotification);
  yield takeEvery('MARK_NOTIFICATIONS_AS_READ', workerMarkNotificationsAsRead);
}