import React, {useEffect, useRef, useState} from 'react';
import OpenAiButton from './openAiButton';
import OpenAiIcon from '../icons/openAiIcon';
import {useSelector} from 'react-redux';
import axios from 'axios';
import AlertIcon from '../icons/alertIcon';

function OpenAi({article, refactorArticle, isEditable}) {
  const openai_url = 'https://api.openai.com/v1';
  const token = process.env.REACT_APP_OPENAI_TOKEN;
  const [isVisible, setIsVisible] = useState(false);
  const {user_firstName} = useSelector((state) => state.auth);
  const [command, setCommand] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState('');
  const [userInput, setUserInput] = useState('');
  const lastRunFunctionRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const bottomEl = useRef(null);

  useEffect(() => {
    if (article) {
      setError('');
    }
  }, [article]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  function scrollToBottom() {
    bottomEl?.current?.scrollIntoView({behavior: 'smooth'});
  }

  function toggleIsVisible() {
    setIsVisible(!isVisible);
  }

  function handleRefactorArticle(str) {
    refactorArticle(str);
  }

  function makeCommand(command, message) {
    setCommand(command);

    addMessage(message, true, false);
  }

  function addMessage(data, received, isArticle) {
    setMessages(currentMessages => {
      return [
        ...currentMessages,
        {
          data,
          received,
          isArticle
        }
      ];
    });
  }

  async function handleVerifyOrthographe() {
    await openAiEdit('fix spelling and grammar mistakes if any.');
  }

  async function handleTranslateArticle() {
    const instruction = `translate my input to this language ${userInput}, if not possible return the input as is.`;

    return await openAiEdit(instruction);
  }

  async function handleOptimizeSeo() {
    return await openAiEdit('Improve my input\'s SEO and ponctuation.');
  }

  async function openAiEdit(instruction) {
    if (!article) {
      setError('veuillez écrire votre article');

      return;
    }

    setIsLoading(true);
    try {
      const res = await axios.post(
        `${openai_url}/edits`,
        {
          model: 'text-davinci-edit-001',
          input: article,
          instruction: instruction,
          temperature: 0
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      addMessage(res.data.choices[0].text.replaceAll('&nbsp;', ' '), true, true);
    } catch {
      setError('Une erreur est survenue veuillez réessayer ultérieurement.');
    }

    setIsLoading(false);
  }

  async function handleComplete() {
    await openAiComplete(parseInt(userInput));
  }

  async function runFunction(functionName) {
    switch (functionName) {
      case 'SPELLING_CHECK':
        lastRunFunctionRef.current = handleVerifyOrthographe;
        return handleVerifyOrthographe();
      case 'TRANSLATE':
        lastRunFunctionRef.current = handleTranslateArticle;
        return handleTranslateArticle();
      case 'OPTIMIZE_SEO':
        lastRunFunctionRef.current = handleOptimizeSeo;
        return handleOptimizeSeo();
      case 'COMPLETE':
        lastRunFunctionRef.current = handleComplete;
        return handleComplete();
      default:
        setError('Une erreur est survenue veuillez réessayer ultérieurement.');
    }
  }

  function reRunLastFunction() {
    if (lastRunFunctionRef.current) {
      lastRunFunctionRef.current();
    }
  }

  async function submitForm(e) {
    e.preventDefault();

    if (userInput === '') {
      setError('veuillez fournir une réponse valide.');

      return;
    }
    addMessage(userInput, false, false);

    switch (command) {
      case 'translate':
        await runFunction('TRANSLATE');
        break;
      case 'complete':
        const length = parseInt(userInput);
        if (!Number.isInteger(length)) {
          setError('la longueur doit être un entier.');

          return;
        }

        if (length > 1000 || length < 50) {
          setError('Article doit être entre 50 et 2000!');

          return;
        }

        await runFunction('COMPLETE');
        break;
      case 'chat':
        if (userInput.length > 0) {
          await openAiChat(userInput);
        }
        break;
      default:
        setError('Une erreur est survenue veuillez réessayer ultérieurement.');
    }

    setUserInput('');
  }

  async function openAiComplete(length) {
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${openai_url}/completions`,
        {
          model: 'text-davinci-003',
          prompt: `write an article starting with ${article}`,
          suffix: article,
          max_tokens: length,
          temperature: 0
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      addMessage(res.data.choices[0].text.replaceAll('&nbsp;', ' '), true, true);
    } catch (e) {
      setError('Une erreur est survenue veuillez réessayer ultérieurement.');
    }

    setIsLoading(false);
  }

  async function openAiChat(message) {
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${openai_url}/chat/completions`,
        {
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: message
            }
          ],
          max_tokens: 75,
          temperature: 0
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      addMessage(res.data.choices[0].message.content.replaceAll('&nbsp;', ' '), true, false);
    } catch (e) {
      setError('Une erreur est survenue veuillez réessayer ultérieurement.');
    }

    setIsLoading(false);
  }

  if (isEditable) {
    return;
  }

  return (
    <>
      <OpenAiButton onClick={toggleIsVisible} isVisible={isVisible} />
      {isVisible &&
        <div
          className={'z-50 fixed bottom-0 bg-white right-6 md:right-24 border border-gray-500 rounded-t w-60 md:w-80 h-[26rem] flex flex-col'}>
          <div className={'flex justify-between items-center p-2 border border-b-gray-500 rounded-t'}>
            <span>
              Votre Assistant AI
            </span>
            <span className={'cursor-pointer'} onClick={toggleIsVisible}>X</span>
          </div>
          <div className={'h-full max-h-full overflow-y-auto flex flex-col gap-2 p-2'}>
            <div className={'flex flex gap-2 py-2'}>
              <div className={'h-8 w-8 rounded-full bg-[#00A67E] flex items-center border border-primary'}>
                <OpenAiIcon />
              </div>
              <div className={'rounded p-1 bg-gray-300 max-w-full'}>
                Bonjour {user_firstName}, Je suis votre assistant AI, comment puis-je vous aider aujourd'hui?
              </div>
            </div>
            <div className={'flex flex-col items-center font-light text-blue-600'}>
              <span className={'hover:font-normal hover:underline cursor-pointer'} onClick={() => runFunction('OPTIMIZE_SEO')}>Optimisation SEO</span>
              <span className={'hover:font-normal hover:underline cursor-pointer'} onClick={() => runFunction('SPELLING_CHECK')}>Verification orthographe</span>
              <span className={'hover:font-normal hover:underline cursor-pointer'} onClick={() => makeCommand('translate', 'À quelle langue souhaitez-vous que votre texte soit traduit ?')}>Traduction</span>
              <span className={'hover:font-normal hover:underline cursor-pointer'} onClick={() => makeCommand('complete', 'Combien de mots souhaitez-vous avoir dans votre article ?')}>Terminer votre article</span>
              <span className={'hover:font-normal hover:underline cursor-pointer'} onClick={() => makeCommand('chat', 'Comment puis-je vous aider?')}>Autres...</span>
            </div>
            <div className={'flex flex-col gap-2'} ref={messagesContainerRef}>
              {messages?.map((message, idx) => (
                <div key={idx}>
                  {message.received ?
                    <div className={'flex flex-col'}>
                      <div className="flex gap-2">
                        <div className={'h-8 w-8 rounded-full bg-[#00A67E] flex items-center border border-primary'}>
                          <OpenAiIcon />
                        </div>
                        <div className={'rounded p-1 bg-gray-300 max-w-[80%]'}
                             dangerouslySetInnerHTML={{__html: message.data}} />
                      </div>
                      {idx + 1 === messages.length && message.isArticle &&
                        <div className={'flex gap-2 items-center justify-center py-2 cursor-pointer'}>
                          <span className={'bg-gray-300 rounded p-1 text-gray-700'} title={'regenerate'}
                                onClick={reRunLastFunction}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-4 h-4 hover:w-5 hover:h-5">
                              <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                          </span>
                          <span className={'bg-blue-300 rounded p-1 text-blue-700'} title={'confirm'}
                                onClick={() => handleRefactorArticle(message.data)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                 className="w-4 h-4 hover:w-5 hover:h-5">
                              <path
                                d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                            </svg>
                          </span>
                        </div>
                      }
                    </div>
                    :
                    <div className={'w-full flex justify-end'}>
                      <div className={'bg-blue-300 p-1 rounded max-w-[80%]'}>
                        {message.data}
                      </div>
                    </div>
                  }

                </div>
              ))}

              {error &&
                <div
                  className={'bg-red-600 bg-opacity-40 w-fill p-2 flex items-center justify-center rounded text-red-600'}>
                  <AlertIcon /> &nbsp; {error}
                </div>}
            </div>
            <div ref={bottomEl} />
          </div>

          <div className={'flex justify-between items-center p-2 border border-t-gray-500 '}>
            {isLoading ?
              <span className={'animate-pulse w-full flex justify-center'}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-6 h-6 animate-spin">
                  <path strokeLinecap="round" strokeLinejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </span> :
              <>
                <form className={'w-full flex gap-2 items-center text-primary'} onSubmit={submitForm}>
                  <input type={'text'} className={'h-8'} onChange={(e) => setUserInput(e.target.value)}
                         value={userInput} />
                  <button type={'submit'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                      <path
                        d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                    </svg>
                  </button>
                </form>
              </>
            }
          </div>
        </div>
      }
    </>
  );
}

export default OpenAi;