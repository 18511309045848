import React, {useCallback, useEffect, useState} from 'react';
import TrashIcon from '../../components/icons/trashIcon';
import PenIcon from '../../components/icons/penIcon';
import {useDispatch, useSelector} from 'react-redux';
import UserCard from '../../components/ui/userCard';
import {useDropzone} from 'react-dropzone';
import Button from '../../components/ui/button';
import {authSagaActions} from '../../redux/auth/authSagaActions';
import {modalStyle} from '../../utils/modalStyle';
import Modal from 'react-modal';
import UserForm from '../../components/forms/userForm';
import InputField from '../../components/ui/inputField';
import {usersSagaActions} from '../../redux/user/usersSagaActions';
import getRole from '../../hooks/getRole';
import {useNavigate} from 'react-router-dom';

function MyProfile(props) {
  const [file, setFile] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const dispatch = useDispatch();
  const [showSetting, setShowSetting] = useState(false);
  const [confirm, setConfirm] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const navigate = useNavigate();
  Modal.setAppElement('#root');

  const onDrop = useCallback(acceptedFiles => {
    const imageFile = acceptedFiles[0];
    setFile(imageFile);
  }, []);

  const {getRootProps, acceptedFiles, getInputProps, isDragActive} = useDropzone(
    {
      onDrop,
      accept: {
        'image/png': [],
        'image/jpg': [],
        'image/jpeg': []
      },
      multiple: false
    });

  const {
    user_id,
    user_email,
    user_firstName,
    user_lastName,
    user_image,
    user_type,
    user_company,
    user_phone,
    user_country,
    user_roles,
    user_isDeleted
  } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user_isDeleted) navigate('/');
  }, [navigate, user_isDeleted]);

  function editProfilePicture(e) {
    e.preventDefault();
    dispatch(authSagaActions.editProfilePicture({profilePhoto: file, id: user_id}));
    setFile(null);
  }

  function openModal() {
    setIsOpen(true);
  }

  function openDeleteModal() {
    setIsDeleteOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeDeleteModal() {
    setIsDeleteOpen(false);
  }

  function deactivateAccount() {
    if (`${user_firstName} ${user_lastName}` !== confirm) {
      setConfirmError('Veuillez verifier ce champ.');
      return;
    }

    const user = {
      isDeleted: true
    }

    dispatch(usersSagaActions.deactivateAccount({id: user_id, user }));
  }

  return (
    <>
      <div className={'relative'}>
        <div className={'absolute top-4 right-3'}>
          <button className={'text-white border border-white p-2 rounded bg-gray-100 bg-opacity-10 hover:bg-opacity-20'}
                  onClick={() => setShowSetting(!showSetting)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
            </svg>
          </button>
          <div
            className={'absolute rounded w-44 right-0 bg-white p-2 border border-secondary flex flex-col gap-2 z-10 ' + (showSetting ? 'block' : 'hidden')}>
            <p className={'text-gray-500 font-light'}>Paramètres :</p>
            <button onClick={() =>{ openModal(); setShowSetting(false)}}
                    className={'flex flex-row items-center justify-center gap-4 hover:bg-gray-500 py-1 hover:text-white text-sm'}>
              <PenIcon size={'w-3 h-3'} />
              Modifier profil
            </button>
            <button onClick={() =>{ openDeleteModal(); setShowSetting(false)}}
              className={'flex flex-row items-center justify-center gap-4 hover:bg-red-500 py-1 hover:text-white text-sm'}>
              <TrashIcon />
              Désactiver profil
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div>
          <img className={'w-full h-[200px] rounded-tl-lg rounded-tr-lg mb-2'}
               src={'https://vojislavd.com/ta-template-demo/assets/img/profile-background.jpg'} alt={'img'} />
        </div>
        <div className="flex flex-col items-center gap-2 -mt-20">
          <div className="relative">
            <img className={'h-40 w-40 rounded-full mb-2 border-2 border-white bg-white'}
                 src={user_image} alt={'img'} />
            <div
              className={'absolute rounded-full w-40 h-40 top-0 flex flex-col text-transparent justify-center items-center bg-gray-700 hover:text-white bg-opacity-0 hover:bg-opacity-75 cursor-pointer'} {...getRootProps()}>
              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p>Drop the files here ...</p> :
                  <p className={'flex flex-col items-center gap-2'}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                    Changer photo
                  </p>
              }
            </div>
          </div>
          {
            file && acceptedFiles?.map(file => (
              <li className={'font-light text-xs'} key={file.path}>
                {file.name}
              </li>))
          }
          <p className={'capitalize text-xl text-primary font-medium'}>{user_firstName} {user_lastName}</p>
          <span className={'font-light text-center'}>
          <p className={'text-gray-700'}> {user_type === 'EMPLOYEE' ? 'Employé(e)' : 'Freelance'} {user_company ? '. ' + user_company : null}</p>
          <p className={'text-gray-700 text-sm flex flex-row items-center justify-center'}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="w-3 h-3">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            </svg>
            &nbsp;
            {user_country}
          </p>
        </span>
        </div>
        <div className="flex justify-between mb-4">
          <p className={'text-2xl font-bold'}>Informations personnelles :</p>
          {file ?
            <div className="w-34 flex">
              <Button value={'Annuler'} className={'bg-white border-primary text-primary text-xs font-light'}
                      onClick={() => setFile(null)} />
              <Button value={'Confirmer'} className={'bg-primary text-white text-xs font-light'}
                      onClick={editProfilePicture} />
            </div>
            : null
          }
        </div>
        <UserCard firstName={user_firstName}
                  lastName={user_lastName}
                  country={user_country}
                  type={user_type}
                  company={user_company}
                  email={user_email}
                  phone={user_phone}
                  role={getRole(user_roles).text}
        />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={modalStyle}
        >
          <UserForm user={{
            id: user_id,
            firstName: user_firstName,
            lastName: user_lastName,
            company: user_company,
            phone: user_phone,
            type: user_type,
            email: user_email,
            roles: user_roles
          }} closeModal={closeModal} />
        </Modal>

        <Modal
          isOpen={isDeleteOpen}
          onRequestClose={closeDeleteModal}
          style={modalStyle}>
          <div className={'flex flex-col gap-5 items-center justify-center w-[80vw] md:w-[40vw]'}>
            <div className={'flex justify-between border-b border-primary w-full py-3'}>
              <p className={'text-primary font-medium'}>
                Désactiver cosaContent/{user_firstName}.{user_lastName}
              </p>
              <span className={'cursor-pointer'} onClick={closeDeleteModal}>x</span>
            </div>
            <div className={'rounded-full p-6 bg-red-500 text-white border-2 border-red-600'}>
              <TrashIcon size={'w-12 h-12'}/>
            </div>
            <p className={'text-sm select-none'}>
              Pour confirmer, veuillez taper "{user_firstName} {user_lastName}" dans le champ ci-dessous.
            </p>
            <InputField type={'text'} value={confirm} error={confirmError} onChange={(e) => {
            setConfirm(e.currentTarget.value);
            setConfirmError('');
            }
            } />
            <Button className={'bg-secondary text-white'} value={'Désactiver profil'} onClick={deactivateAccount}/>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default MyProfile;