import React from 'react';
import {Route, Routes} from 'react-router-dom';
import SideBar from '../../components/ui/sideBar';
import ProtectedRoutes from '../../routes/ProtectedRoutes';
import Dashboard from './dashboard';
import Categories from '../configuration/Categories';
import Status from '../configuration/Status';
import Destinations from '../configuration/Destinations';
import MyProfile from '../user/my-profile';
import Users from '../configuration/Users';
import {useSelector} from 'react-redux';
import ListOfContent from '../content/listOfContent';
import AddContent from '../content/addContent';
import DetailContent from '../content/detailContent';
import Calendar from '../calendar/calendar';
import NotificationsPage from './notificationsPage';
import Themes from '../themes/themes';
import WordPress from '../configuration/WordPress';

function DashboardLayout(props) {
  const {user_roles, user_isDeleted} = useSelector((state) => state.auth);

  return (
    <>
      <SideBar />
      <div className={'rounded px-2 py-12 md:p-4 my-4 mx-0 md:ml-16 md:mr-10 bg-white'}>
        <Routes>
          <Route path="/*" element={
            <ProtectedRoutes>
              <Dashboard />
            </ProtectedRoutes>
          }
          />
          {!user_isDeleted ? <>
              {user_roles.includes('ROLE_ADMIN') ?
                <>
                  <Route path="/configurations/categories" element={
                    <ProtectedRoutes>
                      <Categories />
                    </ProtectedRoutes>
                  }
                  />
                  <Route path="/configurations/status" element={
                    <ProtectedRoutes>
                      <Status />
                    </ProtectedRoutes>
                  }
                  />
                  <Route path="/configurations/destinations" element={
                    <ProtectedRoutes>
                      <Destinations />
                    </ProtectedRoutes>
                  }
                  />
                  <Route path="/configurations/users" element={
                    <ProtectedRoutes>
                      <Users />
                    </ProtectedRoutes>
                  }
                  />
                  <Route path="/configurations/wordpress" element={
                    <ProtectedRoutes>
                      <WordPress />
                    </ProtectedRoutes>
                  }
                  />
                </>
                : null
              }
              <Route path="/my-profile" element={
                <ProtectedRoutes>
                  <MyProfile />
                </ProtectedRoutes>
              }
              />
              <Route path="/calendar" element={
                <ProtectedRoutes>
                  <Calendar />
                </ProtectedRoutes>
              }
              />
              <Route path="/themes" element={
                <ProtectedRoutes>
                  <Themes />
                </ProtectedRoutes>
              }
              />
              <Route path="/content" element={
                <ProtectedRoutes>
                  <ListOfContent />
                </ProtectedRoutes>
              }
              />
              <Route path="/add-content" element={
                <ProtectedRoutes>
                  <AddContent />
                </ProtectedRoutes>
              }
              />
              <Route path="/content/:id" element={
                <ProtectedRoutes>
                  <DetailContent />
                </ProtectedRoutes>
              }
              />
              <Route path="/notifications" element={
                <ProtectedRoutes>
                  <NotificationsPage />
                </ProtectedRoutes>
              }
              />
            </>
            : null}
        </Routes>
      </div>
    </>
  );
}

export default DashboardLayout;