import React, {useEffect, useState} from 'react';
import TrashIcon from '../icons/trashIcon';
import CheckIcon from '../icons/checkIcon';
import InputField from '../ui/inputField';
import SelectSearch from '../ui/selectSearch';
import TextAreaField from '../ui/textAreaField';
import {useDispatch, useSelector} from 'react-redux';
import {usersSagaActions} from '../../redux/user/usersSagaActions';
import {categorySagaActions} from '../../redux/category/categorySagaActions';
import {destinationSagaActions} from '../../redux/destination/destinationSagaActions';
import {format} from 'date-fns';
import compareAsc from 'date-fns/compareAsc';
import {tasksSagaActions} from '../../redux/task/tasksSagaActions';

function TaskForm({task, isFormVisible, onDelete, onSubmit, content}) {
  const dispatch = useDispatch();
  const {usersList, headOfList} = useSelector((state) => state.users);
  const {activeCategoriesList} = useSelector((state) => state.category);
  const {destinationsList} = useSelector((state) => state.destination);

  const [category, setCategory] = useState(task ? task.category : null);
  const [user, setUser] = useState(task ? task.assignedTo : null);
  const [destination, setDestination] = useState(task ? task.destination : null);
  const [title, setTitle] = useState(task ? task.title : '');
  const [description, setDescription] = useState(task ? task.description : '');
  const [date, setDate] = useState(task ? task.deadlineAt : '');

  const [categoryError, setCategoryError] = useState(null);
  const [userError, setUserError] = useState('');
  const [destinationError, setDestinationError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [dateError, setDateError] = useState('');

  const requiredError = 'Ce champ est obligatoire!';

  useEffect(() => {
    dispatch(usersSagaActions.getUsers());
    dispatch(categorySagaActions.getActiveCategories());
    dispatch(destinationSagaActions.getActiveDestinations());
    dispatch(usersSagaActions.getUsersByRole({role: 'ROLE_HEAD_OF'}));
  }, [dispatch]);

  useEffect(() => {
    if (task) {
      setTitle(task.title);
      setCategory(task.category);
      setDescription(task.description);
      setDestination(task.destination);
      setUser(task.assignedTo);

      resetErrors();
    }
  }, [task]);

  useEffect(() => {
    if (category) {
      setCategoryError('');
    }
  }, [category]);

  useEffect(() => {
    if (destination) {
      setDestinationError('');
    }
  }, [destination]);

  useEffect(() => {
    if (user) {
      setUserError('');
    }
  }, [user]);

  function handleCategoryChange(val) {
    setCategory(val);
  }

  function handleDestinationChange(val) {
    setDestination(val);
  }

  function handleUserChange(val) {
    setUser(val);
  }

  function isFormValid() {
    let formValid = true;

    if (!title || !category || !destination || !date || !user || !description) {
      if (!title) {
        setTitleError(requiredError);
        formValid = false;
      }
      if (!category) {
        setCategoryError(requiredError);
        formValid = false;
      }
      if (!destination) {
        setDestinationError(requiredError);
        formValid = false;
      }
      if (!user) {
        setUserError(requiredError);
        formValid = false;
      }
      if (!description) {
        setDescriptionError(requiredError);
        formValid = false;
      }
    }

    if (!date || -1 === compareAsc(new Date(date), new Date().setDate(new Date().getDate() - 1))) {
      setDateError('Cette date n\'est pas valide!');
      formValid = false;
    }

    return formValid;
  }

  function resetData() {
    setTitle('');
    setCategory(null);
    setDescription('');
    setDestination(null);
    setUser(null);
    setDate('');
    resetErrors();
  }

  function resetErrors() {
    setDateError('');
    setDestinationError('');
    setUserError('');
    setDescriptionError('');
    setCategoryError('');
    setTitleError('');
  }

  function handleSubmit() {
    if (isFormValid()) {
      let data = {
        category: category,
        destination: destination,
        title,
        description,
        assignedTo: user,
        deadlineAt: date
      };

      if (task?.id) {
        data = {...task, ...data};
        dispatch(tasksSagaActions.editTask(data));
      } else {
        if (content) {
          data = {...data, content: content['@id']};
          dispatch(tasksSagaActions.addTask(data));
        } else {
          onSubmit(data, task);
        }
      }

      resetData();
      onDelete();
    }
  }

  return (
    <div className={'flex flex-col gap-2 border border-primary rounded p-2 ' + (isFormVisible ? 'block' : 'hidden')}>
      <div className="flex justify-between">
        <h1 className="text-xl">
          {task?.id ? 'Modifier ' : 'Nouvelle '}
          tâche
        </h1>
        <div className={'flex items-center gap-2'}>
            <span className={'cursor-pointer hover:text-red-600'} onClick={() => {
              onDelete();
              resetData();
            }}>
              <TrashIcon size={'h-5 w-5'} />
            </span>
          <span className={'cursor-pointer hover:text-green-600'} onClick={handleSubmit}>
              <CheckIcon size={'h-5 w-5'} />
            </span>
        </div>
      </div>

      <div className={'flex flex-col gap-3'}>
        <div className="flex flex-col md:flex-row gap-3">
          <InputField type={'text'}
                      value={title ? title : ''}
                      label={'Titre'}
                      placeholder={'Titre'}
                      error={titleError}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        setTitleError('');
                      }
                      }
          />
          <SelectSearch
            value={category}
            items={activeCategoriesList}
            field={'name'}
            label={'Catégorie'}
            placeholder={'Sélectionner une catégorie'}
            error={categoryError}
            onChange={handleCategoryChange}
          />
        </div>
        <div className="flex flex-col md:flex-row gap-3">
          <SelectSearch
            value={destination}
            items={destinationsList}
            field={'name'}
            label={'Canaux'}
            placeholder={'Sélectionner un canal'}
            onChange={handleDestinationChange}
            error={destinationError}
            isMulti
          />
          <InputField
            value={date ? date : ''}
            min={format(new Date(), 'yyyy-MM-dd')}
            className={'flex flex-row items-center'}
            type={'date'}
            placeholder={'Date de livraison'}
            label={'Date de livraison'}
            error={dateError}
            onChange={(e) => {
              setDate(e.target.value);
              setDateError('');
            }
            }
          />
        </div>
        <SelectSearch
          value={user}
          items={[...headOfList, ...usersList]}
          field={'email'}
          label={'Affectée à'}
          error={userError}
          onChange={handleUserChange}
          user
        />
        <TextAreaField placeholder={'Description...'}
                       rows={2}
                       label={'Description'}
                       value={description}
                       error={descriptionError}
                       onChange={(e) => {
                         setDescriptionError('');
                         setDescription(e.target.value);
                       }}
        />
      </div>
    </div>
  );
}

export default TaskForm;
