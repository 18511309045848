import React from 'react';

function Button({className, onClick, icon, value, ...rest}) {
  return (
    <button
      className={className + ` w-full h-fit rounded text-center font-medium border py-1 px-2 text-sm md:text-base md:py-2 md:px-4 hover:drop-shadow-xl flex justify-center gap-3 min-w-fit`}
      onClick={onClick} {...rest}>
      {icon ? icon : null}
      {value}
    </button>
  );
}

export default Button;
