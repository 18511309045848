export const categorySagaActions = {
  getAllCategories,
  getActiveCategories,
  addCategory,
  deactivateCategory,
  editCategory
};

function getAllCategories(payload) {
  return {
    type: 'FETCH_ALL_CATEGORIES',
    payload
  };
}

function getActiveCategories() {
  return {
    type: 'FETCH_ACTIVE_CATEGORIES'
  };
}

function addCategory(payload) {
  return {
    type: 'ADD_CATEGORY',
    payload
  };
}

function editCategory(payload) {
  return {
    type: 'EDIT_CATEGORY',
    payload
  }
}

function deactivateCategory(payload) {
  return {
    type: 'DEACTIVATE_CATEGORY',
    payload
  }
}