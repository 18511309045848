import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {contentSagaActions} from '../../redux/content/contentSagaActions';
import {useNavigate, useParams} from 'react-router-dom';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import EditButton from '../../components/ui/editButton';
import DeleteButton from '../../components/ui/deleteButton';
import ItemNotFound from '../../components/ui/itemNotFound';
import Modal from 'react-modal';
import {modalStyle} from '../../utils/modalStyle';
import ContentForm from '../../components/forms/contentForm';
import {resetContent} from '../../redux/content/contentSlice';
import TaskForm from '../../components/forms/taskForm';
import AddButton from '../../components/ui/addButton';
import TaskDetail from '../../components/tasks/taskDetail';
import Tag from '../../components/ui/tag';
import getRole from '../../hooks/getRole';

function DetailContent(props) {
  Modal.setAppElement('#root');
  const {user_email, user_roles} = useSelector((state) => state.auth);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [editTaskModalIsOpen, setEditTaskModalIsOpen] = useState(false);
  const [myTask, setTask] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();

  const {
    task
  } = useSelector(
    (state) => state.task
  );

  const {
    content,
    success
  } = useSelector(
    (state) => state.content
  );

  const {comment} = useSelector((state) => state.comment);

  useEffect(() => {
    dispatch(contentSagaActions.getContentById(id));
  }, [task, comment, dispatch, id]);

  useEffect(() => {
    if (success) {
      dispatch(resetContent());
      navigate('/content');
    }
  }, [navigate, success, dispatch]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleDelete() {
    if (window.confirm(`Voulez vous vraiment supprimé ce contenu d'une façon permanante?`)) {
      dispatch(contentSagaActions.deleteContent(content));
    }
  }

  function closeEditTaskModal() {
    setEditTaskModalIsOpen(false);
    setTask(null);
  }

  return (
    <>
      {
        content ?
          <div className={'flex flex-col gap-1'}>
            <div className={'flex justify-between items-center'}>
              <h1 className={'mb-3'}>Content</h1>
              {(
                content.createdBy.email === user_email
                || content.managedBy.email === user_email
                || getRole(user_roles).key === 'ROLE_ADMIN'
                ) &&
                <div className={'flex gap-1'}>
                  <EditButton onClick={openModal} />
                  <DeleteButton onClick={handleDelete} />
                </div>
              }
            </div>
            <div className={'w-full flex gap-2'}>
              <h2>Titre</h2>
              {content.theme &&
                <Tag color={content.theme.color} text={content.theme.title} link={`/content?theme=${content.theme.title}`} />
              }
            </div>
            <p>{content.title}</p>
            <h2>Description</h2>
            <p>{content.description}</p>
            <div>
              <div className={'w-full flex justify-between items-center'}>
                <h1 className={'my-4'}>Tâches</h1>
                { (getRole(user_roles).key === 'ROLE_ADMIN'
                  || content.createdBy.email === user_email
                  || content.managedBy.email === user_email)
                  &&
                  <AddButton onClick={() => setEditTaskModalIsOpen(true)} />
                }
              </div>
              {content.tasks?.length > 0 ?
                <Tabs>
                  <TabList className={'mb-0'}>
                    <>
                      { content.tasks?.map((task, index) => (
                        <Tab key={index}>Tâche #{index+1}</Tab>
                      ))
                      }
                    </>
                  </TabList>
                  <div className={'task-detail rounded-b rounded-t-r p-2'}>
                    { content.tasks?.map((item, index) => (
                      <TabPanel key={index}>
                        <TaskDetail index={index} item={item} />
                      </TabPanel>
                    ))
                    }
                  </div>
                </Tabs>
                : <ItemNotFound />
              }
            </div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={modalStyle}
            >
              <div className={'w-[70vw] overflow-y-auto max-h-[80vh]'}>
                <ContentForm content={content} formTitle={'Modifier contenu'} closeModal={closeModal} />
              </div>
            </Modal>
          </div>
          : <ItemNotFound />
      }
      <Modal
        isOpen={editTaskModalIsOpen}
        onRequestClose={closeEditTaskModal}
        style={modalStyle}
      >
        <div className={'w-[70vw] overflow-y-auto max-h-[80vh]'}>
          <TaskForm task={myTask} isFormVisible={true} onDelete={closeEditTaskModal} content={content} />
        </div>
      </Modal>
    </>
  );
}

export default DetailContent;