import React, {useEffect, useState} from 'react';
import AddButton from '../../components/ui/addButton';
import {useDispatch, useSelector} from 'react-redux';
import {wordPressSagaActions} from '../../redux/wrodPress/wordPressSagaActions';
import {format} from 'date-fns';
import EditButton from '../../components/ui/editButton';
import DeleteButton from '../../components/ui/deleteButton';
import Pagination from '../../components/ui/pagination';
import Modal from 'react-modal';
import {modalStyle} from '../../utils/modalStyle';
import WordPressForm from '../../components/forms/wordPressForm';
import ItemNotFound from '../../components/ui/itemNotFound';

function WordPress(props) {
  Modal.setAppElement('#root');
  const {
    wordPressList,
    totalItems
  } = useSelector(
    (state) => state.wordPress
  );
  const [modalIsOpen, setIsOpen] = useState(false);
  const [wordPress, setWordPress] = useState(null);
  const [index, setIndex] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(wordPressSagaActions.getAllWordPresses());
  }, [dispatch]);

  useEffect(() => {
    closeModal();
  }, [wordPressList]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setWordPress(null);
  }

  function onPageChange(currentPage) {
    dispatch(wordPressSagaActions.getAllWordPresses({page: currentPage.selected + 1}));
  }

  function handleDelete(val, i) {
    if (window.confirm('Voulez vous vraiment supprimer ce WordPress?')) {
      dispatch(wordPressSagaActions.deleteWordPress({id: val.id, index: i}));
    }
  }

  function handleEdit(val, i) {
    setIndex(i);
    setWordPress(val);
    openModal();
  }

  return (
    <>
      <div className={'flex flex-col gap-6 items-center rounded'}>
        <div className={'w-full flex justify-between'}>
          <h1>WordPress :</h1>
          <AddButton onClick={openModal} />
        </div>

        {totalItems === 0 ?
          <ItemNotFound />
          :
          <>
            <table
              className={'min-w-full table-auto text-center rounded-b-lg border border-primary text-primary text-sm md:text-base'}>
              <thead className={'bg-primary text-white'}>
              <tr>
                <th className={'py-2'}>WordPress</th>
                <th className={'py-2'}>Lien</th>
                <th className={'py-2'}>Crée le</th>
                <th className={'py-2'}>Modifié le</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {wordPressList?.map((item, index) => (
                <tr key={index}>
                  <td className={'py-1 text-center'}>
                    {item.name}
                  </td>
                  <td className={'py-1 text-center max-w-[80px] md:max-w-full truncate'}>
                    {item.link}
                  </td>
                  <td className={'py-1 text-center'}>
                    {format(new Date(item.createdAt), 'dd/MM/yy')}
                  </td>
                  <td className={'py-1 text-center'}>
                    {format(new Date(item.updatedAt), 'dd/MM/yy')}
                  </td>
                  <td className={'py-1 text-center flex gap-2 justify-center items-center'}>
                    <EditButton onClick={() => handleEdit(item, index)} />
                    <DeleteButton onClick={() => handleDelete(item, index)} />
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
            <Pagination totalItems={totalItems} onPageChange={onPageChange} />
          </>
        }
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
      >
        <WordPressForm closeModal={closeModal} item={wordPress} index={index} />
      </Modal>
    </>
  );
}

export default WordPress;