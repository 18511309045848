import api from '../../hooks/Api';
import {call, put, takeEvery} from 'redux-saga/effects';
import
{
  addStatus,
  editStatus,
  getAllStatus,
  httpFailure,
  deactivateStatus,
  setNextStatus,
  setInitialStatus,
  isFinalStatus
} from './statusSlice';

const baseUrl = process.env.REACT_APP_BACK_END_URL;

function* workerGetAllStatus(action) {
  try {
    let url = '';

    if (action.payload) {
      url = `?_page=${action.payload}`;
    }

    const res = yield call(() => api({
      url: `${baseUrl}/api/statuses${url}`
    }));

    yield put(getAllStatus(res.data));
  } catch {}
}

function* workerAddStatus(action) {
  try {
    const res = yield call(() => api({
      url: `${baseUrl}/api/statuses`,
      method: 'post',
      data: action.payload
    }));

    yield put(addStatus(res));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* updateStatus(action) {
  let res = yield call(() => api({
    url: `${baseUrl}/api/statuses/${action.payload.status.id}`,
    method: 'put',
    data: action.payload.status
  }));

  return {...res, index: action.payload.index};
}

function* workerEditStatus(action) {
  try {
    let res = yield updateStatus(action);
    yield workerGetAllStatus({payload : 1});
    yield put(editStatus(res));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* workerDeactivateStatus(action) {
  try {
    let res = yield updateStatus(action);
    yield put(deactivateStatus(res));
  } catch (e) {
    yield put(httpFailure());
  }
}

export function* getNextStatus(level) {
  return yield call(() => api({
    url: `${baseUrl}/api/get_next_status`,
    method: 'post',
    data: level
  }));
}

function* workerGetInitialStatus() {
  try {
    const res = yield getNextStatus({level: 0});
    yield put(setInitialStatus(res.data));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* workerGetNextStatus(action) {
  try {
    const res = yield getNextStatus(action.payload);
    yield put(setNextStatus(res.data));
  } catch (e) {
    yield put(httpFailure());
  }
}

function* workerIsFinalStatus(action) {
  try {
    const res = yield getNextStatus(action.payload);
    yield put(isFinalStatus(res.data === null));
  } catch (e) {
    yield put(httpFailure());
  }
}

export function* watcherStatus() {
  yield takeEvery('FETCH_ALL_STATUS', workerGetAllStatus);
  yield takeEvery('ADD_STATUS', workerAddStatus);
  yield takeEvery('EDIT_STATUS', workerEditStatus);
  yield takeEvery('DEACTIVATE_STATUS', workerDeactivateStatus);
  yield takeEvery('GET_INITIAL_STATUS', workerGetInitialStatus);
  yield takeEvery('GET_NEXT_STATUS', workerGetNextStatus);
  yield takeEvery('IS_FINAL_STATUS', workerIsFinalStatus);
}