import React, {useState} from 'react';

function DisplayFile({file, handleClosePreview}) {
  const path = `${process.env.REACT_APP_BACK_END_URL}/media`;
  const [isFullScreen, setIsFullScreen] = useState(false);

  function handleExitFile(e) {
    e.preventDefault();

    setIsFullScreen(false);
    handleClosePreview();
  }

  return (
    <>
      { file ?
        <div className={'fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center'}>
          <embed
            src={`${path}/${file.name}`}
            title={'file.originalName'}
            className={'h-full'}
            style={isFullScreen? {width : 100 + 'vw'} : {width: 60 + 'vw'}}
            type={file.mimeType}
            content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
          />
          <div className={'fixed top-16 right-8 flex flex-row gap-2'}>
            <span className={'text-white cursor-pointer'} title={isFullScreen? 'quitter plein écran' : 'plein écran'} onClick={() => setIsFullScreen(!isFullScreen)}>
              { isFullScreen ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 hover:w-10 hover:h-10">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"/>
              </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 hover:w-10 hover:h-10">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                </svg>
              }
            </span>
            <span className={' text-white cursor-pointer'} onClick={handleExitFile} title={'fermer'}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 hover:w-10 hover:h-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
          </div>
        </div>
        : null
      }
    </>
  );
}

export default DisplayFile;